import React from 'react';

import { IAffiliateCtaFields } from '../../../../types/contentful';

import { getImageData } from '../../../utils/contentful';

import { GlImage } from '../../Image';
import { Flexbox } from '../../Flexbox';

import { AffiliateBtn } from './AffiliateCta.styles';

const DEFAULT_WIDTH = 156;

export interface AffiliateCtaProps extends IAffiliateCtaFields {
  height?: string | number;
}

export function AffiliateCta({
  buttonUrl,
  image,
  mobileWidth,
  desktopWidth,
  height
}: AffiliateCtaProps) {
  const { width, ...imgData } = getImageData(image);

  return (
    <Flexbox flexDirection="column" justifyContent="center">
      <AffiliateBtn
        href={buttonUrl ?? 'https://greenlight.com'}
        width={width ?? DEFAULT_WIDTH}
        height={height}
        mobileWidth={mobileWidth as number}
        desktopWidth={desktopWidth as number}
        data-testid="affiliate-cta"
      >
        <GlImage {...imgData} width={width ?? DEFAULT_WIDTH} />
      </AffiliateBtn>
    </Flexbox>
  );
}
