import { RefObject, useEffect } from 'react';

export function useRefClickEvent(
  ref: RefObject<HTMLElement>,
  callback: () => void
) {
  function handleClick(event: MouseEvent) {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleClick);
    return () => {
      document.removeEventListener('mouseup', handleClick);
    };
  }, [ref, callback]);
}
