import React from 'react';

import { ColorTokenProp } from '../../../theme/tokens/color';
import { FontWeightProp } from '../../../theme/tokens/font-weight';

import { InlineAnchor } from '../styles';

import { NodeProps } from './shared';

interface EmbeddedInlineEntryNodeProps extends NodeProps {
  colour: ColorTokenProp | string;
  fontWeight?: FontWeightProp;
}
export function EmbeddedInlineEntryNode({
  node,
  colour,
  fontWeight = 'inlineAnchor'
}: EmbeddedInlineEntryNodeProps) {
  const { fields, sys } = node.data?.target ?? {};
  const { url, title, openInNewTab } = fields ?? {};

  if (sys?.contentType?.sys?.id === 'link') {
    return (
      <InlineAnchor
        href={url}
        target={openInNewTab ? '_blank' : undefined}
        colour={colour}
        fontWeight={fontWeight}
      >
        {title}
      </InlineAnchor>
    );
  } else {
    return null;
  }
}
