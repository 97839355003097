import { TokenProp } from './types';
import { Theme } from '../types';
import { useTokenProp } from './utils';

export interface CFColorTokens {
  spearmint: string;
  green: string;
  ginger: string;
  blackberry: string;
  cinnamint: string;
  sweetmint: string;
  white: string;
  mutedNavy: string;
  transparent: string;
  limegreen: string;
}

export type CFColorToken = keyof CFColorTokens;
export type CFColorTokenProp = TokenProp<CFColorToken>;

interface StyledProps {
  theme: Theme;
  cfBackgroundColor?: CFColorTokenProp;
  cfButtonColor?: CFColorTokenProp;
  cfButtonHoverColor?: CFColorTokenProp;
}

const setThemeColors = (props: any, token: any) => {
  const backgroundColor = token
    ? props.theme.cfColor[token as CFColorToken]
    : '';
  const darkColors = [
    props.theme.cfColor.blackberry,
    props.theme.cfColor.sweetmint
  ];
  if (darkColors.includes(backgroundColor)) {
    return props.theme.color.white;
  } else {
    return props.theme.color.defaultText;
  }
};

export const useCFBackgroundColorProp = useTokenProp<CFColorToken, StyledProps>(
  'background-color',
  (props) => props.cfBackgroundColor,
  (props, token) => (token ? props.theme.cfColor[token as CFColorToken] : '')
);

export const useCFButtonColorProp = (props: StyledProps) => `
  ${useTokenProp<CFColorToken | string, StyledProps>(
    'background-color',
    (btnProps) => btnProps.cfButtonColor,
    (_props, token) =>
      token ? _props.theme.cfColor[token as CFColorToken] : ''
  )(props)}
  ${useTokenProp<CFColorToken, StyledProps>(
    'color',
    (btnProps) => btnProps.cfButtonColor,
    setThemeColors
  )(props)}
`;

export const useCFButtonHoverColorProp = (props: StyledProps) => `
  ${useTokenProp<CFColorToken | string, StyledProps>(
    'background-color',
    (btnProps) => btnProps.cfButtonHoverColor,
    (_props, token) =>
      token ? _props.theme.cfColor[token as CFColorToken] : ''
  )(props)}
  ${useTokenProp<CFColorToken, StyledProps>(
    'color',
    (btnProps) => btnProps.cfButtonHoverColor,
    setThemeColors
  )(props)}
`;
