import styled from '../../../../theme/styled-with-theme';
import Link from '../../../Link';

export const FooterLink = styled(Link)`
  ${({ theme }) => ({
    color: theme.color.footerTextSupplementary,
    fontSize: `${theme.fontSize.s}`,
    lineHeight: theme.lineHeight.s
  })}
  font-weight: ${({ theme }) => theme.fontWeight[400]};
  text-decoration: none;
  transition: 0.2s color ease;

  &:hover {
    color: ${({ theme }) => theme.color.inlineAnchorHover};
    text-decoration: underline;
  }
`;
