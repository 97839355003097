import getConfig from 'next/config';
import logger from '@greenlightme/logger';

const { publicRuntimeConfig } = getConfig();
const { domain } = publicRuntimeConfig;

export function getCookieDomain(sameSite = false) {
  try {
    if (sameSite) return '';

    const url = new URL(domain);
    const hostname = url.hostname.split('.').slice(-2).join('.');

    if (hostname === 'localhost') return hostname;

    return `.${hostname}`; // Allow subdomains
  } catch (e) {
    logger.error('Failed to get cookie domain.', e);
    return ''; // Fallback to same site
  }
}

export function getCookie(cookieName: string): string {
  const name = cookieName + '=';
  const decodedCookie = document ? decodeURIComponent(document.cookie) : '';
  const char = decodedCookie.split(';');
  for (const ca of char) {
    let c = ca;
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      try {
        return JSON.parse(c.substring(name.length, c.length));
      } catch (e) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return '';
}

export function persistUrlParamsInCookies(cookies: string[]) {
  const urlParams = new URL(window.location.href).searchParams;

  const setCookie = (paramName: string) => {
    let value = urlParams.get(paramName) || '';
    if (paramName === 'utm_source' && value === '') {
      value = 'organic';
    }
    if (value === '') {
      return '';
    }
    const cookieValue = `${paramName}=${value}`;
    document.cookie = cookieValue;
    return cookieValue;
  };

  return cookies.map((cookie) => setCookie(cookie));
}

export function getUrlParamFromCookie(cookieName: string) {
  const name = `${cookieName}=`;
  const decodedCookie = document ? decodeURIComponent(document.cookie) : '';
  const queryParams = decodedCookie.split(';');
  for (const query of queryParams) {
    let param = query;
    while (param.charAt(0) == ' ') {
      param = param.substring(1);
    }
    if (param.indexOf(name) == 0) {
      return `&${cookieName}=${param.substring(name.length, param.length)}`;
    }
  }
  return '';
}
