import React, { useState } from 'react';
import { css } from '@emotion/react';

import { ButtonColorTokenProp } from '../../../theme/tokens/button-color';

import { hidden } from '../../../styles/a11y';

import { SharedProps, WidgetProps } from '../shared';

import { Button, Input, Container, Error } from './PhoneNumberWidget.styles';

export const DEFAULT_LABEL = 'Enter mobile phone number';
export const DEFAULT_PLACEHOLDER = 'Enter mobile number';
export const DEFAULT_BUTTON_TEXT = 'Get Started';

export function LegacyPhoneNumberWidget({
  id,
  className,
  buttonColor = 'dark',
  buttonHoverColor = 'light',
  cfButtonColor,
  cfButtonHoverColor,
  buttonContent = DEFAULT_BUTTON_TEXT,
  label,
  errorMessage,
  errorPosition,
  showErrorBanner,
  inputProps
}: WidgetProps & SharedProps) {
  const [isLabelObscured, setIsLabelObscured] = useState(false);

  const defaultInputProps = {
    ctaText: buttonContent,
    onFocus: () => setIsLabelObscured(true),
    onBlur: () => setIsLabelObscured(false),
    ...inputProps
  };

  return (
    <div style={{ flex: 'auto' }}>
      <Container data-e2e="phone-container" className={className}>
        <label
          htmlFor={id}
          css={css`
            ${hidden}
          `}
        >
          {(!isLabelObscured && label) || ''}
        </label>
        <Input
          {...defaultInputProps}
          id={id}
          placeholder={label}
          data-e2e="phone-number"
          data-testid="phone-number-cta-v1"
          autoComplete="tel"
        />
        <Button
          type="submit"
          buttonColor={buttonColor as ButtonColorTokenProp}
          buttonHoverColor={buttonHoverColor as ButtonColorTokenProp}
          cfButtonColor={cfButtonColor}
          cfButtonHoverColor={cfButtonHoverColor}
          data-e2e="btn-getting-started"
          data-testid="btn-getting-started"
        >
          {buttonContent}
        </Button>
      </Container>
      {showErrorBanner && errorMessage && (
        <Error position={errorPosition}>{errorMessage}</Error>
      )}
    </div>
  );
}
