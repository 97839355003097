import { Property } from 'csstype';
import styled from '../../theme/styled-with-theme';
import { SpacingProps, useSpacingProps } from '../../theme/tokens/spacing';
import {
  ColorTokenProp,
  HexOverrideColorProp,
  useBackgroundColorProp,
  useHexOverrideBgColorProp
} from '../../theme/tokens/color';
import {
  BorderRadiusProp,
  useBorderRadiusProp
} from '../../theme/tokens/border-radius';
import {
  CFColorTokenProp,
  useCFBackgroundColorProp
} from '../../theme/tokens/contentful-color';

export interface BoxProps extends SpacingProps {
  as?: React.ElementType;
  children?: React.ReactNode;
  flex?: Property.Flex<string>;
  alignSelf?: Property.AlignSelf;
  justifySelf?: Property.JustifySelf;
  order?: Property.Order;
  backgroundColor?: ColorTokenProp;
  cfBackgroundColor?: CFColorTokenProp;
  hexOverrideBgColor?: HexOverrideColorProp;
  borderRadius?: BorderRadiusProp;
  id?: string;
}

const useFlexProps = ({ flex, justifySelf, alignSelf, order }: BoxProps) => ({
  flex,
  justifySelf,
  alignSelf,
  order
});

export const Box = styled('div')<BoxProps>`
  box-sizing: border-box;
  ${useBackgroundColorProp}
  ${useCFBackgroundColorProp}
  ${useHexOverrideBgColorProp}
  ${useSpacingProps}
  ${useFlexProps}
  ${useBorderRadiusProp}
`;
