import { IExperimentGroupContainerFields } from '@/types/contentful';
import { datadogRum } from '@datadog/browser-rum';
import { FC } from 'react';
import { useOptimizely } from '~/context/Optimizely';
import { Variation } from '../Variation';
import { VariationContainer } from '../VariationContainer';

export const ExperimentGroupContainer: FC<IExperimentGroupContainerFields> = ({
  controlVariation,
  variationContainers
}) => {
  const experiments = useOptimizely();

  const variationContainer = variationContainers.find(
    ({ fields: { experimentKey } }) =>
      experiments[experimentKey ?? '']?.enabled &&
      experiments[experimentKey ?? '']?.variationKey !== ''
  );

  if (!variationContainer) {
    datadogRum.addAction(
      `ExperimentGroupContainer: No active experiment found, using control variation ${controlVariation.fields.variationKey}`
    );
    return <Variation {...controlVariation.fields} isActive={true} />;
  }

  datadogRum.addAction(
    `ExperimentGroupContainer: Active experiment found, using Variation Container for Experiment : ${variationContainer.fields.experimentKey}`
  );

  return (
    <VariationContainer
      {...variationContainer.fields}
      assignControlAsDefault={false}
    />
  );
};
