import styled from '../../theme/styled-with-theme';
import { Button as StyledButton } from '../Button';
import { mediaQueries } from '../../styles/media-queries';
import { pxToRem } from '../../styles/utils';
import { Box } from '../Box';
import { Flexbox, FlexboxProps } from '../Flexbox';
import StyledPhoneNumberInput from '../Input';
import { ctaWidgetEnum } from './shared';
import { LegacyPhoneNumberWidget } from './LegacyPhoneNumberWidget';

export const Container = styled(Flexbox)<FlexboxProps>`
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  ${({ flexDirection, justifyContent, alignItems }) => `
    flex-direction: ${flexDirection || 'column'};
    justify-content: ${justifyContent || 'center'};
    align-items: ${alignItems || 'center'};
  `}
`;

export const Wrapper = styled(Container)<{
  height?: number | string;
  width?: number | string;
}>`
  ${({ height = '100%', width = 'fit-content' }) => `
    height: ${typeof height === 'string' ? height : pxToRem(height)};
    width: ${typeof width === 'string' ? width : pxToRem(width)}
  `}
`;

export const FlexForm = styled('form')<{
  height?: number | string;
  width?: number | string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  ${({ height = '100%', width = 'fit-content' }) => `
    height: ${typeof height === 'string' ? height : pxToRem(height)};
    width: ${typeof width === 'string' ? width : pxToRem(width)}
  `}
`;

export const Widget = styled(Flexbox)`
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const ErrorMessage = styled('div')<{
  position?: string;
  height?: number;
  widgetType?: ctaWidgetEnum;
}>`
  background-color: #035c67;
  width: 100%;
  min-height: ${pxToRem(25)};
  color: white;
  text-align: center;
  position: absolute;

  ${({ position, theme }) =>
    position === 'top'
      ? `
      padding: ${theme.spacing.m};
      padding-bottom: ${theme.spacing.ml};
      border-radius: ${pxToRem(20)} ${pxToRem(20)} 0 0;
      bottom: ${pxToRem(55)};
    `
      : `
      padding: ${theme.spacing.m};
      padding-top: ${theme.spacing.ml};
      border-radius: 0 0 ${pxToRem(20)} ${pxToRem(20)};
      top: ${pxToRem(55)};
    `};
`;

export const Input = styled(StyledPhoneNumberInput)`
  flex: 1 0 auto;
  border: 1px solid #80d6b0;
  font-size: ${({ theme }) => theme.fontSize.input_tablet};
  padding: ${pxToRem(20)} ${pxToRem(22)};
  border-radius: ${pxToRem(10)};

  ${mediaQueries.desktop} {
    font-size: ${({ theme }) => theme.fontSize.input_desktop};
    border-radius: ${pxToRem(10)};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.white};
  }

  &::placeholder {
    color: #1d252c;
    font-size: ${({ theme }) => theme.fontSize.input_desktop};
    font-weight: 500;
  }
`;

export const Button = styled(StyledButton)`
  font-size: ${({ theme }) => theme.fontSize.button_0};
  width: 100%;
  height: 100%;
  min-width: fit-content;
  border-radius: ${pxToRem(10)};

  ${mediaQueries.desktop} {
    font-size: ${({ theme }) => theme.fontSize.button_desktop};
    border-radius: ${pxToRem(10)};
    height: 100%;
  }
`;

export const PhoneNumberWrapper = styled(Box)`
  max-width: ${pxToRem(270)};
  min-width: ${pxToRem(220)};
  height: ${pxToRem(36)};

  ${mediaQueries.tablet} {
    min-width: ${pxToRem(254)};
    max-width: ${pxToRem(270)};
    height: ${pxToRem(40)};
  }
  ${mediaQueries.desktop} {
    min-width: ${pxToRem(260)};
    max-width: ${pxToRem(390)};
    height: ${pxToRem(40)};
  }
`;

export const InBlockPhoneWidget = styled(LegacyPhoneNumberWidget)`
  display: flex;
  flex-grow: 1;

  @media (min-width: 375px) {
    min-width: ${pxToRem(250)};
  }

  input {
    font-size: ${pxToRem(10)};
    height: 2.25rem;
    flex-grow: 1;

    @media (min-width: 375px) {
      font-size: ${pxToRem(12)};
    }

    ${mediaQueries.tablet} {
      height: 2.5rem;
    }

    ${mediaQueries.desktop} {
      height: 3.125rem;
      font-size: ${pxToRem(18)};
      padding-left: 1rem;
    }
  }

  button {
    height: 2.25rem;

    ${mediaQueries.tablet} {
      height: 2.5rem;
    }

    ${mediaQueries.desktop} {
      height: 3.125rem;
      font-size: ${pxToRem(20)};
      padding: 0 2rem;
    }
  }
`;
