import Image from 'next/legacy/image';

import styled from '../../theme/styled-with-theme';
import { pxToRem } from '../../styles/utils';
import { mediaQueries } from '../../styles/media-queries';

import { Box } from '../Box';
import { Text } from '../Text';
import { Layout, BlockContainer } from '../Layout';
import { PhoneNumberWrapper } from '../CtaWidget/LegacyPhoneNumberWidget/PhoneNumberWidget.styles';
import { Flexbox } from '../Flexbox';
import {
  ParagraphWithLineBreaks as RichTextParagraph,
  handleColorType
} from '../RichText/styles';
import Link from '../Link';
import { GlImage } from '../Image';

export const MobileVersion = styled('div')`
  display: block;

  ${mediaQueries.desktop} {
    display: none;
  }
`;

export const DesktopHeroLayout = styled(Layout)<{
  bgColor: string;
  bgImage?: string;
}>`
  background-color: ${({ bgColor }) => `#${bgColor}`};
  border-radius: ${({ theme }) => theme.borderRadius.block_desktop};
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-size: cover;
`;

export const DesktopBlockContainer = styled(BlockContainer)`
  display: none;

  ${mediaQueries.desktop} {
    display: flex;
  }
`;

export const RelativeBox = styled(Box)<{
  bgColor: string;
  tabletBgColor: string;
}>`
  position: relative;
  height: ${pxToRem(500)};
  background-color: ${({ bgColor }) => `#${bgColor}`};

  ${mediaQueries.tablet} {
    height: ${pxToRem(750)};
    background-color: ${({ tabletBgColor }) => `#${tabletBgColor}`};
  }
`;

export const CTA = styled(Box)`
  position: absolute;
  z-index: 1;
  bottom: ${({ theme }) => theme.spacing.ml};
  left: ${({ theme }) => theme.spacing.m};
  right: ${({ theme }) => theme.spacing.m};

  ${mediaQueries.tablet} {
    bottom: ${({ theme }) => theme.spacing['2xl']};
    left: ${({ theme }) => theme.spacing['2xl']};
    right: ${({ theme }) => theme.spacing['2xl']};
  }
`;

export const CTAText = styled(Text)<{ mobileSubheadingSize?: string }>`
  max-width: ${pxToRem(225)};
  margin: 0 auto;
  padding-bottom: ${({ theme }) => theme.spacing.m};
  font-size: ${({ mobileSubheadingSize }) => `${mobileSubheadingSize}rem`};
  line-height: 1.125;

  ${mediaQueries.tablet} {
    max-width: ${pxToRem(512)};
    padding-bottom: ${({ theme }) => theme.spacing.ml};
  }
`;

export const ImageContainer = styled('div')`
  overflow: hidden;
  display: inline-block;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 4rem;
`;

export const MobileImage = styled(GlImage)`
  height: 100%;
  border-radius: ${pxToRem(56)};
`;

export const BackgroundImage = styled(GlImage)`
  grid-area: 1/1;
  border-radius: ${({ theme }) => theme.borderRadius.pressPost};
  z-index: 0;
  width: 100%;

  ${mediaQueries.desktop} {
    border-radius: ${({ theme }) => theme.borderRadius.block_desktop};
  }
`;

export const DesktopImage = styled(GlImage)<{ visibility: string }>`
  height: 100%;
  visibility: ${({ visibility }) => visibility};
`;

export const HomepagePhoneNumberWrapper = styled(PhoneNumberWrapper)`
  display: flex;
  flex-grow: 1;
  max-width: ${pxToRem(272)};
  justify-content: center;

  ${mediaQueries.tablet} {
    min-width: 16.25rem;
    max-width: 20rem;
  }

  ${mediaQueries.desktop} {
    min-width: 16.25rem;
    max-width: 26.375rem;
  }
`;

export const PopUpIcon = styled(Image)`
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const PopUpBubble = styled(Box)<{ isVisible: boolean }>`
  position: absolute;
  background-color: ${({ theme }) => theme.cfColor.white};
  font-size: ${pxToRem(12)};
  color: ${({ theme }) => theme.cfColor.blackberry};
  padding: 0.25rem 1.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.popup_border_radius};
  line-height: 1.25rem;
  margin: auto;
  left: 0;
  right: 0;
  width: 95%;
  top: -${pxToRem(12)};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: center;
  min-height: ${pxToRem(75)};

  @media (max-width: 350px) {
    top: -${pxToRem(16)};
  }

  ${mediaQueries.tablet} {
    min-height: ${pxToRem(100)};
    align-items: center;
    top: ${pxToRem(8)};
  }

  ${mediaQueries.desktop} {
    top: -${pxToRem(112)};
    width: ${pxToRem(320)};
    left: -${pxToRem(282)};
    z-index: 5;
    position: absolute;
    opacity: 0.95;
  }

  &:after {
    content: '';
    position: absolute;
    left: 87%;
    top: 98%;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid white;
    clear: both;

    @media (min-width: 425px) {
      left: 82%;
    }

    ${mediaQueries.tablet} {
      left: 76%;
      top: 100%;
    }

    ${mediaQueries.desktop} {
      left: ${pxToRem(288)};
    }
  }
`;

export const PopUpContainer = styled(Flexbox)`
  display: none;
  min-width: ${pxToRem(24)};

  ${mediaQueries.desktop} {
    position: relative;
  }
`;

export const NoSpamText = styled(Text)`
  margin: auto;
  margin-top: 0.5rem;
  width: 80%;

  ${mediaQueries.desktop} {
    margin: 0;
    margin-top: 1rem;
  }
`;

export const SpamTextContainer = styled(Box)`
  display: none;
  font-size: ${pxToRem(8)};
  margin: 0rem auto;
  margin-top: ${pxToRem(10)};
  max-width: ${pxToRem(272)};
  min-width: ${pxToRem(220)};
  height: 2.25rem;
  line-height: ${pxToRem(16)};
  color: ${({ theme }) => theme.cfColor.blackberry};

  ${mediaQueries.desktop} {
    font-size: ${pxToRem(12)};
    margin: 1.25rem 0;
    max-width: ${pxToRem(385)};
  }
`;

export const SubheadText = styled(Text)<{
  desktopSubheadingSize?: string;
}>`
  font-size: ${({ desktopSubheadingSize }) => `${desktopSubheadingSize}rem`};
  line-height: 1.125;
`;

export const EyebrowContainer = styled(Flexbox)<{ eyebrowLink?: boolean }>`
  background: rgba(29, 37, 44, 0.5);
  cursor: ${({ eyebrowLink }) => (eyebrowLink ? 'pointer' : 'auto')};
  border-radius: ${({ theme }) => theme.borderRadius.eyebrow};
  color: ${({ theme }) => theme.cfColor.white};
  display: flex;
  align-items: center
  justify-content: center;
  transition: .2s background ease, .2s box-shadow ease;

  &:hover {
    background: ${({ eyebrowLink }) =>
      eyebrowLink ? 'rgba(29, 37, 44, 0.8)' : 'rgba(29, 37, 44, 0.5)'};
    box-shadow: ${({ eyebrowLink }) =>
      eyebrowLink ? '0px 4px 40px rgba(0, 0, 0, 0.24)' : 'none'};
  }
  
  ${mediaQueries.tablet} {
    white-space: nowrap;
  }
`;

export const EyebrowImage = styled('img')`
  height: 40px;
  width: 40px;
`;

export const EyebrowArrow = styled(Image)`
  padding: ${({ theme }) => `0 ${theme.spacing.s} 0 ${theme.spacing.sm}`};

  ${mediaQueries.tablet} {
    padding: ${({ theme }) => `0 ${theme.spacing.s}`};
  }
`;

export const InlineAnchor = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'useLightText'
})<{ useLightText: boolean }>`
  color: ${({ theme }) => theme.color.defaultText};
  font-weight: ${({ theme }) => theme.fontWeight.p};
  text-decoration: underline;
  transition: 0.2s color ease;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.color.darkButton};
  }

  ${mediaQueries.desktop} {
    color: ${({ theme, useLightText }) =>
      handleColorType(theme, useLightText ? 'white' : 'defaultText')};
    &:hover {
      color: ${({ theme, useLightText }) =>
        handleColorType(
          theme,
          useLightText ? 'inlineAnchorHover' : 'darkButton'
        )};
    }
  }
`;

export const ParagraphWithLineBreaks = styled(RichTextParagraph)`
  max-width: ${pxToRem(272)};
  text-align: center;

  ${mediaQueries.tablet} {
    text-align: center;
    min-width: 16.25rem;
    max-width: 20rem;
  }

  ${mediaQueries.desktop} {
    text-align: left;
    min-width: 16.25rem;
    max-width: 26.375rem;
  }
`;
