import React, { useCallback, useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import { IHeader2Fields } from '@/types/contentful';

import { MobileModal } from './MobileNavComponents/MobileModal';
import { MobileNavbar } from './MobileNavComponents/MobileNavbar';

export interface MobileNavProps extends Partial<IHeader2Fields> {
  isScrolled?: boolean;
  isSimplified?: boolean;
}

export function NavItem(props: MobileNavProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const router = useRouter();

  useEffect(() => {
    if (isMenuOpen) {
      setIsMenuOpen(!isMenuOpen);
    }
  }, [router.asPath]);

  const toggleMobileMenu = useCallback(
    () => setIsMenuOpen(!isMenuOpen),
    [isMenuOpen]
  );

  return (
    <>
      <MobileNavbar
        {...props}
        isMenuOpen={isMenuOpen}
        toggleModal={toggleMobileMenu}
      />

      {isMenuOpen && (
        <MobileModal
          {...props}
          isMenuOpen={isMenuOpen}
          toggleModal={toggleMobileMenu}
        />
      )}
    </>
  );
}
