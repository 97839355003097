import React from 'react';
import Image from 'next/image';

import { usePageDataContext } from '../../../../context/PageData';

import Link from '../../../Link';

import apple from '@/images/app-store-badge-white.svg';
import google from '@/images/google-play-badge-white.svg';
import amazon from '@/images/amazon-store-badge-white.svg';

export function AppLinks() {
  const { config } = usePageDataContext();

  const { appleAppLink, googleAppLink, amazonAppLink } = config ?? {};

  return (
    <div id="app-block" className="mt-4 tiny:max-tablet:mt-6">
      <div id="app-link-layout" className="flex w-full gap-2">
        {appleAppLink && (
          <div className="flex w-fit justify-center">
            <Link href={appleAppLink} data-testid="ios-app-link">
              <Image
                src={apple.src}
                width={apple.width}
                height={apple.height}
                alt="Download Greenlight App - App Store"
                className="relative h-[48px] w-[129px] object-contain tablet:h-[40px] tablet:w-[108px]"
              />
            </Link>
          </div>
        )}
        {googleAppLink && (
          <div className="flex w-fit justify-center">
            <Link href={googleAppLink} data-testid="android-app-link">
              <Image
                src={google.src}
                width={google.width}
                height={google.height}
                alt="Download Greenlight App - Google Play"
                className="relative h-[48px] w-[129px] object-contain tablet:h-[40px] tablet:w-[108px]"
              />
            </Link>
          </div>
        )}
        {amazonAppLink && (
          <div className="flex w-fit justify-center">
            <Link href={amazonAppLink} data-testid="amazon-app-link">
              <Image
                src={amazon.src}
                width={amazon.width}
                height={amazon.height}
                alt="Download Greenlight App - Amazon App Store"
                className="relative h-[48px] w-[129px] object-contain tablet:h-[40px] tablet:w-[108px]"
              />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
