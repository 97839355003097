import React from 'react';

const switchButton = {
  content: '',
  position: 'absolute',
  left: '0px',
  width: '20px',
  height: '20px',
  borderRadius: '20px',
  boxShadow: '0 0 2px 0 rgba(10, 10, 10, 0.29)',
  backgroundColor: '#757575',
  transition: 'all 0.2s'
} as React.CSSProperties;

const activeSwitchButtonStyle = {
  backgroundColor: '#3bbfad',
  left: '100%',
  transform: 'translateX(-100%)',
  transition: 'all 0.2s'
};

const switchLabel = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  width: '32px',
  height: '12px',
  borderRadius: '100px',
  position: 'relative',
  backgroundColor: '#efefef',
  transition: 'all 0.2s'
} as React.CSSProperties;

const activeSwitchLabelStyle = {
  backgroundColor: '#abddd6'
};

interface ToggleSwitchProps {
  toggleName: string;
  checked: boolean;
  onChange: () => void;
}

export function ToggleSwitch({
  toggleName,
  checked,
  onChange
}: ToggleSwitchProps) {
  return (
    <>
      <input
        data-testid="toggle-switch"
        type="checkbox"
        style={{
          height: 0,
          width: 0,
          visibility: 'hidden'
        }}
        name={toggleName}
        id={toggleName}
        onChange={onChange}
        checked={checked}
        aria-checked={checked}
      />
      <label
        style={
          checked
            ? { ...switchLabel, ...activeSwitchLabelStyle }
            : { ...switchLabel }
        }
        htmlFor={toggleName}
      >
        <span
          data-testid="toggle-button"
          style={
            checked
              ? { ...switchButton, ...activeSwitchButtonStyle }
              : { ...switchButton }
          }
        />
      </label>
    </>
  );
}
