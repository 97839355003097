import React from 'react';
import Image from 'next/legacy/image';
import { RenderNode } from '@contentful/rich-text-react-renderer';
import { Block, BLOCKS, INLINES, Inline } from '@contentful/rich-text-types';

import { usePageDataContext } from '../../context/PageData';

import { Flexbox } from '../Flexbox';
import { BlockContainer, Layout, Column } from '../Layout';
import { Text } from '../Text';
import { Box } from '../Box';
import RichText from '../RichText';
import { EmbeddedInlineEntryNode } from '../RichText/Nodes';
import CtaWidget from '../CtaWidget';

import { ParagraphWithLineBreaks } from '../RichText/styles';
import { InlineAnchor } from '../HomepageHero/styles';

import { ctaWidgetEnum, DEFAULT_EMAIL_LABEL } from '../CtaWidget/shared';

import shield from '@/images/shield.svg';

export function LeadCapture() {
  const { config: { failWhaleDisclaimer } = {}, webregIncident = false } =
    usePageDataContext();

  const RICH_TEXT_OVERRIDE: RenderNode = {
    [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => {
      return (
        <ParagraphWithLineBreaks
          as="p"
          fontSize={{
            0: '2xs',
            tablet: 's'
          }}
          marginLeft="sm"
          fontWeight="400"
          colour="defaultText"
          textAlign="left"
        >
          {children}
        </ParagraphWithLineBreaks>
      );
    },
    [INLINES.HYPERLINK]: (node, children) => (
      <InlineAnchor href={node.data.uri} useLightText={false}>
        {children}
      </InlineAnchor>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node, children) => (
      <EmbeddedInlineEntryNode node={node} colour="defaultText" fontWeight="p">
        {children}
      </EmbeddedInlineEntryNode>
    )
  };

  return !webregIncident ? null : (
    <BlockContainer
      as="section"
      xPaddingOverrides={{ 0: 0, min: 0, mobile: 0 }}
      padding={{ desktop: [0, 'xl'] }}
      marginTop={{ 0: 'blockMarginY', tablet: 'blockMargin' }}
      data-blockid="leadCapture"
    >
      <Layout
        gutter={{ 0: 0, tablet: 'sm' }}
        padding={{ 0: ['ml', 'sm'], tablet: ['ml', 'xl'] }}
        marginTop="sm"
        backgroundColor="leadCaptureBg"
        borderRadius={{ tablet: 'block', desktop: 'block_desktop' }}
      >
        <Column
          size={{ 0: 12, desktop: 8 }}
          justifyContent="center"
          flexDirection="column"
        >
          <Text
            as="h3"
            fontWeight="h3"
            fontSize={{ 0: '2xl', desktop: '3xl' }}
            textAlign="center"
            data-testid="fail-whale-incident-message"
          >
            Uh-oh! ​​We&apos;re experiencing some challenges right now.
          </Text>

          <Text
            as="p"
            fontWeight="p"
            fontSize={{ 0: 's', desktop: 'm' }}
            textAlign="center"
            padding={{
              0: 'm',
              tablet: ['m', '3xl'],
              desktop: ['m', '2xl']
            }}
          >
            Please share your email address with us so we can let you know when
            we&apos;re back up and running.
          </Text>
        </Column>
        <Column
          size={{ 0: 12, tablet: 10, desktop: 8 }}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          id="email-column"
        >
          <CtaWidget
            id="lead-capture-email-widget"
            module="Lead Capture"
            designType={ctaWidgetEnum.EMAIL}
            sysId="registrationEntrypoint"
            errorPosition="bottom"
            data-elementid="lead-capture-email-widget"
            label={DEFAULT_EMAIL_LABEL}
            placeholder={DEFAULT_EMAIL_LABEL}
            buttonContent="Submit"
            height="100%"
            width="100%"
          />
          {failWhaleDisclaimer && (
            <Box marginTop="ml">
              <Flexbox
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                margin={{ 0: [0, 'l'], desktop: [0, 'ml'] }}
              >
                {/* Empty alt tag to hide decorative icons from screen readers */}
                <Image src={shield} alt="" />
                <RichText
                  data={failWhaleDisclaimer}
                  overrideOptions={RICH_TEXT_OVERRIDE}
                />
              </Flexbox>
            </Box>
          )}
        </Column>
      </Layout>
    </BlockContainer>
  );
}
