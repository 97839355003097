import { useEffect } from 'react';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';

import { IAnalyticsMetadata } from '../../types/contentful';

import analytics from '../utils/analytics';
import { getCookie } from '../utils/cookies';
import { getDeviceType } from '../utils/detectDevice';
import { setRumUser, trackRumEvent } from '../utils/datadogRum';
import { getAnonymousId } from '../utils/getAnonymousId';
import { Educator } from '../context/User';

interface PageAnalytics {
  analyticsMetadata?: IAnalyticsMetadata;
  query?: NextParsedUrlQuery;
  slug?: string;
  pathPrefix?: string;
  user?: Educator;
}

export function usePageAnalytics({
  analyticsMetadata,
  query,
  slug,
  pathPrefix,
  user
}: PageAnalytics) {
  useEffect(() => {
    const userId = getAnonymousId();

    const referrer = window?.document?.referrer;

    const pageEvent: any = {
      ...query,
      anonymousId: userId,
      deviceType: getDeviceType(),
      returningUser: getCookie('gl_is_returning_user'),
      registeredUser: getCookie('gl_is_registered_user'),
      utm_source: query?.utm_source || getCookie('utm_source') || 'not_set',
      direct_utm: query?.utm_source || referrer || 'not_set'
    };

    // Set path for pageEvent from slug and pathPrefix
    if (slug) {
      const url = slug === 'homepage' ? '/' : `/${slug}`;
      pageEvent.path = pathPrefix ? `${pathPrefix}${url}` : url;
    }

    if (user) {
      pageEvent.email = user.email;
    }

    // Update RUM context with userId
    setRumUser({ id: userId });

    // Ensure Segment anonId is in sync with userId
    analytics.setAnonymousId(userId);

    // Trigger Segment Loaded a Page event
    analytics.page(
      analyticsMetadata?.fields?.category || '',
      analyticsMetadata?.fields?.name || '',
      pageEvent
    );

    trackRumEvent('Loaded a Page', {
      referrer,
      ...analyticsMetadata?.fields,
      ...pageEvent
    });

    // Mixpanel is loaded by segment in device mode, meaning their anonymous ids diverge when using anonymous id on backend.
    // Doing an alias here should fix that to stitch mixpanel's anonid and segment's
    (function aliasMixpanel() {
      if (window.mixpanel && typeof window.mixpanel.alias === 'function') {
        window.mixpanel.alias(analytics.anonymousId as string);
      } else {
        setTimeout(aliasMixpanel, 2000); // if mixpanel doesnt exist yet, try again in 2 seconds
      }
    })();
  }, [analyticsMetadata]);
}
