import React from 'react';

import { IFooter20Fields } from '../../../../types/contentful';

import { Navigation } from './Navigation';
import { Legal } from './Legal';
import { Social } from './Social';

import cx from 'classnames';

export function Footer2(props: IFooter20Fields) {
  const { isAffiliateFooter } = props;
  return (
    <footer>
      <div
        id="footer"
        className="flex flex-col rounded-t-[32px] bg-black p-5 !pt-20 tiny:max-tablet:pb-10 tablet:flex-row tablet:rounded-t-[48px] tablet:p-10 desktop:p-15"
        data-blockid="footer20"
        data-testid="footer20"
      >
        <div
          className={cx('flex w-full flex-col items-center tablet:mr-4', {
            'tablet:items-start desktop:w-4/12': !isAffiliateFooter
          })}
        >
          <Social {...props} />
        </div>

        {!isAffiliateFooter && (
          <hr className="my-6 w-full bg-[#000] tablet:hidden" />
        )}

        {!isAffiliateFooter && (
          <div className="flex w-full flex-col desktop:w-8/12">
            <Navigation {...props} />
          </div>
        )}
      </div>

      <Legal {...props} />
    </footer>
  );
}
