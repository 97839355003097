//prettier-ignore-start

const FAQRichResults = `{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "What is Greenlight?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text":
          "Greenlight is a company on a mission to shine a light on the world of money for kids and parents.</p><br/><p>The Greenlight card and app were purpose-built for families. Parents get the tools they need to manage their kids’ money. Kids get real-life lessons in earning, spending, saving and giving.</p>"
      }
    },
    {
      "@type": "Question",
      "name": "Is there a minimum age?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text":
          "We support kids and grownups of all ages. No minimum (or maximum) age here."
      }
    },
    {
      "@type": "Question",
      "name": "How much does it cost?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text":
          "Greenlight costs $4.99 per month and includes debit cards for up to five (5) kids.</p><br/><p>It also includes the parental tools and learning opportunities that help kids master financial responsibility for life (and have fun doing it). Your first month is on us, and you can cancel at any time. See our <a href=/details/>Details page</a> for more.</p>"
      }
    },
    {
      "@type": "Question",
      "name": "Is this a debit or credit card?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text":
          "Greenlight is a debit card for kids, not a credit card. Parents load money onto the card from their own Greenlight app. And because it’s a debit card, kids can’t spend what isn’t there. That’s a pretty valuable life lesson."
      }
    },
    {
      "@type": "Question",
      "name": "Where is the card accepted?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text":
          "The Greenlight card can be used almost anywhere Mastercard is accepted, online and in-store. Almost, because our cardholders are kids, and we’ve put important <a href=https://help.greenlight.com/hc/en-us/articles/223606648-Are-there-places-I-can-t-use-my-Greenlight-card->guardrails in place</a>. Greenlight also works internationally in 150+ other countries, with no foreign transaction fees. No need to let us know if you travel abroad — you’ll have enough to remember."
      }
    },
    {
      "@type": "Question",
      "name": "Is Greenlight secure?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text":
          "Greenlight accounts are FDIC-insured through our partner, Community Federal Savings Bank, which is compliant with the Payment Card Industry Data Security Standard. All communications between your phone and our servers use state-of-the-art encryption."
      }
    },
    {
      "@type": "Question",
      "name": "What do I need to sign up?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text":
          "All we need to get you started is some basic information.</p><ul><li>Your email address</li><li>Your mobile phone number</li><li>Your child’s/children’s name(s)</li><li>Your legal first and last name</li><li>Your mailing address</li><li>Your date of birth</li><li>Your Social Security number (SSN). For information on why we ask for your SSN, <a href=https://help.greenlight.com/hc/en-us/articles/360037404433-Why-am-I-being-asked-to-verify-my-identity->learn more about the U.S. Patriot Act</a>.</li><li>A valid debit card or bank account</li></ul>"
      }
    },
    {
      "@type": "Question",
      "name": "How long will it take for my card to arrive?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text":
          "You should receive your Greenlight card within 5-10 days. For an additional charge, expedited shipping is available."
      }
    },
    {
      "@type": "Question",
      "name": "How is Greenlight different from a traditional debit card?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text":
          "Greenlight is bigger than a debit card, and it was built with families in mind.</p><br/><p>Yes, it’s a debit card for kids, managed by parents. But it’s also a powerful mobile app that lets parents easily manage and see where, when and how much kids spend, while helping them learn to manage their money.</p><p>It’s three different accounts in one — Spend, Save and Give. A digital chore board. A saving-motivation machine. A set of flexible parental controls you won’t get from most banks. And a simple, clear view of your child’s financial world, complete with real-time transaction alerts. All in one app.</p><p>Greenlight lets parents:</p><ul><li>Transfer money instantly to kids</li><li>Turn the card off via the app, if needed</li><li>Receive alerts whenever the card is used</li><li>Set store-level spend controls to help kids learn to budget</li><li>Automate allowance payments</li><li>Manage chores (and instantly reward a job well done)</li></ul><p>You’ll find more about what you can do with Greenlight on our <a href="https://greenlight.com/details">Details page</a>.</p>"
      }
    },
    {
      "@type": "Question",
      "name": "Does Greenlight work with Apple Pay and Google Pay?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text":
          "Yes. You can start using Apple Pay (kids 13+) and Google Pay (kids 16+) as soon as you add your card(s) to your device."
      }
    }
  ]
}`;

export default FAQRichResults;
