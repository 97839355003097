import { TokenProp } from './types';
import { Theme } from '../types';
import { useTokenProp } from './utils';

export interface ColorTokens {
  unset: string;
  transparent: string;
  brand: string;
  footerBg: string;
  footerBgSecondary: string;
  footerText: string;
  footerTextSecondary: string;
  footerTextSupplementary: string;
  defaultText: string;
  secondaryText: string;
  white: string;
  black: string;
  inputBorder: string;
  darkButton: string;
  lightButton: string;
  buttonHover: string;
  sliderSlide1: string;
  sliderSlide2: string;
  sliderSlide3: string;
  headerBg: string;
  headerAltBg: string;
  headerLink: string;
  headerAltLink: string;
  headerLinkHover: string;
  headerLogo: string;
  headerAltLogo: string;
  milestoneText: string;
  infoText: string;
  infoBg: string;
  asFeaturedOnBg: string;
  homepageHeroBg: string;
  homepageHeroCtaBg: string;
  collapsibleBorder: string;
  inlineAnchor: string;
  inlineAnchorHover: string;
  lightInlineAnchor: string;
  playIconBackground: string;
  playIconCenter: string;
  pressPostBg: string;
  pressHeaderBg1: string;
  pressHeaderBg2: string;
  errorBg: string;
  quickStartGuideToggle: string;
  blogPostHeroBg: string;
  tagBg: string;
  tagText: string;
  blockquoteBg: string;
  blockquoteText: string;
  socialIcon: string;
  socialIconHover: string;
  socialIconLight: string;
  socialIconLightHover: string;
  socialIconSupplementary: string;
  featuredBlogPostBg: string;
  featuredBlogPostText: string;
  leadCaptureBg: string;
  leadCaptureText: string;
  navbarLink: string;
  mobileMenuGetStartedBg: string;
  desktopNavSublinkHoverColor: string;
  desktopNavSublinkBorderColor: string;
  selectInputLabelBg: string;
  selectInputLabelText: string;
  inputLabelText: string;
  inputBorderColor: string;
  privacyLinkText: string;
  privacyTextBorderBottom: string;
  emailInputBorder: string;
  emailInputBackground: string;
  emailInputBorderError: string;
  giftingSubscriptionButton: string;
  radioButtonPrimary: string;
  radioButtonSecondary: string;
  textInputPrimary: string;
  textInputInvalid: string;
  formToastBorder: string;
  formToastBackground: string;
  giftingSubscriptionImageBackground: string;
  giftingSubscriptionBackground: string;
}

export type ColorToken = keyof ColorTokens;
export type ColorTokenProp = TokenProp<ColorToken>;
export type HexOverrideColorProp = TokenProp<string>;

interface StyledProps {
  theme: Theme;
  colour?: ColorTokenProp | string;
  backgroundColor?: ColorTokenProp;
  hoverColor?: ColorTokenProp;
  hexOverrideBgColor?: HexOverrideColorProp;
}

export const useColourProp = useTokenProp<ColorToken | string, StyledProps>(
  'color',
  (props) => props.colour,
  (props, token) =>
    token ? props.theme.color[token as ColorToken] ?? token : ''
);

export const useBackgroundColorProp = useTokenProp<ColorToken, StyledProps>(
  'background-color',
  (props) => props.backgroundColor,
  (props, token) => (token ? props.theme.color[token as ColorToken] : '')
);

export const useHexOverrideBgColorProp = useTokenProp<string, StyledProps>(
  'background-color',
  (props) => props.hexOverrideBgColor,
  (_props, token) => (token ? `#${token}` : '')
);

export const useColourPropAsFill = useTokenProp<
  ColorToken | string,
  StyledProps
>(
  'fill',
  (props) => props.colour,
  (props, token) => (token ? props.theme.color[token as ColorToken] : '')
);

export const useHoverColorPropAsFill = useTokenProp<ColorToken, StyledProps>(
  'fill',
  (props) => props.hoverColor,
  (props, token) => (token ? props.theme.color[token as ColorToken] : '')
);
