import axios, { Method, AxiosRequestConfig, AxiosResponse } from 'axios';
import logger from '@greenlightme/logger';

import { captureError } from './datadogRum';

const delayMs = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

const axiosInstance = axios.create();

export interface HttpRequestConfig<ResponseData> {
  method: Method;
  url: string;
  /**
   * Should throw an error if response data is invalid.
   * Use runtype's .check function.
   */
  validateResponse: (response: unknown) => ResponseData;
  headers?: Record<string, unknown>;
  data?: Record<string, unknown>;
  params?: { [key: string]: unknown };
  retries?: number;
  backoffMs?: number;
}

export interface HttpRequestResponse<ResponseData> {
  data: ResponseData;
  status: number;
  statusText: string;
  headers: unknown;
}

export async function sendHttpRequest<ResponseData>({
  validateResponse,
  retries = 0,
  backoffMs = 1000,
  ...requestConfig
}: HttpRequestConfig<ResponseData>): Promise<
  HttpRequestResponse<ResponseData>
> {
  let result;

  try {
    result = await axiosInstance(requestConfig as AxiosRequestConfig).then(
      (response) => {
        try {
          return {
            ...response,
            data: validateResponse(response.data)
          };
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    );
  } catch (error) {
    if (retries > 0) {
      await delayMs(backoffMs);
      result = await sendHttpRequest({
        ...requestConfig,
        validateResponse,
        retries: retries - 1,
        backoffMs: backoffMs * 2
      });
    } else {
      throw error;
    }
  }
  return result;
}

interface HttpRequest<ResponseData> extends AxiosRequestConfig {
  validateResponse: (response: unknown) => ResponseData;
  retries?: number;
  backoffMs?: number;
}

export async function sendHttpRequest2<ResponseData>({
  validateResponse,
  retries = 0,
  backoffMs = 1000,
  ...requestConfig
}: HttpRequest<ResponseData>): Promise<AxiosResponse<ResponseData>> {
  try {
    const response = await axios(requestConfig);

    // Validate use the runtype "check" method
    validateResponse(response.data);

    return response;
  } catch (err: any) {
    logger.error('Send HTTP Request Error', { error: err?.response });
    captureError(new Error('Send HTTP Request Error', err?.response));

    if (retries > 0) {
      await delayMs(backoffMs);
      return await sendHttpRequest2({
        ...requestConfig,
        validateResponse,
        retries: retries - 1,
        backoffMs: backoffMs * 2
      });
    }

    return err?.response;
  }
}
