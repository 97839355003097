import React from 'react';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';

interface ScrollRevealProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const ScrollReveal: React.FC<ScrollRevealProps> = (
  props: ScrollRevealProps
) => {
  const { ref, inView } = useInView({
    triggerOnce: true
  });
  const revealAnimation = useSpring({ opacity: inView ? 1 : 0 });

  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'ci') {
    return <div>{props.children}</div>;
  }

  return (
    <animated.div
      style={{ ...props.style, ...revealAnimation }}
      ref={ref}
      className={props.className}
    >
      {props.children}
    </animated.div>
  );
};
