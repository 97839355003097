import { TokenProp } from './types';
import { Theme } from '../types';
import { useTokenProp } from './utils';

export interface FontFamilyTokens {
  default: string;
}

type FontFamilyToken = keyof FontFamilyTokens;
export type FontFamilyProp = TokenProp<FontFamilyToken>;

interface StyledProps {
  theme: Theme;
  fontFamily?: FontFamilyProp;
}

export const useFontFamilyProp = useTokenProp<FontFamilyToken, StyledProps>(
  'font-family',
  props => props.fontFamily,
  (props, token) =>
    token ? props.theme.fontFamily[token as FontFamilyToken] : ''
);
