import { pxToRem } from '../styles/utils';

export const getScaledImageHeight = (
  width = 1,
  height = 1,
  minHeight = 22,
  maxHeight = 88
) => {
  const aspectRatio = width / height;

  const imageHeight = pxToRem(
    minHeight + (maxHeight - minHeight) / aspectRatio
  );

  return { aspectRatio, imageHeight };
};
