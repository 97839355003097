import React from 'react';
import Image from 'next/legacy/image';
import { Block, BLOCKS, INLINES, Inline } from '@contentful/rich-text-types';
import isEmpty from 'lodash/isEmpty';

import { IWebBannerFields } from '../../../types/contentful';

import { usePublicRuntimeConfig } from '../../hooks/usePublicRuntimeConfig';
import { trackWithFingerprint } from '../../utils/trackWithFingerprint';
import { UrlByEnv, envEnum } from '../../context/PageData';
import { ColorTokenProp } from '../../theme/tokens/color';

import RichText from '../RichText';
import { EmbeddedInlineEntryNode } from '../RichText/Nodes';
import { Flexbox } from '../Flexbox';
import { Text } from '../Text';

import { ArrowNorthEast } from './ArrowNorthEast';

import {
  WebBannerWrapper,
  WebBannerCloseButton,
  InlineAnchor,
  CTALinkButtonRight,
  CTALinkButtonOnly
} from './styles';

import toggleClose from '@/images/toggle-close.svg';

export interface WebBannerProps extends IWebBannerFields {
  toggleBanner: Function;
  roundedCornersVariation?: boolean;
}

export function WebBanner({
  title,
  titleColor,
  bannerBackgroundColor,
  message,
  messageColor,
  rightButtonText,
  rightButtonArrowColor,
  ctaUrl,
  previewCtaUrl,
  stagingCtaUrl,
  openInNewTab,
  toggleBanner,
  roundedCornersVariation
}: WebBannerProps) {
  const { nodeEnv } = usePublicRuntimeConfig();

  const handleCTAClick = () => {
    // Fire Segment Event
    trackWithFingerprint('CTA Clicked', {
      page_url: window.location.href,
      module: 'web banner'
    });
  };

  const target = openInNewTab ? '_blank' : '_self';

  const env = (nodeEnv as keyof typeof envEnum) || '';

  const envCtaUrls: UrlByEnv = {
    development: previewCtaUrl as string,
    preview: previewCtaUrl as string,
    staging: stagingCtaUrl as string,
    production: ctaUrl as string
  };

  // Use CTA url based on env, if undefined don't show CTA button
  const url = envCtaUrls[envEnum[env]];

  return isEmpty(message) ? null : (
    <Flexbox
      backgroundColor="white"
      data-testid="webBanner"
      className={`web-banner m-auto w-full ${
        roundedCornersVariation ? 'max-w-[1500px]' : 'max-w-auto'
      }`}
    >
      <WebBannerWrapper
        backgroundColor={(bannerBackgroundColor ?? 'black') as ColorTokenProp}
        borderRadius={{
          0: ['0', '0', 'pressPostHeader', 'pressPostHeader'],
          tablet: ['0', '0', 'pressPostHeader', 'pressPostHeader'],
          desktop: ['0', '0', 'pressPostHeader', 'pressPostHeader']
        }}
      >
        <Flexbox
          justifyContent="center"
          alignItems="center"
          marginLeft={{ 0: 's', min: 'm' }}
          marginRight={{ 0: 's', min: 'm' }}
        >
          <WebBannerCloseButton
            onClick={() => toggleBanner('hide')}
            data-testid="webBannerCloseButton"
          >
            <Image src={toggleClose} alt="close banner" />
          </WebBannerCloseButton>
        </Flexbox>
        <Flexbox flex={1} justifyContent="flex-start" alignItems="center">
          <RichText
            data={message}
            overrideOptions={{
              [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => (
                <Text
                  as="p"
                  fontWeight="p"
                  fontSize={{ 0: 's', tablet: 's', desktop: 'm' }}
                  textAlign="left"
                  style={{ color: messageColor ?? '#ffffff' }}
                  marginRight={{ 0: 0 }}
                >
                  <Text
                    as="span"
                    fontWeight="bold"
                    fontSize={{ 0: 's', tablet: 's', desktop: 'm' }}
                    textAlign="left"
                    style={{ color: titleColor ?? '#ffffff' }}
                    marginRight={{ 0: 's', tablet: 'ml', desktop: 'ml' }}
                  >
                    {title}
                  </Text>
                  {children}
                </Text>
              ),
              [INLINES.HYPERLINK]: (node, children) => (
                <InlineAnchor href={node.data.uri}>{children}</InlineAnchor>
              ),
              [INLINES.EMBEDDED_ENTRY]: (node, children) => (
                <EmbeddedInlineEntryNode
                  node={node}
                  colour="defaultText"
                  fontWeight="p"
                >
                  {children}
                </EmbeddedInlineEntryNode>
              )
            }}
          />
        </Flexbox>
        {url && (
          <Flexbox marginLeft={{ 0: 's', min: 'm' }}>
            <CTALinkButtonRight
              href={url}
              target={target}
              rel="noreferrer"
              aria-label={rightButtonText ?? ''}
              data-testid="webBannerCtaLink"
              attachParams={true}
              onClick={handleCTAClick}
            >
              <Text
                as="p"
                fontWeight="p"
                fontSize={{ 0: 's', desktop: 'sm' }}
                textAlign="center"
                colour="white"
              >
                {rightButtonText}
              </Text>
              <ArrowNorthEast fill={rightButtonArrowColor ?? '#ffffff'} />
            </CTALinkButtonRight>
            <CTALinkButtonOnly
              href={url}
              target={target}
              rel="noreferrer"
              aria-label={rightButtonText ?? ''}
              attachParams={true}
              onClick={handleCTAClick}
            >
              <ArrowNorthEast fill={rightButtonArrowColor ?? '#ffffff'} />
            </CTALinkButtonOnly>
          </Flexbox>
        )}
      </WebBannerWrapper>
    </Flexbox>
  );
}
