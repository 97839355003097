import React from 'react';
import { signOut } from 'next-auth/react';
import startCase from 'lodash/startCase';

import { Educator } from '../../../context/User';

import { ProfileItem } from './ProfileItem';

import cog from '@/images/cog-icon.svg';
import location from '@/images/location-icon.svg';
import pin from '@/images/pin-icon.svg';
import profile from '@/images/profile-icon.svg';
import logout from '@/images/logout-icon.svg';

interface ProfileDropdownProps extends Partial<Educator> {
  hidden: boolean;
}

export function ProfileDropdown({
  givenName,
  state,
  school,
  role,
  hidden
}: ProfileDropdownProps) {
  return (
    <div
      className={`absolute right-0 top-12 z-40 w-64 rounded-2xl bg-white p-4 text-[#1D252C] shadow-dropdown transition duration-500 ${
        hidden
          ? 'pointer-events-none -translate-y-1 opacity-0'
          : 'translate-y-0 opacity-100'
      }`}
      data-testid="profile-dropdown"
    >
      <ul>
        <ProfileItem title={givenName!} description="Profile" />

        {state && (
          <ProfileItem
            title={startCase(state.toLowerCase())!}
            description="State"
            icon={location}
          />
        )}

        {role && (
          <ProfileItem title={role!} description="Role" icon={profile} />
        )}

        {school && (
          <ProfileItem title={school!} description="School" icon={pin} />
        )}

        <ProfileItem
          url="/classrooms/lesson-library/u/settings"
          title="Settings"
          icon={cog}
          borderTop
        />

        <ProfileItem
          title="Log out"
          icon={logout}
          onClick={() =>
            signOut({
              redirect: true,
              callbackUrl: '/api/auth/logout'
            })
          }
        />
      </ul>
    </div>
  );
}
