import React from 'react';

import { Text } from '../../Text';

import { NodeProps } from './shared';

interface HeadingNodeProps extends NodeProps {
  type: 'h1' | 'h2' | 'h3';
}

export function HeadingNode({ children, type }: HeadingNodeProps) {
  return (
    <Text
      as={type}
      fontSize={{
        0: `${type}_0`,
        tablet: type === 'h2' ? `${type}_desktop` : `${type}_tablet`
      }}
      fontWeight={type}
      marginTop={{ 0: 'ml', tablet: 'l' }}
      marginBottom={{ 0: 'sm', tablet: 'm' }}
    >
      {children}
    </Text>
  );
}
