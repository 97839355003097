import styled from '../../../../theme/styled-with-theme';
import { pxToRem } from '../../../../styles/utils';
import { mediaQueries } from '../../../../styles/media-queries';

import { ColorToken } from '../../../../theme/tokens/color';

import { BlockContainer, Column } from '../../../Layout';
import {
  InlineAnchor as RichInlineAnchor,
  handleColorType
} from '../../../RichText/styles';
import Link from '../../../Link';

export const LegalBlockContainer = styled(BlockContainer)`
  padding: ${pxToRem(56)} ${pxToRem(48)};

  ${mediaQueries[0]} {
    padding-left: ${pxToRem(40)};
    padding-right: ${pxToRem(40)};
  }

  ${mediaQueries.tablet} {
    padding: ${({ theme }) => `${pxToRem(40)} ${theme.spacing.l}`};
  }
`;

export const BrightEdgeColumn = styled(Column)`
  padding-top: ${({ theme }) => theme.spacing.sm};
  padding-bottom: ${({ theme }) => theme.spacing.sm};
  align-items: center;

  ${mediaQueries.desktop} {
    justify-content: center;
  }
`;

export const FooterLegalNavItemList = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 0 -${pxToRem(8)};
`;

export const SecondaryNavItem = styled('li')`
  padding: 0 ${({ theme }) => theme.spacing.s};

  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.sm};

  &:not(:last-child) {
    border: none;
  }

  ${mediaQueries.desktop} {
    display: inline-block;
    margin-bottom: ${({ theme }) => theme.spacing.xs};

    &:not(:last-child) {
      border-right: 1px solid ${({ theme }) => theme.color.footerTextSecondary};
    }
  }
`;

export const SecondaryLink = styled(Link)`
  ${({ theme }) => ({
    color: theme.color.footerTextSecondary,
    fontSize: `${theme.fontSize.s}`,
    lineHeight: theme.lineHeight.s
  })}
  font-weight: ${({ theme }) => theme.fontWeight.secondaryFooterAnchor};
  text-decoration: none;
  transition: 0.2s color ease;

  &:hover {
    color: ${({ theme }) => theme.color.inlineAnchorHover};
    text-decoration: underline;
  }
`;

export const InlineAnchor = styled(RichInlineAnchor)<{
  hoverColor?: ColorToken;
}>`
  ${({ theme, hoverColor = 'inlineAnchorHover' }) => `
    &:hover {
      color: ${handleColorType(theme, hoverColor)};
    }

    ${mediaQueries.desktop} {
      &:hover {
        color: 
          ${handleColorType(theme, hoverColor)};
      }
    }
  `}
`;
