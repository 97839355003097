import { useState } from 'react';
import { getCookie, setCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';

import { getCookieDomain } from '../utils/cookies';

export function useCookie(key: string, defaultValue?: string) {
  const getItem = () => getCookie(key)?.toString() || defaultValue;

  const [cookie, setItem] = useState(getItem());

  const updateCookie = (value: any, options: OptionsType = {}) => {
    const domain = options?.domain || getCookieDomain();
    setCookie(key, value, { ...options, domain });
    setItem(value);
  };

  return [cookie, updateCookie] as const;
}
