import styled from '../../../theme/styled-with-theme';
import { Button as StyledButton } from '../../Button';
import { mediaQueries } from '../../../styles/media-queries';
import { pxToRem } from '../../../styles/utils';
import { Box } from '../../Box';
import StyledPhoneNumberInput from '../../Input';

const OVERLAP = 2;

export const Container = styled('div')`
  display: flex;
  align-items: stretch;
  height: ${pxToRem(32)};
  position: relative;
  z-index: 3;
  ${mediaQueries.desktop} {
    height: ${pxToRem(40)};
  }
`;

export const ErrorContainer = styled('div')`
  position: relative;
  width: 100%;
`;

export const Error = styled('div')<{ position?: string }>`
  background-color: ${({ theme }) => theme.color.black};
  width: 100%;
  color: white;
  font-size: ${({ theme }) => theme.fontSize.s};
  text-align: center;
  position: absolute;
  z-index: 2;

  ${({ position, theme }) =>
    position === 'top'
      ? `
      padding: ${theme.spacing.m};
      padding-bottom: ${theme.spacing.ml};
      border-radius: 20px 20px 0 0;
      bottom: 25px;
    `
      : `
      padding: ${theme.spacing.m};
      border-radius: 0 0 20px 20px;
      top: 35px;
      `};
`;

export const Input = styled(StyledPhoneNumberInput, {
  shouldForwardProp: (prop) => prop !== 'ctaText'
})<{
  floatLabel?: boolean;
}>`
  flex: 1 0 auto;
  margin-right: -${OVERLAP}rem;
  font-size: ${({ theme }) => theme.fontSize.input_0};
  padding-right: ${OVERLAP + 0.25}rem;
  width: 73%;
  ::placeholder {
    visibility: ${({ floatLabel }) => (floatLabel ? `hidden` : `visible`)};
  }

  ${mediaQueries.tablet} {
    font-size: ${({ theme }) => theme.fontSize.input_tablet};
  }
  ${mediaQueries.desktop} {
    font-size: ${({ theme }) => theme.fontSize.input_desktop};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.white};
  }
`;

export const Button = styled(StyledButton)`
  min-width: fit-content;
  width: 45%;
  height: ${pxToRem(32)};
  ${mediaQueries.tablet} {
    font-size: ${({ theme }) => theme.fontSize.button_0};
  }
  ${mediaQueries.desktop} {
    font-size: ${({ theme }) => theme.fontSize.button_desktop};
    min-width: fit-content;
  }
`;

export const PhoneNumberWrapper = styled(Box)`
  max-width: ${pxToRem(270)};
  min-width: ${pxToRem(220)};
  height: ${pxToRem(36)};

  ${mediaQueries.tablet} {
    min-width: ${pxToRem(254)};
    max-width: ${pxToRem(270)};
    height: ${pxToRem(40)};
  }
  ${mediaQueries.desktop} {
    min-width: ${pxToRem(260)};
    max-width: ${pxToRem(390)};
    height: ${pxToRem(40)};
  }
`;
