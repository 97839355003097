import styled from '../../../../theme/styled-with-theme';
import { pxToRem } from '../../../../styles/utils';
import { mediaQueries } from '../../../../styles/media-queries';
import { Box } from '../../../Box';

export const SocialIconBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${pxToRem(168)};

  ${mediaQueries.min} {
    margin-left: ${({ theme }) => theme.spacing.auto}
    margin-right: ${({ theme }) => theme.spacing.auto}
  }

  ${mediaQueries.desktop} {
    margin-left: ${({ theme }) => theme.spacing[0]};
  }
`;
