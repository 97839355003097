import {
  IAffiliateCtaFields,
  IRegistrationEntrypointFields
} from '@/types/contentful';

import { ButtonColorTokenProp } from '../../theme/tokens/button-color';
import { CFColorTokenProp } from '../../theme/tokens/contentful-color';

import { InputProps } from '../Input';

export const DEFAULT_LABEL = 'Enter mobile number';
export const DEFAULT_PLACEHOLDER = '(000) 000-0000';
export const DEFAULT_BUTTON_TEXT = 'Get Started';

export const DEFAULT_EMAIL_LABEL = 'Enter email address';
export const DEFAULT_EMAIL_CTA_LABEL = 'Enter email address through CTA';
export const DEFAULT_EMAIL_BUTTON_TEXT = 'Notify Me';
export const VALID_EMAIL =
  'We will let you know when the app is up and running';
export const DEFAULT_EMAIL_WIDGET_BUTTON_TEXT = 'Submit';

export const SUBMITTED_TEXT = 'Submitted!';
export const THANKS_TEXT_WITH_SPACING =
  '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Thank you!';
export const THANKS_TEXT = 'Thank you!';

export enum ctaWidgetEnum {
  GET_STARTED = 'Get Started Button',
  GET_STARTED_NO_PHONE = 'Get Started - No Phone',
  GET_STARTED_V1 = '1.0 Get Started',
  MOBILE_PHONE_NUMBER = 'Button Inside Phone Number',
  MOBILE_PHONE_NUMBER_DROPSHADOW = 'Button Inside Phone Number - With Dropshadow',
  DESKTOP_PHONE_NUMBER = 'Button Next To Phone Number',
  DESKTOP_PHONE_NUMBER_V1 = '1.0 Button Overlapping Phone Number',
  INBLOCK_PHONE_NUMBER_V1 = '1.0 In Block Phone Number',
  EMAIL = 'Email',
  STANDARD = 'Standard Button',
  ROLE_SELECT_WEBREG = 'Role Select Button - Webreg',
  GL4C_AUTH = 'GL4C Auth',
  GL4C_INLINE_AUTH = 'GL4C Inline Auth'
}

export interface RegistrationEntrypoint
  extends Partial<
    Omit<
      IRegistrationEntrypointFields,
      | 'designType'
      | 'buttonColor'
      | 'buttonHoverColor'
      | 'buttonText'
      | 'returningUserButtonText'
    >
  > {
  sysId?: string;
  designType?: ctaWidgetEnum | IRegistrationEntrypointFields['designType'];
  buttonText?: JSX.Element | string;
  returningUserButtonText?: string;
  buttonColor?: ButtonColorTokenProp | CFColorTokenProp;
  buttonHoverColor?: ButtonColorTokenProp | CFColorTokenProp;
  buttonContent?: JSX.Element | string;
  cfButtonColor?: CFColorTokenProp;
  cfButtonHoverColor?: CFColorTokenProp;
  secondaryButtonText?: string;
  secondaryCfButtonColor?: CFColorTokenProp;
  secondaryCfButtonHoverColor?: CFColorTokenProp;
}

export type PartialCtaProps = RegistrationEntrypoint &
  Partial<IAffiliateCtaFields> &
  Partial<SharedProps>;

export interface SharedProps {
  id?: string;
  className?: string;
  submitted?: boolean;
  label?: string;
  placeholder?: string;
  errorMessage?: string | null;
  errorPosition?: 'top' | 'bottom';
  showErrorBanner?: boolean;
  sendConsentEvent?: boolean;
}

export interface WidgetProps extends RegistrationEntrypoint {
  inputProps?: InputProps;
  isScrolled?: boolean;
  setErrorMessage?: Function;
  isReturning?: boolean;
  responsive?: boolean;
}
