import React, { useRef } from 'react';
import Image from 'next/legacy/image';

import { useEscapeEvent } from '../../hooks/useEscapeEvent';
import { useRefClickEvent } from '../../hooks/useRefClickEvent';

import { Button } from '../Button';
import { ToggleSwitch } from '../ToggleSwitch';

import {
  DisableBackground,
  Modal,
  ModalContainer,
  ModalBody,
  ModalHeader,
  ModalSubHeader,
  CloseButton,
  ModalContent,
  ButtonWrapper
} from './styles';

import fullLogo from '@/images/gl-logo-full.svg';

interface ConsentModalProps {
  optedOut: boolean;
  setOptedOut: (val: boolean) => void;
  toggleOptOutModal: () => void;
}

export function ConsentModal({
  optedOut,
  setOptedOut,
  toggleOptOutModal
}: ConsentModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEscapeEvent(toggleOptOutModal);
  useRefClickEvent(modalRef, toggleOptOutModal);

  return (
    <>
      <DisableBackground />
      <Modal data-testid="consent-modal" ref={modalRef}>
        <ModalBody>
          <ModalContainer>
            <ModalHeader>
              <Image src={fullLogo} alt="Greenlight logo" />
              <CloseButton
                data-testid="header-close-button"
                onClick={() => toggleOptOutModal()}
              />
            </ModalHeader>
            <div>
              <ModalSubHeader>
                Do Not Sell or Share My Personal Information
              </ModalSubHeader>
              <ModalContent>
                We use cookies and other similar technologies on our Site to
                help us advertise our Service on other websites or services you
                visit. We share information we collect on our Site, such as
                information about the pages you visit and the actions you take
                on our Site, with advertising networks and analytics partners to
                support our interest-based advertising, which may qualify as a
                sale or sharing of personal information, or targeted
                advertising, under applicable law.
                <br />
                <br />
                To opt-out of our use or sharing of your information for
                targeted advertising, please adjust the toggle below.
              </ModalContent>
              <ModalHeader>
                <h4 style={{ fontWeight: 500 }}>Opt-out</h4>
                <div>
                  <ToggleSwitch
                    toggleName="consent-modal"
                    checked={optedOut}
                    onChange={() => {
                      setOptedOut(!optedOut);
                    }}
                  />
                </div>
              </ModalHeader>
            </div>
          </ModalContainer>
          <ButtonWrapper>
            <Button
              buttonColor="dark"
              onClick={() => {
                toggleOptOutModal();
                window.location.reload();
              }}
            >
              Save and close
            </Button>
          </ButtonWrapper>
        </ModalBody>
      </Modal>
    </>
  );
}
