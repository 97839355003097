import React from 'react';

import { NodeProps } from './shared';

export function HorizontalRuleNode({ children }: NodeProps) {
  return (
    <div>
      <hr data-testid="horizontal-rule"></hr>
      {children}
    </div>
  );
}
