import React from 'react';

import { IPageFields, ISeoMetadata } from '@/types/contentful';

import { VariationContainer } from '../VariationContainer';
import Metadata from '../Metadata';

interface MetaProps extends IPageFields {
  pathPrefix?: string;
}

export default function Meta({ pathPrefix, seoMetadata, ...page }: MetaProps) {
  const { sys, fields } = seoMetadata ?? {};

  switch (sys?.contentType?.sys?.id) {
    case 'variationContainer':
      return <VariationContainer {...fields} sysId={sys?.id} />;

    case 'seoMetadata':
    default:
      return (
        <Metadata
          {...{ ...page, seoMetadata: seoMetadata as ISeoMetadata }}
          pathPrefix={pathPrefix ?? ''}
        />
      );
  }
}
