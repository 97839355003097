import React from 'react';

import { ILink } from '../../../../../types/contentful';

import { Link } from './Link';

interface NavListProps {
  links: ILink[];
}

export function NavList({ links }: NavListProps) {
  return (
    <ul className="m-0 flex list-none columns-1 flex-col gap-3 p-0 [&_li]:break-inside-avoid-column">
      {links && links.map(({ fields }, idx) => <Link key={idx} {...fields} />)}
    </ul>
  );
}
