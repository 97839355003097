import getConfig from 'next/config';

import { getUrlParamFromCookie } from './cookies';
import getUrlSearchParams from './getUrlSearchParams';
import { getAnonymousId } from './getAnonymousId';

import { UrlByEnv, envEnum } from '../context/PageData';
import { UserData } from '../lib/referrals/getUserDataById';
import { initialize } from 'src/lib/onboarding/initialize';
import { snakeToCamel } from './camelSnakeConverter';
const { publicRuntimeConfig } = getConfig();
const { nodeEnv } = publicRuntimeConfig;

interface WebRegBits {
  baseUrl: string;
  phoneNumber?: string;
  forwardedUrlParams?: string[] | null;
  bypassPhoneNumberEntry: boolean;
  forwardedCtaParams?: string | null;
  forwardedButtonParams?: string | null;
  emailAddress?: string;
  advocateUserData?: UserData | null;
  role?: string;
}

export function getRedirectByEnv(ctaDestinationUrls: UrlByEnv = {}) {
  const env = nodeEnv as keyof typeof envEnum;
  // Use webreg url from contentful or default to env variable
  return ctaDestinationUrls[envEnum[env]];
}

export function appendParams({
  baseUrl,
  phoneNumber,
  forwardedUrlParams,
  bypassPhoneNumberEntry,
  forwardedCtaParams,
  forwardedButtonParams,
  emailAddress,
  advocateUserData,
  role
}: WebRegBits) {
  const anonymousId = getAnonymousId();
  // Default is organic; If no cookies, assumed to be incognito
  const utmSource = getUrlParamFromCookie('utm_source');
  const utmMedium = getUrlParamFromCookie('utm_medium');
  const utmCampaign = getUrlParamFromCookie('utm_campaign');
  const utmTerm = getUrlParamFromCookie('utm_term');
  const utmContent = getUrlParamFromCookie('utm_content');
  const irclickid = getUrlParamFromCookie('irclickid');
  const branchMatchId = getUrlParamFromCookie('_branch_match_id');
  const maxRedemptionLimitReach = getUrlParamFromCookie(
    'max_redemption_limit_reached'
  );
  const referralRewardValue = getUrlParamFromCookie('referral_reward_value');
  const buttonParams = forwardedButtonParams ? `&${forwardedButtonParams}` : '';
  const ctaParams = forwardedCtaParams ? `&${forwardedCtaParams}` : '';
  if (forwardedUrlParams) {
    forwardedUrlParams?.push('gclid');
  } else {
    forwardedUrlParams = new Array('gclid');
  }
  const advocateIsTeen = advocateUserData?.isTeen
    ? `&advocate-is-teen=${advocateUserData?.isTeen}`
    : '';

  const addtlParams = getUrlSearchParams(
    forwardedUrlParams as string[],
    buttonParams,
    ctaParams
  );

  const url = new URL(baseUrl || '');

  if (bypassPhoneNumberEntry === false && phoneNumber) {
    url.searchParams.append('phoneNumber', phoneNumber);
  }

  if (bypassPhoneNumberEntry === false && emailAddress) {
    url.searchParams.append('emailAddress', emailAddress);
  }

  if (anonymousId) {
    url.searchParams.append('anonymous_id', encodeURIComponent(anonymousId));
  }

  if (!utmSource) {
    url.searchParams.append('utm_source', 'incognito');
  }

  if (role) {
    url.searchParams.append('role', role);
  }

  return `${url}${utmSource}${utmMedium}${utmCampaign}${utmTerm}${utmContent}${irclickid}${branchMatchId}${addtlParams}${ctaParams}${buttonParams}${maxRedemptionLimitReach}${referralRewardValue}${advocateIsTeen}`;
}

export async function webregInitialize(
  webregRedirectUrl: string
): Promise<boolean> {
  const url = new URL(webregRedirectUrl);
  const params = new URLSearchParams(url.search);
  const configuredParams = configureParams(params);
  return await initialize(configuredParams);
}

function configureParams(params: URLSearchParams) {
  const paramsObj = Object.fromEntries(params.entries());
  return snakeToCamel(paramsObj);
}
