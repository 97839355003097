import { getCookie } from './cookies';
import { trackRumEvent } from './datadogRum';

export const SEGMENT_ANON_ID_KEY = 'ajs_anonymous_id';

/**
 * Used to find any existing anon IDs set by Segment and Nextjs Middleware.
 * Compares anon IDs from our GL cookie, Segment cookie, and local storage.
 * Returns the GL cookie first or fallbacks to Segment generated IDs.
 */
export const getAnonymousId = () => {
  let anonIdFromLocalStorage;
  let anonIdFromSegmentCookie;
  let anonIdFromGlCookie;

  try {
    if (typeof localStorage !== 'undefined') {
      anonIdFromLocalStorage = JSON.parse(
        localStorage.getItem(SEGMENT_ANON_ID_KEY) || '""'
      );
    }

    anonIdFromSegmentCookie = getCookie(SEGMENT_ANON_ID_KEY);
    anonIdFromGlCookie = getCookie('gl_anonymous_id');

    return (
      anonIdFromGlCookie ||
      anonIdFromSegmentCookie ||
      anonIdFromLocalStorage ||
      undefined
    );
  } catch (e) {
    trackRumEvent('Failed to get anonymous ID.', {
      anonIdFromGlCookie,
      anonIdFromSegmentCookie,
      anonIdFromLocalStorage
    });
    return undefined;
  }
};
