import isPropValid from '@emotion/is-prop-valid';

import styled from '../../theme/styled-with-theme';
import { pxToRem } from '../../styles/utils';
import { mediaQueries } from '../../styles/media-queries';

export const Input = styled('input', { shouldForwardProp: isPropValid })<{
  removeCancelButton?: boolean;
}>`
  padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.sm}`};
  font-size: ${({ theme }) => theme.fontSize.input_tablet};
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  border: ${({ theme }) => `1px solid ${theme.color.inputBorder}`};
  border-radius: ${({ theme }) => theme.borderRadius.input};
  color: ${({ theme }) => theme.color.black};
  &::placeholder {
    color: ${({ theme }) => theme.color.black};
  }

  ${mediaQueries.desktop} {
    border-radius: ${({ theme }) => theme.borderRadius.input_desktop};
  }

  &:-webkit-autofill,
  :-webkit-autofill:focus {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }

  &[data-autocompleted] {
    background-color: transparent !important;
  }

  ${({ removeCancelButton }) =>
    removeCancelButton
      ? `
      &[type="search"]::-webkit-search-decoration,
      &[type="search"]::-webkit-search-cancel-button,
      &[type="search"]::-webkit-search-results-button,
      &[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }
      `
      : ''}
`;

export const Label = styled('label', { shouldForwardProp: isPropValid })<{
  floatLabel: boolean;
  submitted?: boolean;
}>`
  position: absolute;
  top: 0;
  left: ${({ submitted }) => (submitted ? 'auto' : 0)};
  transition:
    margin-top 0.4s,
    font-size 0.125s,
    margin-left 0.3s,
    padding 0.3s;
  border-radius: ${({ theme }) => theme.borderRadius.input_desktop};
  color: ${({ theme }) => theme.color.defaultText};
  font-weight: 500;

  ${({ theme, floatLabel }) =>
    floatLabel
      ? `
      font-size: .75em;
      padding: 0 4px 0 4px;
      margin-top: ${theme.spacing.s};
      margin-left: ${theme.spacing.sm};
      background-color: #fff;
    `
      : `
      font-size: 0.95em;
      padding: ${pxToRem(19)} 0 0 ${theme.spacing.m};
    `}
`;
