import React from 'react';
import { useRouter } from 'next/router';

import {
  BlogsBlockquoteContainer,
  LearningBlockquoteContainer
} from '../styles';

import { NodeProps } from './shared';

export function BlockquoteNode({ children }: NodeProps) {
  const router = useRouter();

  return router.pathname.includes('learning-center') ? (
    <LearningBlockquoteContainer
      backgroundColor="white"
      borderRadius={{ 0: 'blockquote_0', tablet: 'blockquote_tablet' }}
    >
      {children}
    </LearningBlockquoteContainer>
  ) : (
    <BlogsBlockquoteContainer
      backgroundColor="brand"
      borderRadius={{ 0: 'blockquote_0', tablet: 'blockquote_tablet' }}
    >
      {children}
    </BlogsBlockquoteContainer>
  );
}
