import styled from '../../theme/styled-with-theme';
import { mediaQueries, breakpoints } from '../../styles/media-queries';
import { useTokenProp } from '../../theme/tokens/utils';
import { TokenProp } from '../../theme/tokens/types';
import { SpacingToken, SpacingTokens } from '../../theme/tokens/spacing';
import { Box } from '../Box';
import { Flexbox } from '../Flexbox';
import { Theme } from '../../theme/types';
import { BlockContainerProps, LayoutProps } from '.';

export const StyledBlockContainer = styled(Flexbox)<BlockContainerProps>`
  width: 100%;
  justify-content: center;
  scroll-margin-top: ${({ theme }) => theme.spacing['2xl']};

  > * {
    max-width: ${breakpoints.max}px;

    ${({ xPaddingOverrides, theme }) => ({
      paddingLeft:
        xPaddingOverrides && 0 in xPaddingOverrides
          ? xPaddingOverrides[0]
          : theme.spacing.sm,
      paddingRight:
        xPaddingOverrides && 0 in xPaddingOverrides
          ? xPaddingOverrides[0]
          : theme.spacing.sm
    })}

    ${mediaQueries.min} {
      ${({ xPaddingOverrides, theme }) => ({
        paddingLeft:
          xPaddingOverrides && 'min' in xPaddingOverrides
            ? xPaddingOverrides.min
            : theme.spacing.sm,
        paddingRight:
          xPaddingOverrides && 'min' in xPaddingOverrides
            ? xPaddingOverrides.min
            : theme.spacing.sm
      })}
    }

    ${mediaQueries.mobile} {
      ${({ xPaddingOverrides, theme }) => ({
        paddingLeft:
          xPaddingOverrides && 'mobile' in xPaddingOverrides
            ? xPaddingOverrides.mobile
            : theme.spacing.sm,
        paddingRight:
          xPaddingOverrides && 'mobile' in xPaddingOverrides
            ? xPaddingOverrides.mobile
            : theme.spacing.sm
      })}
    }

    ${mediaQueries.tablet} {
      ${({ xPaddingOverrides, theme }) => ({
        paddingLeft:
          xPaddingOverrides && 'tablet' in xPaddingOverrides
            ? xPaddingOverrides.tablet
            : theme.spacing.xl,
        paddingRight:
          xPaddingOverrides && 'tablet' in xPaddingOverrides
            ? xPaddingOverrides.tablet
            : theme.spacing.xl
      })}
    }

    ${mediaQueries.desktop} {
      ${({ xPaddingOverrides, theme }) => ({
        paddingLeft:
          xPaddingOverrides && 'desktop' in xPaddingOverrides
            ? xPaddingOverrides.desktop
            : theme.spacing.xl,
        paddingRight:
          xPaddingOverrides && 'desktop' in xPaddingOverrides
            ? xPaddingOverrides.desktop
            : theme.spacing.xl
      })}
    }

    ${mediaQueries.max} {
      ${({ xPaddingOverrides, theme }) => ({
        paddingLeft:
          xPaddingOverrides && 'max' in xPaddingOverrides
            ? xPaddingOverrides.max
            : theme.spacing.xl,
        paddingRight:
          xPaddingOverrides && 'max' in xPaddingOverrides
            ? xPaddingOverrides.max
            : theme.spacing.xl
      })}
    }
  }
`;

const useGutterProp = useTokenProp<
  SpacingToken,
  LayoutProps & { theme: Theme }
>(
  'margin',
  (props) => props.gutter,
  (props, gutter) =>
    gutter
      ? `-${
          parseFloat(props.theme.spacing[gutter as keyof SpacingTokens]) / 2
        }rem`
      : ''
);

const useGutterPaddingProp = useTokenProp<
  SpacingToken,
  LayoutProps & { theme: Theme }
>(
  'padding',
  (props) => props.gutter,
  (props, gutter) =>
    gutter
      ? `${
          parseFloat(props.theme.spacing[gutter as keyof SpacingTokens]) / 2
        }rem`
      : ''
);

export const LayoutContainer = styled(Box)`
  flex: 1 0 100%;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 1 0 0px;
  }

  ${mediaQueries.max} and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 1 0 100%;
  }
`;

export const StyledLayout = styled(Flexbox)<LayoutProps>`
  ${useGutterProp}
  justify-content: center;
  flex-wrap: wrap;

  > * {
    ${useGutterPaddingProp}
  }
`;

const NUM_COLUMNS = 12;

export type ColumnSize = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface ColumnProps {
  children?: React.ReactNode;
  size: TokenProp<ColumnSize>;
}

const useColumnSizeProp = useTokenProp<ColumnSize, ColumnProps>(
  'width',
  (props) => props.size,
  (props, size) => {
    if (typeof size !== 'undefined') {
      return `${(100 / NUM_COLUMNS) * Number(size)}%`;
    }
    return '';
  }
);

export const Column = styled(Flexbox)<ColumnProps>`
  ${useColumnSizeProp}
  flex-grow: 0;
  flex-shrink: 0;
`;

export const BoxColumn = styled(Box)<ColumnProps>`
  ${useColumnSizeProp}
  flex-grow: 0;
  flex-shrink: 0;
`;
