import Image, { ImageLoaderProps, ImageProps } from 'next/legacy/image';

import { StyledObject, SvgWrapper } from './styles';

export interface GlImageProps extends ImageProps {
  sysId?: string;
  heroImage?: boolean;
  contentType?: string | null;
}

const imageLoader = ({ src, width }: ImageLoaderProps) =>
  `https:${src}?w=${width}&fm=avif`;

export function GlImage({
  src,
  sysId,
  contentType,
  heroImage,
  className,
  layout = 'responsive',
  loading = process.env.NEXT_PUBLIC_ENVIRONMENT === 'percy' ? 'eager' : 'lazy',
  style,
  ...props
}: GlImageProps) {
  if (!src) return null;

  let defaultSizes = null;

  switch (layout) {
    case 'fill':
      defaultSizes = '100vw';
      break;
    case 'responsive':
      if (props.width) {
        defaultSizes = `(max-width: ${props.width}px) 100vw, ${props.width}px`;
      } else {
        defaultSizes = '100vw';
      }
      break;
    case 'intrinsic':
    case 'fixed':
    default:
      if (props.width) {
        defaultSizes = `${props.width}px`;
      } else {
        defaultSizes = '100vw';
      }
      break;
  }

  if (heroImage) {
    return (
      <Image
        {...props}
        src={src}
        loader={imageLoader}
        className={className}
        priority={true}
        data-testid="gl-hero-img"
        data-cfid={sysId}
        layout={layout}
        sizes={defaultSizes}
        style={{
          zIndex: 0,
          ...style
        }}
      />
    );
  }

  switch (contentType) {
    case src && 'image/svg+xml':
      return (
        <SvgWrapper>
          <StyledObject
            className={className}
            data={src as string}
            type={contentType}
            data-script-order={0}
            data-testid="svg-image-type"
            data-cfid={sysId}
          >
            <Image
              {...props}
              src={src}
              loader={imageLoader}
              className={className}
              loading={loading}
              sizes={defaultSizes}
              data-testid="gl-img"
              layout={layout}
              data-cfid={sysId}
              style={{
                ...style
              }}
            />
          </StyledObject>
        </SvgWrapper>
      );

    case 'image/gif':
      return (
        <Image
          {...props}
          src={src}
          loader={imageLoader}
          className={className}
          loading={loading}
          layout={layout}
          sizes={defaultSizes}
          data-testid="gl-img"
          data-cfid={sysId}
          style={{
            width: '100%',
            ...style
          }}
        />
      );

    case src && 'image/svg+xml+only':
    case 'image/jpeg':
    case 'image/png':
    default:
      return (
        <Image
          {...props}
          src={src}
          loader={imageLoader}
          className={className}
          loading={loading}
          layout={layout}
          sizes={defaultSizes}
          data-testid="gl-img"
          data-cfid={sysId}
          style={{
            ...style
          }}
        />
      );
  }
}

export default GlImage;
