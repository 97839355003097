import { getCookie } from './cookies';
import { isIE } from './browsers';
import analytics from './analytics';

export const trackWithFingerprint = async (
  event: string,
  properties?: Record<string, any>,
  options?: {},
  callback?: () => void
) => {
  const branch = (window as any).branch;

  const irclickid = getCookie('irclickid');

  if (irclickid) {
    options = {
      referrer: {
        type: 'impactRadius',
        id: irclickid.replace('&irclickid=', '')
      }
    };
  }

  if (isIE() || !branch) {
    // TODO: is branch every defined?
    return await analytics.track(event, properties, options, callback);
  }

  const loadBranchAndGetFingerprint = new Promise(function (resolve, reject) {
    branch.getBrowserFingerprintId(function (err: Error, fingerprint: any) {
      if (!!err) {
        reject(err);
        return;
      }
      resolve({ fingerprint });
    });
  });

  return loadBranchAndGetFingerprint.then(async (data: any) => {
    if (data) {
      const { fingerprint } = data;

      await analytics.track(
        event,
        { ...properties, browser_fingerprint_id: fingerprint },
        options,
        callback
      );
    }
  });
};
