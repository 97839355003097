export enum TextInputType {
  TEXT = 'text',
  EMAIL = 'email',
  PASSWORD = 'password',
  PHONE = 'tel',
  NUMBER = 'number',
  DATE = 'date',
  SEARCH = 'search'
}

export enum TextInputWidth {
  FULL = '100',
  THREE_QUARTER = '75',
  HALF = '50'
}

export type InputAutocomplete =
  | 'tel'
  | 'tel-national'
  | 'email'
  | 'name'
  | 'given-name'
  | 'family-name'
  | 'address-line1'
  | 'address-line2'
  | 'address-level1'
  | 'address-level2'
  | 'postal-code'
  | 'bday'
  | undefined;

export interface BaseInputProps {
  value: string | number | readonly string[] | undefined;
  type: TextInputType;
  label: string;
  placeholder?: string;
  error?: string | null;
  disabled?: boolean;
  required?: boolean;
  inputMode?:
    | 'text'
    | 'none'
    | 'email'
    | 'search'
    | 'tel'
    | 'url'
    | 'numeric'
    | 'decimal'
    | undefined;
  pattern?: string;
  width?: TextInputWidth;
  minLength?: number;
  maxLength?: number;
  autoComplete?: InputAutocomplete;
}
