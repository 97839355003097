import { IDynamicImage } from '@/types/contentful';
import { Asset } from 'contentful';

export interface ImageData {
  sysId: string;
  src: string;
  contentType: string;
  alt: string;
  width: number;
  height: number;
  adGroupId?: string;
}

export const getImageData = (asset: Asset): ImageData => {
  const { sys, fields } = asset ?? {};

  return {
    sysId: sys?.id ?? '',
    src: fields?.file?.url ?? '',
    contentType: fields?.file?.contentType ?? '',
    alt: fields?.description ?? 'Greenlight image',
    width: fields?.file?.details?.image?.width ?? 1,
    height: fields?.file?.details?.image?.height ?? 1
  };
};

export const getImageDataFromOptions = (
  keyword: string,
  channel: string,
  adGroupId: string,
  fallbackImage: Asset,
  options?: IDynamicImage[]
): ImageData => {
  if (!options || !options.length) {
    return getImageData(fallbackImage);
  }

  const variation = options.find(
    ({ fields }: IDynamicImage) =>
      fields.keyword === keyword &&
      (!adGroupId ||
        (fields.adgroupId && fields.adgroupId.includes(adGroupId))) &&
      fields.channels.includes(channel)
  );

  return variation
    ? getImageData(variation?.fields?.image)
    : getImageData(fallbackImage);
};

export const getImageDataWithHttps = (image: Asset): ImageData => {
  const imageData = getImageData(image);
  return {
    ...imageData,
    src: `https:${imageData.src}`
  };
};
