import React from 'react';

import { ColorTokenProp } from '../../../theme/tokens/color';

import { InlineAnchor } from '../styles';

import { NodeProps } from './shared';

interface HyperlinkNodeProps extends NodeProps {
  colour: ColorTokenProp | string;
}
export function HyperlinkNode({ node, children, colour }: HyperlinkNodeProps) {
  return (
    <InlineAnchor href={node.data.uri} colour={colour}>
      {children}
    </InlineAnchor>
  );
}
