import React from 'react';
import cx from 'classnames';

import {
  Sublink,
  SublinkList,
  SublinkItem,
  DropdownContainer
} from '../DesktopNavbar.styles';

interface Props {
  displayDropdown: boolean;
  links?: any[];
  desktopLinks?: any[];
}

export function Dropdown({ displayDropdown, links, desktopLinks }: Props) {
  return displayDropdown ? (
    <>
      {desktopLinks && (
        <div
          className={cx(
            'z-[-10] w-11/12 rounded-2xl bg-white px-6 pb-5 pt-6 drop-shadow-[0_4px_24px_rgba(0,0,0,0.08)]',
            {
              'desktop:columns-3': desktopLinks.length > 7,
              'desktop:columns-1': desktopLinks.length <= 7
            }
          )}
          data-testid="desktop-mega-nav-dropdown"
        >
          <ul className="m-0 mr-5 w-full list-none p-0">
            {desktopLinks.map(
              (
                { fields: { title, url, openInNewTab, subcategoryLabel } },
                index
              ) => {
                const displayDuplicateLabel =
                  desktopLinks[index - 1]?.fields?.subcategoryLabel !==
                  subcategoryLabel
                    ? subcategoryLabel
                    : null;
                return (
                  <>
                    <li
                      key={title}
                      className={cx('pb-2', {
                        'pt-3':
                          displayDuplicateLabel &&
                          index != 0 &&
                          desktopLinks.length <= 7
                      })}
                    >
                      {displayDuplicateLabel && (
                        <p
                          className="text-base font-medium desktop:pb-2"
                          data-testid="subcategorylabel"
                        >
                          {displayDuplicateLabel}
                        </p>
                      )}
                      {url !== '#' ? (
                        <Sublink
                          data-testid="nav-dropdown-sublink"
                          href={url}
                          target={openInNewTab ? '_blank' : '_self'}
                        >
                          {title}
                        </Sublink>
                      ) : (
                        <Sublink data-testid="nav-dropdown-spacer" href="" />
                      )}
                    </li>
                  </>
                );
              }
            )}
          </ul>
        </div>
      )}
      {links && (
        <DropdownContainer
          dropdownSize={links.length}
          data-testid="nav-dropdown"
        >
          <SublinkList>
            {links.map(({ fields: { title, url, openInNewTab } }: any) => (
              <SublinkItem key={title}>
                <Sublink
                  data-testid="nav-dropdown-sublink"
                  href={url}
                  target={openInNewTab ? '_blank' : '_self'}
                >
                  {title}
                </Sublink>
              </SublinkItem>
            ))}
          </SublinkList>
        </DropdownContainer>
      )}
    </>
  ) : null;
}
