import React from 'react';

import { Property } from 'csstype';
import { StyledText } from './styles';
import { FontFamilyProp } from '../../theme/tokens/font-family';
import { ColorTokenProp } from '../../theme/tokens/color';
import { FontSizeProp } from '../../theme/tokens/font-size';
import { FontWeightProp } from '../../theme/tokens/font-weight';
import { LineHeightProp } from '../../theme/tokens/line-height';
import { SpacingProps } from '../../theme/tokens/spacing';
import { TokenProp } from '../../theme/tokens/types';

export type TextAlignProp = TokenProp<Property.TextAlign>;
export type FontStyleProp = TokenProp<Property.FontStyle>;

export interface TextProps extends SpacingProps {
  as?: React.ElementType;
  colour?: ColorTokenProp | string;
  fontFamily?: FontFamilyProp;
  fontSize?: FontSizeProp;
  fontWeight?: FontWeightProp;
  lineHeight?: LineHeightProp;
  textAlign?: TextAlignProp;
  fontStyle?: FontStyleProp;
}

export { StyledText as Text };
