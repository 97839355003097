import React from 'react';

import Link from '../../../Link';

import { SocialIconProps } from '../../../SocialIcons';

import { SocialIconBox } from './styles';

import {
  FacebookIcon,
  YouTube20Icon,
  Twitter20Icon,
  InstagramIcon,
  TikTokIcon
} from '../../../SocialIcons';

const icons = [
  {
    id: 'Facebook',
    url: 'https://www.facebook.com/GreenlightCard/'
  },
  {
    id: 'YouTube',
    url: 'https://www.youtube.com/c/GreenlightCard'
  },
  {
    id: 'TikTok',
    url: 'https://www.tiktok.com/@greenlightcard'
  },
  {
    id: 'Instagram',
    url: 'https://www.instagram.com/greenlightcard/'
  }
];

const Icon = ({ iconId, ...props }: SocialIconProps & { iconId: string }) => {
  switch (iconId) {
    case 'Facebook':
      return <FacebookIcon {...props} />;
    case 'YouTube':
      return <YouTube20Icon {...props} />;
    case 'TikTok':
      return <TikTokIcon {...props} />;
    case 'Twitter':
      return <Twitter20Icon {...props} />;
    case 'Instagram':
      return <InstagramIcon {...props} />;
    default:
      return null;
  }
};

export function SocialLinks() {
  return (
    <div
      className="mt-4 tiny:max-tablet:mt-6"
      data-section-id="social-links"
      data-testid="social-links"
    >
      <SocialIconBox>
        {icons.map(({ id, url }) => (
          <Link
            key={id}
            href={url}
            target="_blank"
            rel="noreferrer"
            aria-label={id}
          >
            <Icon
              iconId={id}
              colour="socialIconSupplementary"
              hoverColor="socialIconLightHover"
            />
          </Link>
        ))}
      </SocialIconBox>
    </div>
  );
}
