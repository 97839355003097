import { camelCase } from 'change-case';
import { snakeCase } from 'lodash';

import type { CamelCase, SnakeCase } from 'type-fest';

export function camelCaseStr<T extends string>(str: T): CamelCase<T> {
  return camelCase(str) as CamelCase<T>;
}

export function snakeToCamel(attr: any): any {
  if (attr === null || attr === undefined) {
    return attr;
  }

  if (Array.isArray(attr)) {
    return attr.map(snakeToCamel);
  }

  if (typeof attr === 'object') {
    return Object.fromEntries(
      Object.entries(attr).map(([key, value]): any => [
        camelCaseStr(key),
        snakeToCamel(value)
      ])
    );
  }
  return attr;
}

export function snakeCaseStr<T extends string>(str: T): SnakeCase<T> {
  return snakeCase(str) as SnakeCase<T>;
}

export function camelToSnake(attr: any): any {
  if (attr === null || attr === undefined) {
    return attr;
  }

  if (Array.isArray(attr)) {
    return attr.map(camelToSnake);
  }

  if (typeof attr === 'object') {
    return Object.fromEntries(
      Object.entries(attr).map(([key, value]): any => [
        snakeCaseStr(key),
        camelToSnake(value)
      ])
    );
  }

  return attr;
}
