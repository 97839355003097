import React from 'react';

import { IVariation, IVariationContainerFields } from '@/types/contentful';

import { useOptimizely, DEFAULT_VARIATION } from '../../context/Optimizely';

import { Variation } from '../Variation';

interface VariationContainerProps extends IVariationContainerFields {
  sysId?: string;
}

export function VariationContainer({
  experimentKey = '',
  variations,
  sysId,
  assignControlAsDefault = true
}: VariationContainerProps) {
  const experiments = useOptimizely();

  /*
  This is the main logic for determining which variation to display.
  If the experiment is enabled and has a valid variation key set , that variation is displayed.
  If the experiment is not enabled, or no variationKey is found, the control variation is displayed, unless assignControlAsDefault is set to false.
  */

  let variationKey: string | undefined;

  if (
    experiments[experimentKey]?.enabled &&
    experiments[experimentKey]?.variationKey
  ) {
    variationKey = experiments[experimentKey].variationKey;
  } else {
    variationKey = assignControlAsDefault ? DEFAULT_VARIATION : undefined;
  }

  return (
    <div
      className={`vc-${experimentKey}`}
      data-elid={sysId}
      data-expid={experimentKey}
      data-testid="variation-container"
    >
      {variations?.map(({ sys, fields }: IVariation) => {
        if (
          sys?.contentType?.sys?.id === 'variation' &&
          variationKey === fields.variationKey
        ) {
          return (
            <Variation
              {...fields}
              key={sys?.id}
              className={`vc-${experimentKey}__variation--${fields.variationKey} vc-${experimentKey}__variation`}
              isActive={variationKey === fields.variationKey}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}
