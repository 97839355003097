import React from 'react';

import { ILinkFields } from '../../../../../types/contentful';

import { FooterLink } from './styles';

export function Link({
  title,
  url,
  openInNewTab,
  hasAffiliateLink
}: ILinkFields) {
  const path = url ?? '';
  return (
    <li>
      <FooterLink
        href={hasAffiliateLink ? `/affiliates${path}` : path}
        target={openInNewTab ? '_blank' : ''}
        rel="noopener noreferrer"
      >
        {title}
      </FooterLink>
    </li>
  );
}
