import React from 'react';

import { IImageWith7030TextFields } from '../../../types/contentful';

import { getImageData } from '../../utils/contentful';

import { Text } from '../Text';
import { Box } from '../Box';
import RichText from '../RichText';

import {
  StyledImage,
  TextWrapper,
  EmbedContainer,
  TextBox,
  ImageBox,
  ImageWrapper
} from './styles';

export function ImageWith7030TextEmbed({
  image,
  headingText,
  imageFirst,
  bodyCopy
}: IImageWith7030TextFields) {
  return (
    <EmbedContainer imageFirst={imageFirst || false}>
      <ImageBox
        paddingBottom={{ 0: imageFirst ? 'ml' : 0, tablet: 0 }}
        paddingTop={{ 0: imageFirst ? 0 : 'ml', tablet: 0 }}
        paddingLeft={{
          0: 0,
          tablet: imageFirst ? 0 : 'ml',
          desktop: imageFirst ? 0 : 'xl'
        }}
        paddingRight={{
          0: 0,
          tablet: imageFirst ? 'ml' : 0,
          desktop: imageFirst ? 'xl' : 0
        }}
        flex="1 0 auto"
      >
        <ImageWrapper>
          <StyledImage
            {...getImageData(image)}
            data-blockid="imageWith7030Text-image"
          />
        </ImageWrapper>
      </ImageBox>
      <TextBox alignItems="center">
        <TextWrapper>
          <Box marginBottom={{ 0: 'sm' }} paddingTop={{ 0: 0, tablet: 'm' }}>
            <Text
              as="h3"
              fontWeight={{ 0: 'h3' }}
              fontSize={{ 0: 'h3_0', tablet: 'h3_tablet' }}
              data-blockid="imageWith7030Text-headline"
            >
              {headingText}
            </Text>
          </Box>
          {bodyCopy && (
            <Box data-blockid="imageWith7030Text-subhead">
              <RichText data={bodyCopy} />
            </Box>
          )}
        </TextWrapper>
      </TextBox>
    </EmbedContainer>
  );
}
