import Image from 'next/legacy/image';

import styled from '../../../../theme/styled-with-theme';
import { pxToRem } from '../../../../styles/utils';
import { mediaQueries, breakpoints } from '../../../../styles/media-queries';

import Link from '../../../Link';

export const DesktopNavbarContainer = styled('div')<{
  isScrolled: boolean;
  roundedCornersVariation: boolean | undefined;
}>`
  position: relative;
  display: none;
  justify-content: center;
  background-color: white;
  align-items: center;
  width: 100%;
  height: ${pxToRem(72)};
  margin: auto;
  z-index: 1;
  border-radius: ${({ roundedCornersVariation }) =>
    roundedCornersVariation ? `0 0 ${pxToRem(40)} ${pxToRem(40)}` : 'initial'};

  @media (min-width: 1080px) {
    display: flex;
    box-shadow: ${({ isScrolled }) =>
      isScrolled ? '0px 4px 4px rgba(0, 0, 0, 0.08)' : 'none'};
  }
`;

export const DesktopNavbarColumn = styled('div')`
  display: flex;
  width: 90vw;
  justify-content: space-between;
  align-items: center;
  max-width: ${breakpoints.max}px;
`;

export const LogoWrapper = styled('div')`
  height: ${pxToRem(24)};
  max-height: ${pxToRem(24)};
`;

export const LinksContainer = styled('div')<{ isSimplified: boolean }>`
  display: ${({ isSimplified }) => (isSimplified ? 'none' : 'flex')};
  flex-direction: row;
  margin-right: auto;
  margin-left: ${pxToRem(32)};
  width: 100%;

  ${mediaQueries.desktop} {
    max-width: ${pxToRem(640)};
  }
`;

export const NavLinkWrapper = styled('ul')`
  display: flex;
  margin: 0;
  padding: 0;
`;

export const NavLink = styled('li')`
  position: relative;
  font-weight: ${({ theme }) => theme.fontWeight['500']};
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.desktop_navbar_navlink};
  margin-top: 0;
  margin-right: ${pxToRem(32)};

  a {
    color: ${({ theme }) => theme.color.navbarLink};
    text-decoration: none;
    margin-right: ${pxToRem(8)};
  }

  img {
    align-self: center;
  }

  ${mediaQueries.max} {
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.xl};
    }
  }
`;

export const PositionCarets = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const NavlinkArrow = styled(Image)`
  position: relative;
`;

export const DropdownCaret = styled('div')<{ displayDropdown: boolean }>`
  position: absolute;
  top: ${pxToRem(28)};
  display: ${({ displayDropdown }) => (displayDropdown ? 'block' : 'none')};
  width: 0;
  height: 0;
  border-width: ${pxToRem(10)};
  border-style: solid;
  border-color: white;
  border-radius: ${pxToRem(5)};
  transform: rotate(45deg);
  z-index: 1;
`;

export const NavbarContainer = styled('nav')`
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  margin-top: 10vh;
`;

export const LinkPositionContainer = styled('div')`
  position: absolute;
  top: ${pxToRem(90)};
`;

export const SublinkContainer = styled('div')<{ displayDropdown: boolean }>`
  visibility: ${({ displayDropdown }) =>
    displayDropdown ? 'visible' : 'hidden'};
  max-height: ${pxToRem(220)};
  max-width: ${pxToRem(400)};
`;

export const SublinkList = styled('ul')`
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  column-count: 2;
`;

export const SublinkItem = styled('li')`
  margin-bottom: ${({ theme }) => theme.spacing.m};
`;

export const Sublink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.desktop_navbar_nav_sublink};
  font-weight: ${({ theme }) => theme.fontWeight['400']};
  color: ${({ theme }) => theme.color.navbarLink};
  text-decoration: none;
  margin-top: ${({ theme }) => theme.spacing.m};
  white-space: nowrap;
  width: fit-content;

  ${mediaQueries.desktop} {
    font-size: ${({ theme }) => theme.fontSize.desktop_navbar_nav_sublink};
    font-weight: ${({ theme }) => theme.fontWeight['400']};
    line-height: ${pxToRem(24)};
    margin: 0 ${pxToRem(50)} ${pxToRem(16)} 0;
    border-bottom: 1px solid transparent;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.color.desktopNavSublinkHoverColor};
      border-bottom: 1px solid
        ${({ theme }) => theme.color.desktopNavSublinkBorderColor};
    }
  }
`;

export const PositionDropdown = styled('div')`
  position: absolute;
  top: 0;
  padding-top: ${pxToRem(20)};
  left: -${pxToRem(32)};
`;

export const DropdownContainer = styled('div')<{ dropdownSize: number }>`
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: ${pxToRem(0)} ${pxToRem(4)} ${pxToRem(24)} rgba(0, 0, 0, 0.08);
  border-radius: ${({ theme }) => theme.borderRadius.desktop_navbar_dropdown};
  z-index: -10;
  margin-top: ${pxToRem(20)};
  padding: ${pxToRem(32)};
  padding-bottom: ${pxToRem(16)};
`;
