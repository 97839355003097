import React from 'react';
import { Block, BLOCKS, Inline, INLINES } from '@contentful/rich-text-types';

import { usePageDataContext } from '../../../../context/PageData';

import RichText from '../../../RichText';
import { Text } from '../../../Text';
import { EmbeddedInlineEntryNode } from '~/components/RichText/Nodes';
import { InlineAnchor } from './styles';

export function Disclaimers() {
  const { config: { footerDisclaimer } = {} } = usePageDataContext();

  return (
    <RichText
      data={footerDisclaimer}
      overrideOptions={{
        [INLINES.HYPERLINK]: (node, children) => (
          <InlineAnchor href={node.data.uri} colour="footerTextSecondary">
            {children}
          </InlineAnchor>
        ),
        [INLINES.EMBEDDED_ENTRY]: (node, children) => (
          <EmbeddedInlineEntryNode node={node} colour="white">
            {children}
          </EmbeddedInlineEntryNode>
        ),
        [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => (
          <Text
            as="p"
            fontSize={{ 0: '2xs', tablet: 's' }}
            colour="footerTextSecondary"
            fontWeight="400"
            marginTop="sm"
          >
            {children}
          </Text>
        )
      }}
    />
  );
}
