import styled from '../../theme/styled-with-theme';
import { pxToRem } from '../../styles/utils';
import { mediaQueries } from '../../styles/media-queries';

export const SvgWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledObject = styled('object')`
  width: 100%;

  ${mediaQueries[0]} {
    height: ${pxToRem(320)};
  }

  ${mediaQueries.min} {
    height: ${pxToRem(352)};
  }

  ${mediaQueries.mobile} {
    height: ${pxToRem(414)};
  }

  ${mediaQueries.tablet} {
    height: ${pxToRem(475)};
  }

  ${mediaQueries.desktop} {
    height: ${pxToRem(630)};
  }
`;
