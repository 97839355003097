import React, { useEffect, useMemo, useState } from 'react';
import { getCookieBannerComponent, OptInBanner } from './bannerUtils';
import { ICookieConsentBannerFields } from '@/types/contentful';
import { usePageDataContext } from '~/context/PageData';
import {
  CONSENT_COOKIE_KEY,
  CONSENT_OPT_IN,
  CONSENT_OPT_OUT,
  readOnetrustSettings
} from '~/utils/analytics/onetrust';
import { useCookie } from '~/hooks/useCookie';

const CookieBanner = (fields: ICookieConsentBannerFields) => {
  const [bannerComponent, setBannerComponent] = useState<React.ReactNode>(null);
  const [showBanner, toggleBanner] = useState<boolean>(true);

  const { cookies: { [CONSENT_COOKIE_KEY]: pvcyCookie } = {} } =
    usePageDataContext();

  // Handle consent cookie default from server
  const pvcySettings = useMemo(
    () => readOnetrustSettings(pvcyCookie),
    [pvcyCookie]
  );

  const [optedOut, setOptedOut] = useState<boolean>(
    pvcySettings?.C0004 === false
  );

  const [pCookie, updateCookie] = useCookie(CONSENT_COOKIE_KEY);

  useEffect(() => {
    // Update consent cookie when toggle changes
    const settings = readOnetrustSettings(pCookie);
    setOptedOut(settings?.C0004 === false);
  }, [pCookie]);

  const updatePrivacyStatus = () => {
    const expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 12);

    const pvcy = !optedOut ? CONSENT_OPT_IN : CONSENT_OPT_OUT;

    updateCookie(pvcy, { expires: expiryDate });
  };

  useEffect(() => {
    updatePrivacyStatus();
  }, [optedOut]);

  useEffect(() => {
    const requestBrowserGeolocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            // Get the appropriate banner component based on location
            const banner = getCookieBannerComponent(
              latitude,
              longitude,
              fields,
              setOptedOut,
              toggleBanner
            );
            setBannerComponent(banner);
          },
          () => {
            const expiryDate = new Date();
            expiryDate.setMonth(expiryDate.getMonth() + 12);
            updateCookie(CONSENT_OPT_OUT, { expires: expiryDate });
            setOptedOut(true);
            // User declined geolocation or an error occurred, default to opt-in banner
            setBannerComponent(
              <OptInBanner
                {...fields}
                setOptedOut={setOptedOut}
                toggleBanner={toggleBanner}
              />
            );
          }
        );
      } else {
        const expiryDate = new Date();
        expiryDate.setMonth(expiryDate.getMonth() + 12);
        updateCookie(CONSENT_OPT_OUT, { expires: expiryDate });
        setOptedOut(true);
        // Geolocation not supported, default to opt-in banner
        setBannerComponent(
          <OptInBanner
            {...fields}
            setOptedOut={setOptedOut}
            toggleBanner={toggleBanner}
          />
        );
      }
    };

    requestBrowserGeolocation();
  }, []);

  return <>{showBanner && <div>{bannerComponent}</div>}</>;
};

export default CookieBanner;
