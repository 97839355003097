export const breakpoints = {
  0: 0,
  min: 321,
  mobile: 480,
  tablet: 720,
  desktop: 1024,
  laptop: 1080,
  max: 1366
};

export const mediaQueries = {
  0: `@media (max-width: ${breakpoints.min - 1}px)`,
  min: `@media (min-width: ${breakpoints.min}px)`,
  mobile: `@media (min-width: ${breakpoints.mobile}px)`,
  tablet: `@media (min-width: ${breakpoints.tablet}px)`,
  desktop: `@media (min-width: ${breakpoints.desktop}px)`,
  laptop: `@media (min-width: ${breakpoints.laptop}px)`,
  max: `@media (min-width: ${breakpoints.max}px)`
};

export type Breakpoints = keyof typeof breakpoints;
