import { ICookieConsentBannerFields } from '@/types/contentful';
import React from 'react';
import RichText from '../RichText';
import { RenderNode } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import closeIcon from '@/images/x-icon.svg';
import Image from 'next/image';

const RICH_TEXT_OVERRIDES: RenderNode = {
  [BLOCKS.PARAGRAPH]: (_node, children) => (
    <div className="text-xs font-normal">{children}</div>
  ),
  [INLINES.HYPERLINK]: (node, children) => (
    <a href={node.data.uri} className="text-xs font-normal hover:text-teal">
      {children}
    </a>
  )
};

export const getCookieBannerComponent = (
  lat: number,
  lng: number,
  fields: ICookieConsentBannerFields,
  setOptedOut: (arg0: boolean) => void,
  toggleBanner: React.Dispatch<React.SetStateAction<boolean>>
): React.ReactNode => {
  //   Define Quebec boundaries
  const isInQuebec = lat >= 45 && lat <= 62 && lng >= -79.5 && lng <= -57;

  //   Define general Canada boundaries
  const isInCanada = lat >= 41 && lat <= 83 && lng >= -141 && lng <= -52.6;

  if (isInQuebec) {
    setOptedOut(true);
    return (
      <OptInBanner
        {...fields}
        setOptedOut={setOptedOut}
        toggleBanner={toggleBanner}
      />
    );
  } else if (isInCanada) {
    return (
      <OptOutBanner
        {...fields}
        setOptedOut={setOptedOut}
        toggleBanner={toggleBanner}
      />
    );
  } else {
    return null; // Outside Canada, no banner needed
  }
};

interface Props extends ICookieConsentBannerFields {
  setOptedOut: (arg0: boolean) => void;
  toggleBanner: (arg0: boolean) => void;
}

const ExitButton = ({ toggleBanner, backgroundColor }: any) => {
  return (
    <button
      className="absolute right-[8px] top-[3px] flex size-7 items-center justify-center"
      style={{ backgroundColor: backgroundColor }}
      onClick={() => {
        toggleBanner(false);
      }}
    >
      <Image src={closeIcon} alt="Close" width={12} height={12} />
    </button>
  );
};

export const OptInBanner = ({
  textColor,
  backgroundColor,
  primaryButtonColor,
  consentBannerBody,
  optInButtonText,
  setOptedOut,
  toggleBanner
}: Props) => {
  return (
    <div
      className="fixed bottom-0 z-[99999999] flex w-[100vw] flex-col items-center justify-center gap-2 rounded-tl-2xl rounded-tr-2xl px-15 py-6"
      style={{ backgroundColor: backgroundColor }}
    >
      <ExitButton
        toggleBanner={toggleBanner}
        backgroundColor={backgroundColor}
      />
      <div className="flex flex-col-reverse items-center justify-between self-stretch text-center tablet:flex-row tablet:text-start">
        <div
          className="flex max-w-[753px] flex-col items-center justify-center gap-4 tablet:items-start"
          style={{ color: textColor }}
        >
          {consentBannerBody && (
            <div className="mt-6 tablet:mt-0">
              <RichText
                data={consentBannerBody}
                colour={textColor}
                overrideOptions={RICH_TEXT_OVERRIDES}
              />
            </div>
          )}
        </div>
        <div className="flex items-center gap-2">
          {optInButtonText && (
            <button
              className="inline-flex h-[42px] min-w-48 flex-col items-center justify-center"
              onClick={() => {
                setOptedOut(false);
                toggleBanner(false);
              }}
            >
              <div
                className="inline-flex items-center justify-center gap-2 self-stretch rounded-lg px-4 py-[13px]"
                style={{ backgroundColor: primaryButtonColor }}
              >
                <div className="text-nowrap text-center text-sm font-medium leading-none text-[#1b2830]">
                  {optInButtonText}
                </div>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const OptOutBanner = ({
  textColor,
  backgroundColor,
  primaryButtonColor,
  consentBannerHeader,
  consentBannerBody,
  optOutButtonText,
  setOptedOut,
  toggleBanner
}: Props) => {
  return (
    <div
      className="fixed bottom-0 z-[99999999] flex w-[100vw] flex-col items-center justify-center gap-2 rounded-tl-2xl rounded-tr-2xl px-15 py-6"
      style={{ backgroundColor: backgroundColor }}
    >
      <ExitButton
        toggleBanner={toggleBanner}
        backgroundColor={backgroundColor}
      />

      <div className="flex flex-col items-center justify-between self-stretch text-center tablet:flex-row tablet:text-start">
        <div
          className="flex max-w-[753px] flex-col items-center justify-center gap-4 tablet:items-start"
          style={{ color: textColor }}
        >
          {consentBannerHeader && (
            <p className="text-sm font-medium leading-[18px]">
              {consentBannerHeader}
            </p>
          )}
          <div className="mb-4 text-xs font-normal tablet:mb-0">
            <RichText
              data={consentBannerBody}
              overrideOptions={RICH_TEXT_OVERRIDES}
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          {optOutButtonText && (
            <button
              className="inline-flex h-[42px] flex-col items-center justify-center"
              onClick={() => {
                setOptedOut(true);
                toggleBanner(false);
              }}
            >
              <div
                className="inline-flex items-center justify-center gap-2 self-stretch rounded-lg px-4 py-[13px]"
                style={{ backgroundColor: primaryButtonColor }}
              >
                <div className="text-nowrap text-center text-sm font-medium leading-none text-[#1b2830]">
                  {optOutButtonText}
                </div>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
