import React, { useState } from 'react';
import cx from 'classnames';

import { ILink } from '../../../../../../types/contentful';

import { NavItem } from '../../../shared';

import {
  CategoryContainer,
  NavLinkContainer,
  NavLink,
  CaretWrapper,
  Caret,
  SublinkContainer,
  Sublink,
  SublinkList,
  SublinkItem
} from '../MobileNav.styles';

import downArrow from '@/images/arrow-down.svg';

export function ModalNavCategory({
  name,
  title,
  url,
  links,
  tabletLinks,
  mobileLinks,
  isExpanded
}: NavItem) {
  const [expanded, setExpanded] = useState(isExpanded);
  const [rotated, setRotated] = useState(isExpanded as boolean);

  const isMeganav = tabletLinks && mobileLinks;
  const ifLinks = links || tabletLinks || mobileLinks;

  const toggleCategoryDisplay = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();

    setExpanded(!expanded);
    setRotated(!rotated);
  };

  return (
    <CategoryContainer>
      <NavLinkContainer>
        <NavLink
          aria-controls={`nav-expanded-content-${name}`}
          aria-expanded={expanded}
          href={ifLinks ? '#0' : (url as string)}
          onClick={toggleCategoryDisplay}
        >
          {name || title}
          {ifLinks && (
            <CaretWrapper>
              <Caret
                alt="Expand navigation list"
                src={downArrow}
                rotated={rotated}
              />
            </CaretWrapper>
          )}
        </NavLink>
      </NavLinkContainer>
      <SublinkContainer id={`nav-expanded-content-${name}`} expanded={expanded}>
        {isMeganav ? (
          <>
            <ul
              data-testid="meganav-links"
              className={cx(
                'm-0 w-full list-none py-4 tiny:hidden tablet:inline-block',
                {
                  'tablet:columns-1': tabletLinks
                    ? tabletLinks?.length < 5
                    : false,
                  'tablet:columns-2': tabletLinks
                    ? tabletLinks?.length >= 5 && tabletLinks?.length < 8
                    : false,
                  'tablet:columns-4': tabletLinks
                    ? tabletLinks?.length > 8
                    : false
                }
              )}
            >
              {tabletLinks &&
                tabletLinks.map(
                  (
                    { fields: { title, url, subcategoryLabel } }: any,
                    idx: number
                  ) => (
                    <li className="mb-3" key={title ?? idx}>
                      {subcategoryLabel && (
                        <p className="mb-3 text-base font-medium">
                          {tabletLinks[idx - 1]?.fields?.subcategoryLabel !==
                          subcategoryLabel
                            ? subcategoryLabel
                            : null}
                        </p>
                      )}
                      {url !== '#' ? (
                        <Sublink href={url}>{title}</Sublink>
                      ) : (
                        <div className="tablet:mb-36"></div>
                      )}
                    </li>
                  )
                )}
            </ul>
            <ul
              className={cx(
                'm-0 inline-block w-full list-none py-4 tiny:inline-block tiny:columns-2 tablet:hidden',
                {
                  'tiny:columns-1': mobileLinks
                    ? mobileLinks?.length < 5
                    : false
                }
              )}
            >
              {mobileLinks &&
                mobileLinks.map(
                  (
                    { fields: { title, url, subcategoryLabel } }: any,
                    idx: number
                  ) => {
                    const nextLinkHasDifferentCategory =
                      mobileLinks[idx + 1]?.fields?.subcategoryLabel !==
                      subcategoryLabel;
                    const previousLinkHasDifferentCategory =
                      mobileLinks[idx - 1]?.fields?.subcategoryLabel !==
                      subcategoryLabel;
                    return (
                      <li
                        className={
                          nextLinkHasDifferentCategory ? 'mb-6' : 'mb-3'
                        }
                        key={title ?? idx}
                        data-testid={`mobile-link-${title ?? idx}`}
                      >
                        {subcategoryLabel && (
                          <p className="mb-2 text-base font-medium">
                            {previousLinkHasDifferentCategory
                              ? subcategoryLabel
                              : null}
                          </p>
                        )}
                        {url !== '#' ? (
                          <Sublink href={url}>{title}</Sublink>
                        ) : (
                          <div className="tiny:mb-16"></div>
                        )}
                      </li>
                    );
                  }
                )}
            </ul>
          </>
        ) : (
          <SublinkList>
            {links &&
              links.map(({ fields }: ILink, idx: number) => (
                <SublinkItem key={fields?.title ?? idx} data-testid="nav-link">
                  <Sublink href={fields?.url}>{fields?.title}</Sublink>
                </SublinkItem>
              ))}
          </SublinkList>
        )}
      </SublinkContainer>
    </CategoryContainer>
  );
}
