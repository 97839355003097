export default function getUrlSearchParams(
  params: string[],
  buttonParams?: string,
  ctaParams?: string
) {
  try {
    if (!params) throw new Error('Missing params');
    const searchString = window.location.search;
    const searchParams = new URLSearchParams(searchString);
    let queryString = '';
    params.forEach((param) => {
      const value = searchParams.get(param);
      if (value) {
        if (param === 'plan' && (buttonParams || ctaParams)) {
          queryString += '';
        } else {
          queryString += `&${param}=${value}`;
        }
      }
    });
    return queryString;
  } catch (err) {
    return '';
  }
}
