import React from 'react';

import { getImageData } from '../../../utils/contentful';

import { ScrollReveal } from '../../ScrollReveal';
import { StyledImage } from '../styles';

import { NodeProps } from './shared';

export function AssetNode({ node }: NodeProps) {
  return node ? (
    <ScrollReveal>
      <StyledImage {...getImageData(node?.data?.target)} />
    </ScrollReveal>
  ) : null;
}
