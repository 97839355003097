import React from 'react';
import { Logo } from './styles';

export const GreenlightLogo = React.memo(
  ({ isMenuOpen, className }: { isMenuOpen: boolean; className?: string }) => (
    <Logo
      className={className}
      isMenuOpen={isMenuOpen}
      viewBox="0 0 225 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M108.564 0H107.421C101.199 0 97.0725 4.43501 97.0725 10.1372V32.4389C97.0725 33.5793 98.0248 34.4663 99.1676 34.4663H101.072C102.215 34.4663 103.104 33.5793 103.104 32.4389V10.644C103.104 7.91966 105.008 5.82887 107.738 5.82887H108.31C111.04 5.82887 112.944 7.91966 112.944 10.644V32.5023C112.944 33.6427 113.897 34.5297 115.039 34.5297H116.881C118.023 34.5297 118.976 33.6427 118.976 32.5023V10.1372C118.912 4.43501 114.722 0 108.564 0Z" />
      <path d="M82.2799 0H81.0101C74.9153 0 70.1537 4.11822 70.1537 10.3272V24.2025C70.1537 30.3481 74.9153 34.5297 81.0101 34.5297H82.3434C86.597 34.5297 90.4698 32.4389 92.2474 28.384C92.6918 27.2436 92.3109 26.1032 91.1047 25.4696L90.5333 25.1528C89.1365 24.3925 87.7398 24.8994 86.978 26.1665C86.0256 27.6871 84.5654 28.9543 82.3434 28.9543H81.2006C78.0897 28.9543 76.1851 26.8001 76.1851 24.0124V20.211H89.8984C91.6126 20.211 93.1997 18.8171 93.1997 17.1698V10.3272C93.0728 4.11822 88.6286 0 82.2799 0ZM87.2319 14.6355H76.0581V10.644C76.0581 7.8563 77.9627 5.6388 81.0736 5.6388H82.2164C85.1368 5.6388 87.1684 7.66623 87.1684 10.4539V14.6355H87.2319Z" />
      <path d="M55.2977 0H54.0279C47.9331 0 43.1716 4.11822 43.1716 10.3272V24.2025C43.1716 30.3481 47.9331 34.5297 54.0279 34.5297H55.3612C59.6149 34.5297 63.4876 32.4389 65.2653 28.384C65.7097 27.2436 65.3287 26.1032 64.1225 25.4696L63.5511 25.1528C62.1544 24.3925 60.7576 24.8994 59.9958 26.1665C59.0435 27.6871 57.5832 28.9543 55.3612 28.9543H54.2184C51.1075 28.9543 49.2029 26.8001 49.2029 24.0124V20.211H62.8527C64.5669 20.211 66.1541 18.8171 66.1541 17.1698V10.3272C66.0906 4.11822 61.6465 0 55.2977 0ZM60.3132 14.6355H49.1394V10.644C49.1394 7.8563 51.044 5.6388 54.1549 5.6388H55.2977C58.2181 5.6388 60.2497 7.66623 60.2497 10.4539V14.6355H60.3132Z" />
      <path d="M39.5528 0H37.9022C31.6169 0 27.0458 4.30829 27.0458 10.8974V32.5023C27.0458 33.6427 27.9981 34.5297 29.1409 34.5297H30.982C32.1883 34.5297 33.0771 33.6427 33.0771 32.5023V11.531C33.0771 8.42651 35.3626 5.95558 38.4735 5.95558H39.6798C40.8861 5.95558 41.7749 5.13194 41.7749 4.11822V1.83736C41.7114 0.823644 40.7591 0 39.5528 0Z" />
      <path d="M19.6177 14.6355H12.1261C11.0468 14.6355 10.285 15.4592 10.285 16.4729V18.2469C10.285 19.2606 11.0468 20.0842 12.1261 20.0842H17.0782V24.0758C17.0782 26.8635 14.9831 28.8909 12.1261 28.8909H10.9834C7.87246 28.8909 5.96783 26.6734 5.96783 23.8857V10.644C5.96783 7.8563 7.87246 5.70215 10.9834 5.70215H12.1261C14.4117 5.70215 15.8084 6.9693 16.7607 8.48987C17.5226 9.75702 18.9193 10.2639 20.316 9.50359L20.8874 9.1868C22.0937 8.55323 22.4746 7.4128 22.0302 6.27237C20.2525 2.09079 16.3798 0 12.1896 0H10.8564C4.76157 0 0 4.11822 0 10.3272V24.2025C0 30.3481 4.76157 34.5297 10.8564 34.5297H12.1261C18.4114 34.5297 22.919 30.4115 22.919 24.2025V17.6767C22.919 16.0294 21.3318 14.6355 19.6177 14.6355Z" />
      <path d="M198.399 0H196.558C195.478 0 194.462 0.950359 194.462 2.09079V14.6355H184.431V2.09079C184.431 0.950359 183.479 0 182.336 0H180.495C179.416 0 178.4 0.950359 178.4 2.09079V32.5023C178.4 33.6427 179.352 34.5297 180.495 34.5297H182.336C183.416 34.5297 184.431 33.6427 184.431 32.5023V20.211H194.462V32.4389C194.462 33.5793 195.415 34.4663 196.558 34.4663H198.399C199.478 34.4663 200.494 33.5793 200.494 32.4389V2.02743C200.494 0.950359 199.541 0 198.399 0Z" />
      <path d="M222.905 0H205.382C204.176 0 203.287 0.950359 203.287 2.09079V3.67472C203.287 4.81515 204.176 5.70215 205.382 5.70215H211.16V32.5023C211.16 33.6427 212.112 34.5297 213.255 34.5297H215.096C216.175 34.5297 217.191 33.6427 217.191 32.5023V5.70215H222.905C224.175 5.70215 225 4.81515 225 3.67472V2.09079C225 0.950359 224.175 0 222.905 0Z" />
      <path d="M135.99 28.5741H134.784C131.673 28.5741 129.388 26.1665 129.388 22.9987V2.02743C129.388 0.887002 128.499 0 127.293 0H125.451C124.309 0 123.356 0.887002 123.356 2.02743V23.6323C123.356 30.2848 127.991 34.5297 134.213 34.5297H135.863C137.07 34.5297 138.022 33.7061 138.022 32.6923V30.4115C138.085 29.3978 137.197 28.5741 135.99 28.5741Z" />
      <path d="M171.099 14.6355H163.607C162.528 14.6355 161.766 15.4592 161.766 16.4729V18.2469C161.766 19.2606 162.528 20.0842 163.607 20.0842H168.559V24.0758C168.559 26.8635 166.464 28.8909 163.607 28.8909H162.465C159.354 28.8909 157.449 26.6734 157.449 23.8857V10.644C157.449 7.85629 159.354 5.70215 162.465 5.70215H163.607C165.893 5.70215 167.29 6.96929 168.242 8.48987C169.004 9.75701 170.401 10.2639 171.797 9.50358L172.369 9.1868C173.575 8.55322 173.956 7.41279 173.512 6.27236C171.734 2.2175 167.861 0.126709 163.607 0.126709H162.274C156.179 0.126709 151.418 4.24493 151.418 10.4539V24.3292C151.418 30.4748 156.179 34.6564 162.274 34.6564H163.544C169.829 34.6564 174.337 30.5382 174.337 24.3292V17.6767C174.337 16.0294 172.75 14.6355 171.099 14.6355Z" />
      <path d="M145.133 0H143.228C142.022 0 141.006 0.823644 141.006 2.09079V4.75179C141.006 6.01894 142.022 6.77923 143.228 6.77923H145.133C146.339 6.77923 147.355 6.01894 147.355 4.75179V2.09079C147.355 0.823644 146.339 0 145.133 0Z" />
      <path d="M145.069 10.2007H143.228C142.149 10.2007 141.133 11.151 141.133 12.2915V32.5024C141.133 33.6429 142.085 34.5299 143.228 34.5299H145.069C146.148 34.5299 147.164 33.6429 147.164 32.5024V12.2915C147.164 11.151 146.148 10.2007 145.069 10.2007Z" />
    </Logo>
  )
);
