import { TokenProp } from './types';
import { Theme } from '../types';
import { useTokenProp } from './utils';
import { FontSizeTokens, FontSizeProp } from './font-size';

type LineHeightToken = keyof FontSizeTokens;
export type LineHeightProp = TokenProp<LineHeightToken>;

interface StyledProps {
  theme: Theme;
  lineHeight?: LineHeightProp;
  fontSize?: FontSizeProp;
}

export const useLineHeightProp = useTokenProp<LineHeightToken, StyledProps>(
  'line-height',
  props => props.lineHeight || props.fontSize,
  (props, token) =>
    token ? props.theme.lineHeight[token as LineHeightToken] : ''
);
