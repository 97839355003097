export function DynamicSchemaJson(
  headline: string,
  id: string,
  description: string,
  image: string,
  datePublished: string
) {
  return {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': id
    },
    headline,
    description,
    image,
    author: {
      '@type': 'Organization',
      name: 'Greenlight',
      url: 'https://greenlight.com/'
    },
    publisher: {
      '@type': 'Organization',
      name: '',
      logo: {
        '@type': 'ImageObject',
        url: ''
      }
    },
    datePublished
  };
}
