import React, { ChangeEvent, InputHTMLAttributes } from 'react';

import { formatToPhone } from '../../utils/formatPhoneNumber';

import { Input } from './styles';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  handleOnChange?: (val: any) => void;
  'aria-invalid'?: boolean;
  'aria-describedby'?: string;
}

const BaseInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      value,
      handleOnChange,
      autoComplete,
      ...props
    }: InputProps,
    ref
  ) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      let formatted;

      switch (type) {
        case 'tel':
          formatted = formatToPhone(e.target.value);
          break;
        case 'email':
        case 'text':
        default:
          formatted = e.target.value;
          break;
      }

      handleOnChange && handleOnChange(formatted || '');
    };

    return (
      <Input
        {...props}
        ref={ref}
        type={type}
        value={value}
        onChange={handleChange}
        autoComplete={autoComplete ?? 'off'}
        maxLength={type === 'tel' ? 14 : 320}
      />
    );
  }
);

export default BaseInput;
