import React from 'react';

import { IPageFields } from '../../../types/contentful';

import { usePageDataContext } from '../../context/PageData';

import { AffiliateHeader } from '../AffiliateHeader';
import { Header } from '../Header';
import { Navbar } from '../Navbar';
import { VariationContainer } from '../VariationContainer';

import { FixedHeader } from './styles';

export function Headers({ headerType, hideHeader, hideBanner }: IPageFields) {
  const { config: { header } = {} } = usePageDataContext();

  // If page query returns 'headerType' data, show either regular Header... or Affiliate Header.
  // If page query returns 'contentfulGlobalConfig' data, show either: regular Header... or Variation Container
  const { sys, fields }: any = headerType ?? header ?? {};

  const contentType = sys?.contentType?.sys?.id;
  const isAffiliate = contentType === 'affiliateHeader';

  return (
    <FixedHeader
      showHeader={!hideHeader || !hideBanner}
      isAffiliate={isAffiliate}
      data-testid="fixed-header"
    >
      {!hideHeader &&
        (() => {
          switch (contentType) {
            case 'affiliateHeader':
              return <AffiliateHeader {...fields} />;

            case 'header':
              return <Header {...fields} />;

            case 'header2':
              return <Navbar {...fields} />;

            case 'variationContainer':
              return <VariationContainer {...fields} sysId={sys?.id} />;

            default:
              return null;
          }
        })()}
    </FixedHeader>
  );
}
