import styled from '../../../theme/styled-with-theme';
import { pxToRem } from '../../../styles/utils';
import { mediaQueries } from '../../../styles/media-queries';

import Link from '../../Link';

export const AffiliateBtn = styled(Link, {
  shouldForwardProp: (prop) =>
    !['mobileWidth', 'desktopWidth'].includes(prop as string)
})<{
  height?: number | string;
  width?: number;
  mobileWidth?: number;
  desktopWidth?: number;
}>`
  ${({ height = '100%', width = 156, mobileWidth, desktopWidth }) => `
    height: ${typeof height === 'string' ? height : pxToRem(height)};
    width: ${typeof width === 'string' ? width : pxToRem(width)};
    
    ${mediaQueries[0]} {
      width: ${pxToRem(mobileWidth || width)};
      max-width: ${pxToRem(230)};
    }
  
    ${mediaQueries.min} {
       width: ${pxToRem(mobileWidth || width)};
    }
    
    ${mediaQueries.tablet} {
       width: ${pxToRem(desktopWidth || width)};
    }
  `}
`;
