import React, { useState } from 'react';
import Image from 'next/legacy/image';

import { IHeaderFields } from '../../../types/contentful';

import { useScrollPosition } from '../../hooks/useScrollPosition';
import { CFColorTokenProp } from '../../theme/tokens/contentful-color';

import { usePageDataContext } from '../../context/PageData';

import { PhoneNumberWrapper } from '../CtaWidget/LegacyPhoneNumberWidget/PhoneNumberWidget.styles';
import { Layout } from '../Layout';
import Link from '../Link';
import { DEFAULT_BUTTON_TEXT } from '../CtaWidget/LegacyPhoneNumberWidget';
import CtaWidget from '../CtaWidget';
import { ctaWidgetEnum, DEFAULT_LABEL } from '../CtaWidget/shared';
import { GreenlightLogo } from './GreenlightLogo';

import {
  MenuButton,
  Icon,
  Menu,
  StyledHeader,
  HeaderLink,
  HeaderNav,
  HeaderColumn,
  HeaderPhoneNumberWrapper,
  GetStartedWrapper,
  HeaderBlockContainer,
  HideOnMobileSecondary,
  ShowOnMobileSecondary,
  HeaderMain
} from './styles';

import glLogo from '@/images/gl-logo.svg';
import menuIconBlack from '@/images/menu-icon.svg';
import menuIconWhite from '@/images/menu-icon-white.svg';
import xImg from '@/images/x-icon.svg';

export function Header({
  links,
  ctaText,
  ctaColor,
  ctaHoverColor,
  ctaSecondaryColor,
  ctaSecondaryHoverColor
}: IHeaderFields) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSecondaryHeader, setIsSecondaryHeader] = useState(false);
  const [isStartClicked, setIsStartClicked] = useState(false);

  const { page: { isSimplified } = {} } = usePageDataContext();

  useScrollPosition(
    ({ currPos }) => {
      if (isStartClicked) return;
      if (!isSecondaryHeader && currPos.y > 0) setIsSecondaryHeader(true);
      else if (isSecondaryHeader && currPos.y <= 0) setIsSecondaryHeader(false);
    },
    [isSecondaryHeader, isStartClicked],
    undefined,
    true,
    100
  );

  const cfColor: CFColorTokenProp | undefined =
    (ctaColor as CFColorTokenProp) || undefined;
  const cfHoverColor: CFColorTokenProp | undefined =
    (ctaHoverColor as CFColorTokenProp) || undefined;
  const cfSecondaryColor: CFColorTokenProp | undefined =
    (ctaSecondaryColor as CFColorTokenProp) || undefined;
  const cfSecondaryHoverColor: CFColorTokenProp | undefined =
    (ctaSecondaryHoverColor as CFColorTokenProp) || undefined;

  const src = isSecondaryHeader ? menuIconWhite : menuIconBlack;

  return (
    <StyledHeader>
      <HeaderBlockContainer
        isMenuOpen={isMenuOpen}
        isSecondaryHeader={isSecondaryHeader}
        data-e2e={isSecondaryHeader ? 'secondary-header' : 'primary-header'}
        revealOnScroll={false}
        data-testid="header-block"
      >
        <Layout gutter={0}>
          <HeaderColumn
            size={{ 0: 12, desktop: 10 }}
            isSecondaryHeader={isSecondaryHeader}
          >
            <HeaderMain
              isMenuOpen={isMenuOpen}
              justifyContent="space-between"
              alignItems="center"
            >
              <Link href="/" aria-label="Greenlight Home">
                <ShowOnMobileSecondary
                  isSecondaryHeader={!isMenuOpen && isSecondaryHeader}
                >
                  <Image src={glLogo} alt="Greenlight Logo" />
                </ShowOnMobileSecondary>
                <HideOnMobileSecondary
                  isSecondaryHeader={!isMenuOpen && isSecondaryHeader}
                >
                  <GreenlightLogo isMenuOpen={isMenuOpen} />
                </HideOnMobileSecondary>
              </Link>
              <ShowOnMobileSecondary
                isSecondaryHeader={!isMenuOpen && isSecondaryHeader}
                margin={{ tablet: [0, 'l', 0, 'auto'], desktop: 0 }}
              >
                <GetStartedWrapper>
                  <CtaWidget
                    id="header-get-started-widget"
                    module="Navigation"
                    errorPosition="bottom"
                    width="100%"
                    sysId="legacyCta"
                    designType={ctaWidgetEnum.GET_STARTED_V1}
                    placeholder={DEFAULT_LABEL}
                    label={DEFAULT_LABEL}
                    buttonColor={
                      isSecondaryHeader || isMenuOpen ? 'light' : 'dark'
                    }
                    buttonHoverColor={
                      isSecondaryHeader || isMenuOpen
                        ? 'lightHover'
                        : 'darkHover'
                    }
                    cfButtonColor={
                      isSecondaryHeader || isMenuOpen
                        ? cfSecondaryColor
                        : cfColor
                    }
                    cfButtonHoverColor={
                      isSecondaryHeader || isMenuOpen
                        ? cfSecondaryHoverColor
                        : cfHoverColor
                    }
                    onStartClick={() => {
                      setIsStartClicked(true);
                      setIsSecondaryHeader(true);
                    }}
                  />
                </GetStartedWrapper>
              </ShowOnMobileSecondary>
              {isMenuOpen ? (
                <MenuButton
                  onClick={() => setIsMenuOpen(false)}
                  data-blockid="close-hamburger"
                  isSimplified={isSimplified!}
                >
                  <Icon src={xImg} alt="Close menu" />
                </MenuButton>
              ) : (
                <MenuButton
                  isSimplified={isSimplified!}
                  onClick={() => setIsMenuOpen(true)}
                  data-blockid="open-hamburger"
                >
                  <Icon src={src} alt="Open navigation menu" />
                </MenuButton>
              )}
            </HeaderMain>
            <Menu isOpen={isMenuOpen}>
              <HeaderNav
                data-blockid="header-navigation"
                isSimplified={isSimplified!}
              >
                {links &&
                  links.map(({ fields: { url, title } }, i) => (
                    <HeaderLink
                      href={url!}
                      key={i}
                      isSecondaryHeader={isSecondaryHeader}
                    >
                      {title}
                    </HeaderLink>
                  ))}
              </HeaderNav>
              <div data-e2e="header-get-started">
                <GetStartedWrapper>
                  <CtaWidget
                    id="header-menu-get-started-widget"
                    module="Navigation"
                    width="100%"
                    sysId="legacyCta"
                    designType={ctaWidgetEnum.GET_STARTED_V1}
                    placeholder={DEFAULT_LABEL}
                    label={DEFAULT_LABEL}
                    buttonColor={
                      isSecondaryHeader || isMenuOpen ? 'light' : 'dark'
                    }
                    buttonHoverColor={
                      isSecondaryHeader || isMenuOpen
                        ? 'lightHover'
                        : 'darkHover'
                    }
                    cfButtonColor={
                      isSecondaryHeader || isMenuOpen
                        ? cfSecondaryColor
                        : cfColor
                    }
                    cfButtonHoverColor={
                      isSecondaryHeader || isMenuOpen
                        ? cfSecondaryHoverColor
                        : cfHoverColor
                    }
                    errorPosition={isMenuOpen ? 'top' : 'bottom'}
                  />
                </GetStartedWrapper>
                <HeaderPhoneNumberWrapper>
                  <PhoneNumberWrapper>
                    <CtaWidget
                      id="header-phone-number-widget"
                      sysId="legacyCta"
                      module="Navigation"
                      errorPosition="bottom"
                      width="100%"
                      designType={ctaWidgetEnum.DESKTOP_PHONE_NUMBER_V1}
                      placeholder={DEFAULT_LABEL}
                      label={DEFAULT_LABEL}
                      buttonColor={isSecondaryHeader ? 'light' : 'dark'}
                      buttonHoverColor={
                        isSecondaryHeader ? 'lightHover' : 'darkHover'
                      }
                      cfButtonColor={
                        isSecondaryHeader ? cfSecondaryColor : cfColor
                      }
                      cfButtonHoverColor={
                        isSecondaryHeader ? cfSecondaryHoverColor : cfHoverColor
                      }
                      buttonContent={ctaText ?? DEFAULT_BUTTON_TEXT}
                    />
                  </PhoneNumberWrapper>
                </HeaderPhoneNumberWrapper>
              </div>
            </Menu>
          </HeaderColumn>
        </Layout>
      </HeaderBlockContainer>
    </StyledHeader>
  );
}
