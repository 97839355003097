import React, { useEffect, useState } from 'react';

import { useUserContext } from '../../../context/User';
import { useEscapeEvent } from '../../../hooks/useEscapeEvent';

import { ProfileDropdown } from './ProfileDropdown';
import { ProfileWidget } from './ProfileWidget';

export function ProfileDropdownMenu() {
  const { educator } = useUserContext();

  const [isDropdownHidden, setDropdownHidden] = useState(true);
  const [displayValue, setDisplayValue] = useState<'none' | 'block'>('none');

  const onProfileWidgetClick = () => setDropdownHidden(!isDropdownHidden);

  useEscapeEvent(() => setDropdownHidden(true), !isDropdownHidden);
  useEffect(() => setDisplayValue('block'), []);

  useEffect(() => {
    if (isDropdownHidden) {
      return;
    }

    const clickHandler = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      let parent = target?.parentElement;

      while (parent?.getAttribute) {
        if (parent.getAttribute('data-blockid') === 'profile-dropdown-menu') {
          return;
        }

        parent = parent.parentElement;
      }

      setDropdownHidden(true);
    };

    window.addEventListener('click', clickHandler);
    return () => window.removeEventListener('click', clickHandler);
  }, [isDropdownHidden]);

  return (
    <div
      className="relative"
      data-testid="profile-dropdown-menu"
      data-blockid="profile-dropdown-menu"
      style={{ display: displayValue }}
    >
      <ProfileWidget
        firstName={educator?.givenName || ''}
        thumbnail={educator?.image}
        state={educator?.state}
        dropdownHidden={isDropdownHidden}
        onClickHandler={onProfileWidgetClick}
      />
      <ProfileDropdown {...educator} hidden={isDropdownHidden} />
    </div>
  );
}
