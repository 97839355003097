import React, { useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import { ImageProps } from 'next/legacy/image';
import { BLOCKS, Block, Inline } from '@contentful/rich-text-types';
import { RenderNode } from '@contentful/rich-text-react-renderer';
import { useExitIntent } from 'use-exit-intent';
import { IExitIntentFields } from '../../../types/contentful';
import { PopUp } from '../PopUp';
import { Flexbox } from '../Flexbox';
import RichText from '../RichText';
import { getImageData } from '../../utils/contentful';
import GlImage from '../Image';
import CtaWidget from '../CtaWidget';
import { DEFAULT_LABEL, DEFAULT_PLACEHOLDER } from '../CtaWidget/shared';
import glLogo from '@/images/gl-logo-full.svg';
import { Text } from '../Text';
import { Box } from '../Box';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { trackWithFingerprint } from '../../utils/trackWithFingerprint';

const bodyOverrideOptions: RenderNode = {
  [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => {
    return (
      <Text as="p" fontSize={{ 0: 'xl', mobile: 'ml' }}>
        {children}
      </Text>
    );
  }
};
const disclaimerOverrideOptions: RenderNode = {
  [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => {
    return <p className="text-xs">{children}</p>;
  }
};
const sizes = {
  '50%': '57.5vw',
  '75%': '75vw',
  '90%': '90vw'
};

export function ExitIntent({
  headline,
  size,
  body,
  image,
  imageOrientation: placeImageOnLeft,
  ctaWidget,
  enableOnMobile,
  delayTypeOnMobile: useIdleDelayOnMobile,
  delayToShowThePopUpOnMobile,
  enableOnDesktop,
  delayTypeOnDesktop: useIdleDelayOnDesktop,
  delayToShowThePopUpOnDesktop,
  disclaimer
}: IExitIntentFields) {
  const isDesktop = useMediaQuery('desktop');
  const [imageProps, setImageProps] = useState<ImageProps>({ src: '' });
  const [showPopup, setShowPopup] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [popupTimerId, setPopupTimerId] = useState<any>();
  const flatBorder = placeImageOnLeft
    ? 'desktop:rounded-tr-none desktop:rounded-br-none'
    : 'desktop:rounded-tl-none desktop:rounded-bl-none';

  const { registerHandler, unsubscribe, isTriggered, isUnsubscribed } =
    useExitIntent({
      cookie: {
        key: 'gl-exit-intent-popup',
        daysToExpire: 30
      },
      desktop: {
        triggerOnIdle: enableOnDesktop && useIdleDelayOnDesktop,
        triggerOnMouseLeave: enableOnDesktop,
        delayInSecondsToTrigger: delayToShowThePopUpOnDesktop
      },
      mobile: {
        triggerOnIdle: enableOnMobile && useIdleDelayOnMobile,
        delayInSecondsToTrigger: delayToShowThePopUpOnMobile
      }
    });

  const onClosePopup = () => {
    setShowPopup(false);
    unsubscribe();
    trackWithFingerprint('Exit Intent Pop-Up Closed', {
      page_url: window.location.href,
      module: 'Exit Intent'
    });
  };

  const onShowPopup = useCallback(() => {
    if (!isTriggered) {
      setShowPopup(true);
      clearTimeout(popupTimerId);
    }
  }, [isTriggered, popupTimerId]);

  registerHandler({
    id: 'open-popup',
    handler: onShowPopup
  });

  useEffect(() => {
    let layout: ImageProps['layout'] = 'fill';
    let width = undefined;
    let height = undefined;

    if (!isDesktop) {
      layout = 'responsive';
      width = 300;
      height = 150;
    }

    setImageProps({
      ...getImageData(image),
      layout,
      width,
      height
    });
  }, [isDesktop, image]);

  useEffect(() => {
    if (showPopup) {
      trackWithFingerprint('Exit Intent Pop-Up Viewed', {
        page_url: window.location.href,
        module: 'Exit Intent'
      });
    }
  }, [showPopup]);

  // This is needed because on the first render, isDesktop is always false, even
  // on desktop, which leads to the popup being shown incorrectly.
  useEffect(() => setFirstRender(false), []);

  // useIdleDelayOn(Desktop|Mobile): when true, the popup will only be shown if
  // the user is idle for the specified amount of time. When false, the popup
  // will be shown after the specified amount of time past the page load.

  useEffect(() => {
    if (
      !firstRender &&
      isDesktop &&
      enableOnDesktop &&
      !useIdleDelayOnDesktop &&
      !isUnsubscribed &&
      delayToShowThePopUpOnDesktop !== undefined
    ) {
      const timerId = setTimeout(
        onShowPopup,
        delayToShowThePopUpOnDesktop * 1000
      );
      setPopupTimerId(timerId);
    }
  }, [
    firstRender,
    isDesktop,
    enableOnDesktop,
    useIdleDelayOnDesktop,
    isUnsubscribed,
    delayToShowThePopUpOnDesktop
  ]);

  useEffect(() => {
    if (
      !firstRender &&
      !isDesktop &&
      enableOnMobile &&
      !useIdleDelayOnMobile &&
      !isUnsubscribed &&
      delayToShowThePopUpOnMobile !== undefined
    ) {
      const timerId = setTimeout(
        onShowPopup,
        delayToShowThePopUpOnMobile * 1000
      );
      setPopupTimerId(timerId);
    }
  }, [
    firstRender,
    isDesktop,
    enableOnMobile,
    useIdleDelayOnMobile,
    isUnsubscribed,
    delayToShowThePopUpOnMobile
  ]);

  return (
    <PopUp
      open={showPopup}
      width={isDesktop ? sizes[size] : '90vw'}
      onClose={onClosePopup}
    >
      <Flexbox
        flexDirection={{
          0: 'column-reverse',
          desktop: placeImageOnLeft ? 'row-reverse' : 'row'
        }}
        className={`h-full rounded-popup bg-white`}
        data-testid="exit-intent"
      >
        <Box className="flex-col px-6 py-8 text-center desktop:w-[54.5%] desktop:px-20 desktop:py-40 desktop:text-left">
          <Image
            src={glLogo}
            alt="Greenlight logo"
            height={30}
            width={194}
            className="mx-auto desktop:mx-0"
          />
          <Text
            as="h2"
            marginTop="ml"
            marginBottom="sm"
            fontWeight="700"
            className="text-[30px] !leading-none desktop:text-[38px]"
          >
            {headline}
          </Text>
          <RichText data={body} overrideOptions={bodyOverrideOptions} />
          {ctaWidget && (
            <Box
              marginTop="ml"
              className="mx-auto inline-block"
              data-testid="exit-intent-cta"
            >
              <CtaWidget
                id="exit-intent-cta"
                module="Exit Intent"
                errorPosition="bottom"
                label={DEFAULT_LABEL}
                placeholder={DEFAULT_PLACEHOLDER}
                {...ctaWidget?.fields}
                sysId={ctaWidget?.sys?.contentType?.sys?.id}
              />
            </Box>
          )}
          {disclaimer && (
            <div className="mt-6">
              <RichText
                data={disclaimer}
                overrideOptions={disclaimerOverrideOptions}
              />
            </div>
          )}
        </Box>
        <Box
          className={`relative overflow-hidden rounded-t-popup desktop:w-[45.5%] desktop:rounded-popup ${flatBorder}`}
        >
          <GlImage {...imageProps} objectFit="cover" />
        </Box>
      </Flexbox>
    </PopUp>
  );
}
