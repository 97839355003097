import React from 'react';

import { Flexbox, FlexboxProps } from '../../Flexbox';

import { FooterLegalNavItemList, SecondaryFooterLink } from './styles';

interface FooterLegalNavProps extends FlexboxProps {
  isAffiliateFooter?: boolean;
}

export const FooterLegalNav = ({
  isAffiliateFooter,
  ...props
}: FooterLegalNavProps) => (
  <Flexbox as="nav" flexDirection="column" {...props}>
    <FooterLegalNavItemList>
      <li>
        <SecondaryFooterLink
          href={isAffiliateFooter ? '/affiliates/terms' : '/terms'}
        >
          Policy Center
        </SecondaryFooterLink>
      </li>
      <li>
        <SecondaryFooterLink
          href={
            isAffiliateFooter
              ? '/affiliates/terms-of-service'
              : '/terms-of-service'
          }
        >
          Terms of Service
        </SecondaryFooterLink>
      </li>
      <li>
        <SecondaryFooterLink
          href={isAffiliateFooter ? '/affiliates/cardholder' : '/cardholder'}
        >
          Cardholder Agreement
        </SecondaryFooterLink>
      </li>
      <li>
        <SecondaryFooterLink
          href={isAffiliateFooter ? '/affiliates/privacy' : '/privacy'}
        >
          Privacy Policy
        </SecondaryFooterLink>
      </li>
      <li>
        <SecondaryFooterLink
          href={
            isAffiliateFooter
              ? '/affiliates/privacy#childrens-privacy'
              : '/privacy#childrens-privacy'
          }
        >
          Children&apos;s Privacy Policy
        </SecondaryFooterLink>
      </li>
      <li>
        <SecondaryFooterLink
          href={
            isAffiliateFooter
              ? '/affiliates/privacy#your-california-privacy-rights'
              : '/privacy#your-california-privacy-rights'
          }
        >
          Your California Privacy Rights
        </SecondaryFooterLink>
      </li>
      <li>
        <SecondaryFooterLink href="/data-request">
          Do Not Sell or Share My Personal Information
        </SecondaryFooterLink>
      </li>
      <li>
        <SecondaryFooterLink href="https://reports.adviserinfo.sec.gov/crs/crs_308546.pdf">
          Customer Relationship Summary
        </SecondaryFooterLink>
      </li>
    </FooterLegalNavItemList>
  </Flexbox>
);
