import React, { useRef } from 'react';
import { NavItem } from '../../../shared';

import Link from '../../../../Link';
import { Dropdown } from './Dropdown';

import {
  NavLinkWrapper,
  NavLink,
  PositionDropdown,
  NavlinkArrow,
  PositionCarets,
  DropdownCaret
} from '../DesktopNavbar.styles';

import cx from 'classnames';
import downArrow from '@/images/arrow-down.svg';

interface NavigationLinkProps extends NavItem {
  selected: boolean;
  setSelected: (val: string | undefined) => void;
}

export function NavigationLink({
  name,
  title,
  url,
  links,
  desktopLinks,
  selected,
  setSelected
}: NavigationLinkProps) {
  const navItemRef = useRef<HTMLAnchorElement>(null);
  const ifLinks = links || desktopLinks;

  const toggleDropdown = () => {
    if (selected) {
      setSelected(undefined);
    } else {
      setSelected(name || title);
    }
  };

  return (
    <NavLinkWrapper>
      <NavLink
        aria-controls={`nav-expanded-content-${name}`}
        aria-expanded={selected}
        onClick={toggleDropdown}
        onMouseEnter={toggleDropdown}
        onMouseLeave={toggleDropdown}
      >
        <Link
          href={ifLinks ? '#0' : (url as string)}
          ref={navItemRef}
          onClick={(e) => {
            if (ifLinks) {
              e.preventDefault();
            }
          }}
        >
          {name || title}
        </Link>
        {desktopLinks ? (
          <>
            <div
              className="relative z-50 flex justify-center"
              data-testid="desktop-mega-navbar"
            >
              <NavlinkArrow
                src={downArrow}
                alt="Open navigation dropdown list"
              />
              <div
                className={cx(
                  'z-1 absolute top-5 block h-0 w-0 rotate-45 border-[10px] border-r-[5px] border-solid border-white',
                  { hidden: !selected }
                )}
              />
            </div>
            <div
              className="absolute -left-6 top-4 pt-5 "
              id={`nav-expanded-content-${name}`}
            >
              <Dropdown
                displayDropdown={selected}
                desktopLinks={desktopLinks}
              />
            </div>
          </>
        ) : (
          links && (
            <>
              <PositionCarets>
                <NavlinkArrow
                  src={downArrow}
                  alt="Open navigation dropdown list"
                />
                <DropdownCaret displayDropdown={selected} />
              </PositionCarets>
              <PositionDropdown id={`nav-expanded-content-${name}`}>
                <Dropdown displayDropdown={selected} links={links} />
              </PositionDropdown>
            </>
          )
        )}
      </NavLink>
    </NavLinkWrapper>
  );
}
