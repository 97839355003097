import { TokenProp, ResponsiveTokenSet } from './types';
import { mediaQueries, breakpoints } from '../../styles/media-queries';

export const useTokenProp =
  <ThemeToken, StyledProps>(
    cssProperty: string,
    getPropValue: (props: StyledProps) => TokenProp<ThemeToken> | undefined,
    getStyles: (
      props: StyledProps,
      token: TokenProp<ThemeToken> | ThemeToken | undefined
    ) => string | undefined
  ) =>
  (props: StyledProps) => {
    const propValue = getPropValue(props);
    if (!propValue) return;

    if (typeof propValue === 'object' && !Array.isArray(propValue)) {
      const propVal = propValue as ResponsiveTokenSet<ThemeToken>;
      const baselineStyles = propVal[0]
        ? `${cssProperty}: ${getStyles(props, propVal[0])};`
        : '';
      const minStyles =
        propVal.min !== undefined
          ? `${mediaQueries.min} {
      ${cssProperty}: ${getStyles(props, propVal.min)};
    }`
          : '';
      const mobileStyles =
        propVal.mobile !== undefined
          ? `${mediaQueries.mobile} {
      ${cssProperty}: ${getStyles(props, propVal.mobile)};
    }`
          : '';
      const tabletStyles =
        propVal.tablet !== undefined
          ? `${mediaQueries.tablet} {
      ${cssProperty}: ${getStyles(props, propVal.tablet)};
    }`
          : '';
      const desktopStyles =
        propVal.desktop !== undefined
          ? `${mediaQueries.desktop} {
      ${cssProperty}: ${getStyles(props, propVal.desktop)};
    }`
          : '';
      const maxStyles =
        propVal.max !== undefined
          ? `${mediaQueries.max} {
      ${cssProperty}: ${getStyles(props, propVal.max)};
    }`
          : '';

      return `
      ${baselineStyles}
      ${minStyles}
      ${mobileStyles}
      ${tabletStyles}
      ${desktopStyles}
      ${maxStyles}
    `;
    }

    return `${cssProperty}: ${getStyles(props, propValue as ThemeToken)};`;
  };

export const mapTokenProp = <FromToken, ToToken>(
  fromToken: TokenProp<FromToken> | string | undefined,
  mapToken: (fromToken: FromToken | undefined) => ToToken | undefined
): TokenProp<ToToken> | undefined => {
  if (!fromToken) return;

  if (typeof fromToken === 'object' && !Array.isArray(fromToken)) {
    const toToken: TokenProp<ToToken> = {};
    const propVal = fromToken as ResponsiveTokenSet<any>;
    let breakpoint: keyof typeof breakpoints;
    for (breakpoint in propVal) {
      toToken[breakpoint] = mapToken(propVal[breakpoint]);
    }
    return toToken;
  }

  return mapToken(fromToken as FromToken);
};
