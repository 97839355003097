export function DynamicBreadcrumbJson(headline: string, id: string) {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Blog',
        item: 'https://greenlight.com/blog'
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: headline,
        item: id
      }
    ]
  };
}
