import { TokenProp } from './types';
import { Theme } from '../types';
import { useTokenProp } from './utils';

export interface ButtonColorTokens {
  dark: string;
  light: string;
  darkHover: string;
  lightHover: string;
}

export type ButtonColorToken = keyof ButtonColorTokens;
export type ButtonColorTokenProp = TokenProp<ButtonColorToken>;
export type ButtonHoverColorTokenProp = TokenProp<ButtonColorToken>;

interface StyledProps {
  theme: Theme;
  buttonColor?: ButtonColorTokenProp;
  buttonHoverColor?: ButtonColorTokenProp;
}

const setThemeColors = (_props: any, token: any) => {
  const backgroundColor = token
    ? _props.theme.buttonColor[token as ButtonColorToken]
    : '';
  const darkColors = [_props.theme.buttonColor.dark];
  if (darkColors.includes(backgroundColor)) {
    return _props.theme.color.white;
  } else {
    return _props.theme.color.defaultText;
  }
};

export const useButtonColorProp = (props: StyledProps) => `
  ${useTokenProp<ButtonColorToken | string, StyledProps>(
    'background-color',
    (btnProps) => btnProps.buttonColor || 'dark',
    (_props, token) =>
      token
        ? _props.theme.buttonColor[token as ButtonColorToken]
        : _props.theme.buttonColor.darkHover
  )(props)}
  ${useTokenProp<ButtonColorToken, StyledProps>(
    'color',
    (btnProps) => btnProps.buttonColor || 'dark',
    setThemeColors
  )(props)}
`;

export const useButtonHoverColorProp = (props: StyledProps) => `
${useTokenProp<ButtonColorToken | string, StyledProps>(
  'background-color',
  (btnProps) => btnProps.buttonHoverColor || 'darkHover',
  (_props, token) =>
    token
      ? _props.theme.buttonColor[token as ButtonColorToken]
      : _props.theme.buttonColor.light
)(props)}
  ${
    useTokenProp<ButtonColorToken, StyledProps>(
      'color',
      (btnProps) => btnProps.buttonHoverColor || 'darkHover',
      setThemeColors
    )(props) || ''
  }
`;
