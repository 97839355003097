import * as React from 'react';

type ArrowNorthEastProps = {
  fill?: string;
  width?: number;
  height?: number;
};

export function ArrowNorthEast({
  fill = '#ffffff',
  width = 24,
  height = 24,
  ...props
}: ArrowNorthEastProps) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.94 18.94a1.5 1.5 0 0 0 2.12 2.12l-2.12-2.12ZM19.641 5.857a1.5 1.5 0 0 0-1.5-1.5h-13.5a1.5 1.5 0 0 0 0 3h12v12a1.5 1.5 0 0 0 3 0v-13.5ZM5.061 21.06 19.203 6.919 17.08 4.797 2.94 18.94l2.122 2.122Z"
        fill={fill}
      />
    </svg>
  );
}
