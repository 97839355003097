import { useCallback, useEffect } from 'react';

export function useEscapeEvent(
  callback: () => void,
  attachEventListener?: boolean
) {
  const handleEscape = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        callback();
      }
    },
    [callback]
  );
  useEffect(() => {
    if (attachEventListener === false) {
      return;
    }

    document.addEventListener('keydown', handleEscape, false);
    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [callback, attachEventListener]);
}
