import { Property } from 'csstype';
import isPropValid from '@emotion/is-prop-valid';

import styled from '../../theme/styled-with-theme';
import { TextProps } from '.';
import { useFontFamilyProp } from '../../theme/tokens/font-family';
import { useFontSizeProp } from '../../theme/tokens/font-size';
import { useFontWeightProp } from '../../theme/tokens/font-weight';
import { useColourProp } from '../../theme/tokens/color';
import { useLineHeightProp } from '../../theme/tokens/line-height';
import { useSpacingProps } from '../../theme/tokens/spacing';
import { useTokenProp } from '../../theme/tokens/utils';

const HOLD_PROPS = ['fontSize', 'fontWeight', 'fontFamily'];

const useTextAlignProp = useTokenProp<Property.TextAlign, TextProps>(
  'text-align',
  (props) => props.textAlign,
  (props, token) => token as Property.TextAlign
);

const useFontStyleProp = useTokenProp<Property.FontStyle, TextProps>(
  'font-style',
  (props) => props.fontStyle,
  (props, token) => token as Property.FontStyle
);

export const StyledText = styled('p', {
  shouldForwardProp: (prop) =>
    isPropValid(prop) && !HOLD_PROPS.includes(prop as string),
})<TextProps>`
  ${useFontFamilyProp}
  ${useFontSizeProp}
  ${useFontWeightProp}
  ${useLineHeightProp}
  ${useColourProp}
  ${useSpacingProps}
  ${useTextAlignProp}
  ${useFontStyleProp}
`;
