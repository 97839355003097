import { TokenProp } from './types';
import { Theme } from '../types';
import { useTokenProp } from './utils';

export interface BorderRadiusTokens {
  unset: string;
  '0': string;
  block: string;
  block_desktop: string;
  sliderBlock: string;
  sliderBlock_desktop: string;
  innerBlock: string;
  pressPost: string;
  pressPostHeader: string;
  quickStartGuideBox: string;
  input: string;
  input_desktop: string;
  input_mobile_nav: string;
  input_button: string;
  button: string;
  button_desktop: string;
  blogHero_0: string;
  blogHero_desktop: string;
  tag_0: string;
  tag_tablet: string;
  tag_desktop: string;
  blockquote_0: string;
  blockquote_tablet: string;
  greenlight_border_radius: string;
  popup_border_radius: string;
  planHeaderMobile: string;
  planHeaderDesktop: string;
  eyebrow: string;
  milestoneShape: string;
  desktop_navbar_dropdown: string;
  cta_button_inside: string;
  cta_button_inside_dropshadow: string;
  standard_border_radius: string;
}

export type BorderRadiusToken = keyof BorderRadiusTokens;
export type BorderRadiusShorthandToken =
  | BorderRadiusToken
  | [BorderRadiusToken, BorderRadiusToken]
  | [
      BorderRadiusToken,
      BorderRadiusToken,
      BorderRadiusToken,
      BorderRadiusToken
    ];
export type BorderRadiusProp = TokenProp<BorderRadiusShorthandToken>;

interface StyledProps {
  theme: Theme;
  borderRadius?: BorderRadiusProp;
}

export const getBorderRadiusValue = (
  theme: Theme,
  token?: BorderRadiusShorthandToken
) => {
  if (!token) return;
  const getBorderRadius = (borderRadius: BorderRadiusToken) =>
    theme.borderRadius[borderRadius];
  if (Array.isArray(token)) {
    return token.map(getBorderRadius).join(' ');
  }
  return getBorderRadius(token);
};

export const useBorderRadiusProp = useTokenProp<
  BorderRadiusShorthandToken,
  StyledProps
>(
  'border-radius',
  (props) => props.borderRadius,
  (props, token) =>
    getBorderRadiusValue(props.theme, token as BorderRadiusToken)
);
