import React from 'react';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { IPageFields, IVariation } from '../../../types/contentful';

import { usePageDataContext } from '../../context/PageData';

import { AffiliateFooter } from '../AffiliateFooter';
import { Footer } from '../Footer/1.0';
import { Footer2 } from '../Footer/2.0';
import { VariationContainer } from '../VariationContainer';

export function Footers({
  footerType,
  appLinkText,
  footerHeadline,
  footerPhoneDisclaimer,
  hideFooterAppLinks,
  hideFooterCta,
  pageFootnotes
}: IPageFields) {
  const { config: { footer, footerDisclaimer } = {} } = usePageDataContext();

  // If page query returns 'footerType' data, show either regular Footer... or Affiliate Footer.
  const { sys, fields }: any = footerType ?? footer ?? {};

  const pageLevelFooterProps = omitBy(
    {
      appLinkText,
      footerHeadline,
      footerPhoneDisclaimer,
      hideFooterAppLinks,
      hideFooterCta,
      pageFootnotes: pageFootnotes ?? (fields as any)?.pageFootnotes,
      footerDisclaimer
    },
    isNil
  );

  switch (sys?.contentType?.sys?.id) {
    case 'affiliateFooter':
      return <AffiliateFooter {...fields} {...pageLevelFooterProps} />;

    case 'footer':
      return <Footer {...fields} {...pageLevelFooterProps} />;

    case 'variationContainer':
      // Do not lazy load
      fields?.variations?.forEach((variation: IVariation | undefined) => {
        if (variation && variation.fields?.pageBlock) {
          variation.fields.pageBlock.fields = {
            ...(variation.fields.pageBlock.fields || {}),
            ...pageLevelFooterProps
          };
        }
      });
      return <VariationContainer {...fields} sysId={sys?.id} />;

    case 'footer20':
      return <Footer2 {...fields} {...pageLevelFooterProps} />;

    default:
      return null;
  }
}
