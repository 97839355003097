import { UserData } from '../lib/referrals/getUserDataById';

export enum keyGeneratedTextValues {
  advocateName = 'advocate_name',
  kidsNames = 'kids_names',
  rewardValue = 'reward_value',
  teenOnly = 'teen_only'
}

export const addAdditionalDataToDynamicText = (
  text: string | undefined,
  keyGeneratedText: string | undefined,
  userData: UserData | undefined | null,
  baseText: string,
  query?: any
): string | undefined => {
  if (!text) {
    return text;
  }

  switch (keyGeneratedText) {
    case keyGeneratedTextValues.advocateName:
      if (userData && userData.name) {
        return text.replace('$[]', userData.name);
      }
      return baseText;
    case keyGeneratedTextValues.kidsNames:
      if (userData && userData.role === 'parent') {
        return text.replace('$[]', userData.firstKidsNames);
      }
      return baseText;
    case keyGeneratedTextValues.rewardValue:
      const referralRewardValue = query?.referral_reward_value;
      return referralRewardValue
        ? text.replace('$[]', referralRewardValue)
        : baseText;
    case keyGeneratedTextValues.teenOnly:
      if (userData && userData.isTeen) {
        return text;
      }
      return baseText;
    default:
      return text;
  }
};
