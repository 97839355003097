import { TokenProp } from './types';
import { Theme } from '../types';
import { useTokenProp } from './utils';

interface SpacingScaleTokens {
  '2xs': string;
  xs: string;
  s: string;
  sm: string;
  m: string;
  ml: string;
  l: string;
  xl: string;
  '2xl': string;
  '3xl': string;
  '4xl': string;
  '5xl': string;
}

interface SpacingAliasTokens {
  auto: string;
  0: string;
  collapsibleVert: string;
  contentMargin: string;
  contentMargin_tablet: string;
  blockMargin: string;
  blockMargin_tablet: string;
  blockMarginY: string;
  blockMarginY_tablet: string;
  blockMarginY_desktop: string;
  blockPadding: string;
  blockPadding_tablet: string;
  plansMargin: string;
  topMargin: string;
  topMargin_tablet: string;
  masonryGutter: string;
  masonryGutter_desktop: string;
  hiddenHeader: string;
  quickStartGuideTop: string;
  splitForm: string;
}

export interface SpacingTokens extends SpacingScaleTokens, SpacingAliasTokens {}

export type SpacingToken = keyof SpacingTokens;
export type SpacingShorthandToken =
  | SpacingToken
  | [SpacingToken, SpacingToken]
  | [SpacingToken, SpacingToken, SpacingToken, SpacingToken];

export interface SpacingProps {
  margin?: TokenProp<SpacingShorthandToken>;
  marginTop?: TokenProp<SpacingToken>;
  marginRight?: TokenProp<SpacingToken>;
  marginBottom?: TokenProp<SpacingToken>;
  marginLeft?: TokenProp<SpacingToken>;
  padding?: TokenProp<SpacingShorthandToken>;
  paddingTop?: TokenProp<SpacingToken>;
  paddingRight?: TokenProp<SpacingToken>;
  paddingBottom?: TokenProp<SpacingToken>;
  paddingLeft?: TokenProp<SpacingToken>;
}

interface StyledProps extends SpacingProps {
  theme: Theme;
}

export const getSpacingValue = (
  theme: Theme,
  token?: SpacingShorthandToken
) => {
  const getSpacing = (spacing: SpacingToken) => theme.spacing[spacing];
  if (token === undefined) return '';
  if (Array.isArray(token)) {
    return token.map(getSpacing).join(' ');
  }
  return getSpacing(token);
};

export const useSpacingProps = (props: StyledProps) => `
  ${
    useTokenProp<SpacingShorthandToken, StyledProps>(
      'margin',
      (spacingProps) => spacingProps.margin,
      (_props, token) => getSpacingValue(_props.theme, token as SpacingToken)
    )(props) || ''
  }
  ${
    useTokenProp<SpacingToken, StyledProps>(
      'margin-top',
      (spacingProps) => spacingProps.marginTop,
      (_props, token) => getSpacingValue(_props.theme, token as SpacingToken)
    )(props) || ''
  }
  ${
    useTokenProp<SpacingToken, StyledProps>(
      'margin-right',
      (spacingProps) => spacingProps.marginRight,
      (_props, token) => getSpacingValue(_props.theme, token as SpacingToken)
    )(props) || ''
  }
  ${
    useTokenProp<SpacingToken, StyledProps>(
      'margin-bottom',
      (spacingProps) => spacingProps.marginBottom,
      (_props, token) => getSpacingValue(_props.theme, token as SpacingToken)
    )(props) || ''
  }
  ${
    useTokenProp<SpacingToken, StyledProps>(
      'margin-left',
      (spacingProps) => spacingProps.marginLeft,
      (_props, token) => getSpacingValue(_props.theme, token as SpacingToken)
    )(props) || ''
  }
  ${
    useTokenProp<SpacingShorthandToken, StyledProps>(
      'padding',
      (spacingProps) => spacingProps.padding,
      (_props, token) => getSpacingValue(_props.theme, token as SpacingToken)
    )(props) || ''
  }
  ${
    useTokenProp<SpacingShorthandToken, StyledProps>(
      'padding-top',
      (spacingProps) => spacingProps.paddingTop,
      (_props, token) => getSpacingValue(_props.theme, token as SpacingToken)
    )(props) || ''
  }
  ${
    useTokenProp<SpacingShorthandToken, StyledProps>(
      'padding-right',
      (spacingProps) => spacingProps.paddingRight,
      (_props, token) => getSpacingValue(_props.theme, token as SpacingToken)
    )(props) || ''
  }
  ${
    useTokenProp<SpacingShorthandToken, StyledProps>(
      'padding-bottom',
      (spacingProps) => spacingProps.paddingBottom,
      (_props, token) => getSpacingValue(_props.theme, token as SpacingToken)
    )(props) || ''
  }
  ${
    useTokenProp<SpacingShorthandToken, StyledProps>(
      'padding-left',
      (spacingProps) => spacingProps.paddingLeft,
      (_props, token) => getSpacingValue(_props.theme, token as SpacingToken)
    )(props) || ''
  }
`;
