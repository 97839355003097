import { TokenProp } from './types';
import { Theme } from '../types';
import { useTokenProp } from './utils';

interface FontSizeScaleTokens {
  '3xs': string;
  '2xs': string;
  xs: string;
  s: string;
  sm: string;
  smm: string;
  m: string;
  ml: string;
  l: string;
  xl: string;
  '2xl': string;
  '3xl': string;
  '4xl': string;
  '5xl': string;
}

interface FontSizeAliasTokens {
  h1_0: string;
  h1_tablet: string;
  h2_0: string;
  h2_tablet: string;
  h2_desktop: string;
  h3_0: string;
  h3_tablet: string;
  h4_0: string;
  h4_tablet: string;
  h5_0: string;
  h5_tablet: string;
  h6_0: string;
  h6_tablet: string;
  p_0: string;
  p_desktop: string;
  small_p_0: string;
  small_p_tablet: string;
  headerLink_0: string;
  headerLink_desktop: string;
  button_0: string;
  button_desktop: string;
  input_0: string;
  input_tablet: string;
  input_desktop: string;
  blogTitle_0: string;
  blogTitle_tablet: string;
  blogTitle_desktop: string;
  tag_0: string;
  tag_tablet: string;
  tag_desktop: string;
  mobile_navbar_menu_link: string;
  mobile_navbar_menu_sublink: string;
  desktop_navbar_navlink: string;
  desktop_navbar_nav_sublink: string;
}

export interface FontSizeTokens
  extends FontSizeScaleTokens,
    FontSizeAliasTokens {}

type FontSizeToken = keyof FontSizeTokens;
export type FontSizeProp = TokenProp<FontSizeToken>;

interface StyledProps {
  theme: Theme;
  fontSize?: FontSizeProp;
}

export const useFontSizeProp = useTokenProp<FontSizeToken, StyledProps>(
  'font-size',
  (props) => props.fontSize,
  (props, token) => (token ? props.theme.fontSize[token as FontSizeToken] : '')
);
