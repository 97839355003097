import styled from '../../../theme/styled-with-theme';
import { pxToRem } from '../../../styles/utils';
import { mediaQueries } from '../../../styles/media-queries';
import { BlockContainer, Column } from '../../Layout';
import Link from '../../Link';

const APP_BADGE_WIDTH = 112;
const APP_BADGE_WIDTH_M = 200;

export const AppBadge = styled(Link)`
  img {
    width: ${pxToRem(APP_BADGE_WIDTH)};
  }

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.xs};
  }

  ${mediaQueries.tablet} {
    img {
      width: ${pxToRem(APP_BADGE_WIDTH_M)};
    }

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.ml};
    }
  }
`;

export const FooterBlockContainer = styled(BlockContainer)`
  overflow: hidden;

  ${mediaQueries.tablet} {
    overflow: visible;
  }
`;

export const FooterLink = styled(Link)`
  ${({ theme }) => ({
    color: theme.color.footerText,
    fontSize: `${theme.fontSize.s}`,
    lineHeight: theme.lineHeight.s
  })}
  font-weight: ${({ theme }) => theme.fontWeight.footerAnchor};
  text-decoration: none;
  transition: 0.2s color ease;

  &:hover {
    color: ${({ theme }) => theme.color.inlineAnchorHover};
  }

  ${mediaQueries.tablet} {
    font-size: ${({ theme }) => theme.fontSize.m};
    line-height: ${({ theme }) => theme.lineHeight.m};
  }
`;

export const SecondaryFooterLink = styled(FooterLink)`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight.secondaryFooterAnchor};
  margin-bottom: ${({ theme }) => theme.spacing.s};
`;

export const LineBreakWrapper = styled(Column)`
  display: none;
  ${mediaQueries.desktop} {
    display: block;
  }
`;

export const LineBreak = styled('hr')`
  ${({ theme }) => ({
    backgroundColor: theme.color.footerText
  })}
  height: 1px;
  border: 0;
  width: 100%;
`;

export const FooterDisclosureParagraph = styled('p')`
  text-align: center;
  ${({ theme }) => ({ marginTop: theme.spacing.m })};
  ${({ theme }) => ({ fontSize: theme.fontSize['2xs'] })};

  ${mediaQueries.tablet} {
    ${({ theme }) => ({ fontSize: theme.fontSize.sm })};
  }
`;

export const InlineAnchor = styled(Link)`
  ${({ theme }) => ({ color: theme.color.defaultText })}
`;

export const FooterNavItemList = styled('ul')`
  list-style-type: none;
  margin: ${({ theme }) =>
    `0 -${theme.spacing.sm} -${theme.spacing.xs} -${theme.spacing.sm}`};
  padding: 0;
`;

export const FooterNavItem = styled('li')`
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.color.white};
  }
  padding: 0 ${({ theme }) => theme.spacing.s};

  ${mediaQueries.tablet} {
    padding: 0 ${({ theme }) => theme.spacing.sm};
  }
`;

export const FooterLegalNavItemList = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
