import React from 'react';
import Image, { StaticImageData } from 'next/legacy/image';

import Link from '../../Link';

interface ProfileItemProps {
  title: string;
  url?: string;
  description?: string;
  icon?: StaticImageData;
  borderTop?: boolean;
  onClick?: () => void;
}

export function ProfileItem({
  title,
  url,
  description,
  icon,
  borderTop = false,
  onClick
}: ProfileItemProps) {
  return (
    <li
      className={`-mx-4 mb-3 border-[#1A353826] px-4 first:border-b first:pb-[18.5px] last:mb-0 ${
        borderTop && `border-t pt-[18.5px]`
      }`}
    >
      <Link
        className={`block w-full ${onClick && 'cursor-pointer'}`}
        href={url ?? ''}
        onClick={() => onClick?.()}
      >
        <span className="flex w-full flex-row">
          {icon && (
            <span
              style={{ width: '20px', height: '20px', marginRight: '18px' }}
            >
              <Image alt="profile icon" src={icon} />
            </span>
          )}
          <span className="flex flex-1 flex-col text-base">
            <span className="mb-1 break-all font-medium">{title}</span>
            {description && (
              <span className="text-sm font-normal">{description}</span>
            )}
          </span>
        </span>
      </Link>
    </li>
  );
}
