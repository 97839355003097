import React from 'react';

import { UnorderedList } from '../styles';

import { NodeProps, BulletColor } from './shared';

interface UnorderedListNodeProps extends NodeProps {
  bulletColor: BulletColor;
  className?: string;
}

export function UnorderedListNode({
  children,
  bulletColor
}: UnorderedListNodeProps) {
  return <UnorderedList bulletColor={bulletColor}>{children}</UnorderedList>;
}
