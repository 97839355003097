import styled from '../../theme/styled-with-theme';
import { mediaQueries } from '../../styles/media-queries';
import { pxToRem } from '../../styles/utils';

import { Box } from '../Box';
import { Layout, Column } from '../Layout';
import { Flexbox } from '../Flexbox';
import Image from '../Image';

export const Layout7030 = styled(Layout)<{ imageFirst: boolean }>`
  flex-direction: ${({ imageFirst }) =>
    imageFirst ? 'column' : 'column-reverse'};

  ${mediaQueries.desktop} {
    flex-direction: ${({ imageFirst }) => (imageFirst ? 'row' : 'row-reverse')};
  }
`;

export const ColumnContainer = styled(Column)`
  ${mediaQueries.desktop} {
    align-self: start;
    padding-top: ${pxToRem(70)};
  }
`;

export const ImageWrapper = styled(Box)`
  width: 100%;

  ${mediaQueries.tablet} {
    width: auto;
    flex: 1.5;
  }
`;

export const TextWrapper = styled(Box)`
  width: 100%;

  ${mediaQueries.tablet} {
    width: auto;
    flex: 1;
  }
`;

export const StyledImage = styled(Image)`
  max-width: 100%;
  display: block;
  margin: 0 auto;
  border-radius: ${({ theme }) => theme.borderRadius.block};
  flex: 1 0 auto;

  ${mediaQueries.desktop} {
    border-radius: ${({ theme }) => theme.borderRadius.block_desktop};
  }
`;

export const EmbedContainer = styled(Flexbox)<{ imageFirst: boolean }>`
  flex-direction: ${({ imageFirst }) =>
    imageFirst ? 'column' : 'column-reverse'};

  ${mediaQueries.tablet} {
    flex-direction: ${({ imageFirst }) => (imageFirst ? 'row' : 'row-reverse')};
  }
`;

export const ImageBox = styled(Flexbox)`
  ${mediaQueries.tablet} {
    flex: 1 0 66.666%;
  }
`;

export const TextBox = styled(Flexbox)`
  width: ${(10 / 12) * 100}%;
  align-self: center;

  ${mediaQueries.tablet} {
    flex: 1 0 33.334%;
    width: initial;
    align-self: auto;
  }
`;
