import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import Image from 'next/image';
import closeIcon from '@/images/green-cross.svg';

interface PopUpProps {
  children: React.ReactNode;
  trigger?: React.ReactNode;
  open?: boolean;
  maxHeight?: string;
  width?: string;
  onClose?: () => void;
}

export function PopUp({
  children,
  open,
  trigger,
  onClose,
  maxHeight = '90%',
  width = '75%'
}: PopUpProps) {
  return (
    <Dialog.Root open={!trigger ? open : undefined} onOpenChange={onClose}>
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Portal>
        <Dialog.Overlay
          className="fixed inset-0 z-10 bg-[#00000072] data-[state=open]:animate-popupOverlayShow"
          data-testid="exit-intent-popup-overlay"
        />
        <Dialog.Content
          data-testid="exit-intent-popup"
          className="fixed left-[50%] top-[50%] z-20 max-w-[90vw] translate-x-[-50%] translate-y-[-50%] overflow-visible overflow-y-auto rounded-popup shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none data-[state=open]:animate-popupContentShow"
          style={{ width, maxHeight }}
        >
          {children}
          <Dialog.Close asChild>
            <button
              className="absolute right-0 top-0 inline-flex h-[50px] w-[50px] appearance-none items-center justify-center rounded-bl-popup rounded-tr-popup bg-white text-[#035C67] outline-none"
              aria-label="Close"
              data-testid="exit-intent-popup-close"
            >
              <Image src={closeIcon} alt="Close" width={28} height={28} />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
