import { Property } from 'csstype';

import { Box, BoxProps } from '../Box';
import styled from '../../theme/styled-with-theme';
import { useTokenProp } from '../../theme/tokens/utils';
import { TokenProp } from '../../theme/tokens/types';

export interface FlexboxProps extends BoxProps {
  flexDirection?: TokenProp<Property.FlexDirection>;
  flexBasis?: Property.FlexBasis<string>;
  flexWrap?: Property.FlexWrap;
  alignContent?: Property.AlignContent;
  alignItems?: TokenProp<Property.AlignItems>;
  justifyContent?: TokenProp<Property.JustifyContent>;
  justifyItems?: Property.JustifyItems;
}

const useFlexProps = ({
  flexBasis,
  flexWrap,
  alignContent,
  justifyItems
}: FlexboxProps) => ({
  flexBasis,
  flexWrap,
  alignContent,
  justifyItems
});

const useFlexDirectionProp = useTokenProp<Property.FlexDirection, FlexboxProps>(
  'flex-direction',
  (props) => props.flexDirection,
  (props, flexDirection) => `${flexDirection || ''}`
);

const useJustifyContentProp = useTokenProp<
  Property.JustifyContent,
  FlexboxProps
>(
  'justify-content',
  (props) => props.justifyContent,
  (props, justifyContent) => `${justifyContent || ''}`
);

const useAlignItemsProp = useTokenProp<Property.AlignItems, FlexboxProps>(
  'align-items',
  (props) => props.alignItems,
  (props, alignItems) => `${alignItems || ''}`
);

export const Flexbox = styled(Box)<FlexboxProps>`
  display: flex;
  ${useFlexProps}
  ${useFlexDirectionProp}
  ${useAlignItemsProp}
  ${useJustifyContentProp}
`;
