import React, { useMemo } from 'react';
import { Asset } from 'contentful';

import { IAffiliateHeaderFields } from '../../../types/contentful';

import { getScaledImageHeight } from '../../utils/getScaledImageHeight';
import { pxToRem } from '../../styles/utils';

import { Layout } from '../Layout';
import { Box } from '../Box';

import {
  AffiliateLogo,
  StyledHeader,
  HeaderColumn,
  HeaderMain,
  PlusSign,
  StyledGreenlightLogo
} from './styles';

import plusSign from '@/images/plus-sign-affiliates.svg';

const AFFILIATE_LOGO_MIN_HEIGHT_PX = 22;
const AFFILIATE_LOGO_MAX_HEIGHT_PX = 80;
const HEADER_HEIGHT_BASELINE_PX = 100;

export const getHeaderHeight = (asset?: Asset) => {
  const { file } = asset?.fields ?? {};

  const logoWidth = file?.details?.image?.width || 1;
  const logoHeight = file?.details?.image?.height || 1;

  const {
    imageHeight: affiliateLogoHeight,
    aspectRatio: affiliateLogoAspectRatio
  } = getScaledImageHeight(
    logoWidth,
    logoHeight,
    AFFILIATE_LOGO_MIN_HEIGHT_PX,
    AFFILIATE_LOGO_MAX_HEIGHT_PX
  );

  return {
    headerHeight:
      affiliateLogoAspectRatio > 3
        ? HEADER_HEIGHT_BASELINE_PX - 20
        : HEADER_HEIGHT_BASELINE_PX,
    affiliateLogoHeight
  };
};

export function AffiliateHeader({
  hideAffiliateLogo,
  affiliateLogo
}: IAffiliateHeaderFields) {
  const { file, description } = affiliateLogo?.fields ?? {};

  const { headerHeight, affiliateLogoHeight } = useMemo(
    () => getHeaderHeight(affiliateLogo),
    [affiliateLogo]
  );

  return (
    <StyledHeader
      headerHeight={pxToRem(headerHeight)}
      data-testid="affiliate-header-1"
    >
      <Layout gutter={0}>
        <HeaderColumn size={{ 0: 12, desktop: 10 }}>
          <HeaderMain headerHeight={pxToRem(headerHeight)}>
            <Box flex="1 1 auto">
              <StyledGreenlightLogo isMenuOpen={false} />
            </Box>
            {!hideAffiliateLogo && (
              <>
                <PlusSign src={plusSign} alt="Plus sign" />
                <Box flex="1 1 auto">
                  <AffiliateLogo
                    src={file?.url}
                    logoHeight={affiliateLogoHeight}
                    alt={description ?? 'Affiliate of Greenlight logo'}
                  />
                </Box>
              </>
            )}
          </HeaderMain>
        </HeaderColumn>
      </Layout>
    </StyledHeader>
  );
}
