import Image from 'next/legacy/image';

import styled from '../../theme/styled-with-theme';
import { mediaQueries, breakpoints } from '../../styles/media-queries';

import { Column } from '../Layout';
import { Flexbox } from '../Flexbox';

import { GreenlightLogo } from '../Header/GreenlightLogo';

export const HeaderColumn = styled(Column)`
  max-width: ${breakpoints.max}px;
`;

export const HeaderMain = styled(Flexbox)<{ headerHeight: string }>`
  height: ${({ headerHeight }) => headerHeight};
  display: flex;
  margin: auto;
  align-items: center;
  max-width: 100vw;
  padding: 0 ${({ theme }) => theme.spacing.ml};
`;

export const AffiliateLogo = styled('img', {
  shouldForwardProp: (prop) => prop !== 'logoHeight'
})<{ logoHeight: string }>`
  max-height: ${({ logoHeight }) => logoHeight};
  max-width: 100%;
`;

export const StyledHeader = styled('div')<{ headerHeight: string }>`
  height: ${({ headerHeight }) => headerHeight};
  width: 100vw;
`;

export const PlusSign = styled(Image)`
  margin: auto ${({ theme }) => theme.spacing.s};

  ${mediaQueries.desktop} {
    margin: auto ${({ theme }) => theme.spacing.sm};
  }
`;

export const StyledGreenlightLogo = styled(GreenlightLogo)`
  max-width: 100%;
  margin: auto;

  ${mediaQueries.desktop} {
    margin: 0;
  }
`;
