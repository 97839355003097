/**
 * Regular expression for valid US phone numbers
 * A valid phone number cannot have 0 or 1 as the first number of the "area code" or "office code"
 * For example, X or A in XXX-AAA-BBBB
 * https://greenlightme.atlassian.net/wiki/spaces/GPD/pages/2018836555/Valid+Numbers
 * @type {Regexp} 222-333-4444
 */
export const phoneNumberRegex =
  /^(\+?1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/;

/**
 * Regular expression for a valid email address
 * @type {Regexp} user@greenlight.me
 */
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateParam = (param: string | null) => {
  const regex = /^[a-zA-Z0-9-_%@:.+#?&~ \/\\\\]+$/;
  return typeof param === 'string' && param !== '' && regex.test(param);
};
