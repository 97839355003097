import styled from '../../theme/styled-with-theme';

export const Wrapper = styled('div')<{ bgColor: string }>`
  background-color: ${({ bgColor = '#FFFFFF' }) => bgColor};
  min-height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
`;

export const FixedHeader = styled('header')<{
  showHeader?: boolean;
  isAffiliate?: boolean;
}>`
  display: ${({ showHeader = true }) => (!showHeader ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  width: 100%;
  top: 0;

  ${({ isAffiliate = false }) =>
    isAffiliate
      ? `position: relative;`
      : `
          position: -webkit-sticky; 
          position: sticky;
          z-index: 4;
        `}
`;
