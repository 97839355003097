import React, { ReactNode } from 'react';
import { RenderMark, RenderNode } from '@contentful/rich-text-react-renderer';
import {
  Block,
  BLOCKS,
  INLINES,
  Inline,
  MARKS
} from '@contentful/rich-text-types';

import { usePageDataContext } from '../../../../context/PageData';

import { Layout, Column } from '../../../Layout';
import RichText from '../../../RichText';
import { EmbeddedInlineEntryNode } from '../../../RichText/Nodes';

import {
  ParagraphWithLineBreaks,
  UnorderedList
} from '../../../RichText/styles';

import { InlineAnchor } from './styles';

export function PageFootnotes(data: any) {
  const {
    minAprAmount,
    maxAprAmount,
    effectiveDateConversion = {}
  } = usePageDataContext();

  const updatePageFootnotesApr = (pageFootnotes: ReactNode) => {
    if (pageFootnotes?.toString().includes('$[Min APR Range]')) {
      let replaceAprFootnotes = pageFootnotes
        ?.toString()
        .replace('$[Min APR Range]', String(minAprAmount));

      replaceAprFootnotes = replaceAprFootnotes?.replace(
        '$[Max APR Range]',
        String(maxAprAmount)
      );

      const effectiveDate = new Date(effectiveDateConversion);
      replaceAprFootnotes = replaceAprFootnotes?.replace(
        '$[Effective Date]',
        `${effectiveDate.toLocaleString('en-us', {
          month: 'long'
        })} ${effectiveDate.getDate()}, ${effectiveDate.getFullYear()}`
      );

      return replaceAprFootnotes;
    }
    return pageFootnotes;
  };

  const RICH_TEXT_OVERRIDE: RenderNode = {
    [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => {
      return (
        <ParagraphWithLineBreaks
          as="p"
          fontSize={{
            0: '2xs',
            tablet: 's'
          }}
          fontWeight="400"
          colour="footerTextSecondary"
        >
          {updatePageFootnotesApr(children)}
        </ParagraphWithLineBreaks>
      );
    },
    [INLINES.HYPERLINK]: (node, children) => (
      <InlineAnchor
        href={node.data.uri}
        colour="footerTextSecondary"
        fontWeight="400"
      >
        {children}
      </InlineAnchor>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node, children) => (
      <EmbeddedInlineEntryNode
        node={node}
        colour="footerTextSecondary"
        fontWeight="400"
      >
        {children}
      </EmbeddedInlineEntryNode>
    ),
    [BLOCKS.UL_LIST]: (_node: Block | Inline, children) => (
      <UnorderedList className="!pl-7">{children}</UnorderedList>
    )
  };

  const RICH_TEXT_MARK_OVERRIDE: RenderMark = {
    [MARKS.CODE]: (children) => {
      const notesToId: any = {
        '*': 'single',
        '**': 'double',
        '†': 'obelus'
      };

      const note = notesToId[children as string] ?? children;

      return <span id={`gl-disclaimer-${note}`}>{children}</span>;
    }
  };

  return (
    <Layout id="page-footnotes" data-testid="page-footnotes">
      <Column size={12} flexDirection="column">
        <RichText
          colour="footerTextSecondary"
          data={data}
          overrideOptions={RICH_TEXT_OVERRIDE}
          overrideMarkOptions={RICH_TEXT_MARK_OVERRIDE}
        />
      </Column>
    </Layout>
  );
}
