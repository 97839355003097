import Image from 'next/legacy/image';

import styled from '../../theme/styled-with-theme';
import { pxToRem, remToPx } from '../../styles/utils';
import { mediaQueries, breakpoints } from '../../styles/media-queries';

import Link from '../Link';
import { BlockContainer, Column } from '../Layout';
import { Flexbox } from '../Flexbox';
import { Box } from '../Box';

export const HEADER_HEIGHT_BASELINE = 64;
export const HEADER_HEIGHT_DESKTOP = 90;

export const GetStartedWrapper = styled('div')`
  margin: 0 auto;

  ${mediaQueries.max} {
    display: none;
  }
`;

export const HeaderBlockContainer = styled(BlockContainer)<{
  isMenuOpen: boolean;
  isSecondaryHeader: boolean;
}>`
  background-color: ${({ isMenuOpen, isSecondaryHeader, theme }) =>
    isMenuOpen || isSecondaryHeader
      ? theme.color.headerAltBg
      : theme.color.headerBg};
  border-radius: ${({ isMenuOpen, theme }) =>
    isMenuOpen
      ? `0 0 ${theme.borderRadius.block} ${theme.borderRadius.block}`
      : 0};
  ${mediaQueries.desktop} {
    flex-direction: row;
    border-radius: 0;
    background-color: ${({ isSecondaryHeader, theme }) =>
      isSecondaryHeader ? theme.color.headerAltBg : theme.color.headerBg};
  }

  transition:
    background-color 0.5s cubic-bezier(0, 0.7, 0.3, 1),
    border-radius 0.01s linear 0.01s;
  will-change: background-color, border-radius;
`;

export const HeaderColumn = styled(Column)<{ isSecondaryHeader: boolean }>`
  max-width: ${breakpoints.max}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaQueries.desktop} {
    flex-direction: row;
    border-radius: 0;
  }

  transition: all 0.5s cubic-bezier(0, 0.7, 0.3, 1);
  will-change: background-color, border-radius;
`;

export const HeaderLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isSecondaryHeader'
})<{ isSecondaryHeader: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.headerLink_0};
  color: ${({ theme }) => theme.color.headerAltLink};
  text-decoration: none;
  margin-top: ${({ theme }) => theme.spacing.sm};
  white-space: nowrap;

  ${mediaQueries.min} {
    font-size: calc(
      ${({ theme }) => remToPx(theme.fontSize.headerLink_0).replace('px', '')} *
        ((100vw + ((${breakpoints.min}px - 100vw) * 0.25)) / ${breakpoints.min})
    );
  }

  ${mediaQueries.mobile} {
    font-size: ${({ theme }) => theme.fontSize.headerLink_0};
  }

  ${mediaQueries.desktop} {
    font-size: ${({ theme }) => theme.fontSize.headerLink_desktop};
    font-weight: ${({ theme }) => theme.fontWeight['500']};
    color: ${({ isSecondaryHeader, theme }) =>
      isSecondaryHeader ? theme.color.headerAltLink : theme.color.headerLink};
    line-height: ${pxToRem(32)};
    margin-top: 0;
    transition: 0.2s color ease;
    &:hover,
    &:focus {
      color: ${({ theme }) => theme.color.headerLinkHover};
    }
  }

  ${mediaQueries.max} {
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.xl};
    }
  }
`;

export const HeaderMain = styled(Flexbox)<{
  isMenuOpen: boolean;
}>`
  height: ${pxToRem(HEADER_HEIGHT_BASELINE)};

  ${mediaQueries.desktop} {
    height: ${pxToRem(HEADER_HEIGHT_DESKTOP)};
  }
`;

export const HeaderNav = styled('nav')<{ isSimplified: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.ml};
  ${({ isSimplified }) => isSimplified && 'visibility: hidden'};

  ${mediaQueries.desktop} {
    flex-direction: row;
    margin-bottom: 0;
    align-items: center;
    flex-grow: 1;
    justify-content: space-around;
    justify-content: space-evenly;
    padding: 0 ${({ theme }) => theme.spacing.m};
  }

  ${mediaQueries.max} {
    flex-grow: unset;
    justify-content: unset;
    padding: 0 ${({ theme }) => `${theme.spacing.m} 0 ${theme.spacing.l}`};
  }
`;

export const HeaderPhoneNumberWrapper = styled('div')`
  display: none;
  margin-left: ${({ theme }) => theme.spacing['xl']};

  ${mediaQueries.max} {
    display: block;
    width: ${pxToRem(280)};
  }
`;

export const HideOnMobileSecondary = styled('div')<{
  isSecondaryHeader: boolean;
}>`
  display: ${({ isSecondaryHeader }) => (isSecondaryHeader ? 'none' : 'block')};
  ${mediaQueries.desktop} {
    display: block;
  }
`;

const LOGO_SVG_HEIGHT = 28;
const LOGO_SVG_WIDTH = 180;
const LOGO_HEIGHT = 28;
const LOGO_HEIGHT_DESKTOP = 28;

export const Icon = styled(Image)`
  display: block;
  height: ${pxToRem(18)};
`;

export const Logo = styled('svg')<{
  isMenuOpen: boolean;
}>`
  display: block;
  height: ${pxToRem(LOGO_HEIGHT)};
  width: ${pxToRem((LOGO_SVG_WIDTH / LOGO_SVG_HEIGHT) * LOGO_HEIGHT)};
  fill: ${({ isMenuOpen, theme }) =>
    isMenuOpen ? theme.color.headerAltLogo : theme.color.headerLogo};

  ${mediaQueries.desktop} {
    height: ${pxToRem(LOGO_HEIGHT_DESKTOP)};
    width: ${pxToRem((LOGO_SVG_WIDTH / LOGO_SVG_HEIGHT) * LOGO_HEIGHT_DESKTOP)};
  }

  ${mediaQueries.desktop} {
    fill: ${({ theme }) => theme.color.headerLogo};
  }
`;

export const Menu = styled('div')<{ isOpen: boolean }>`
  display: flex;
  padding-bottom: ${({ theme, isOpen }) => (isOpen ? theme.spacing['xl'] : 0)};
  flex-direction: column;
  align-items: center;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  height: ${({ isOpen }) => (isOpen ? 'auto' : 0)};
  max-height: ${({ isOpen }) => (isOpen ? '100vh' : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};

  ${mediaQueries.desktop} {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    margin-top: unset;
    padding: 0;
    align-items: center;
    visibility: visible;
    opacity: 1;
    height: ${pxToRem(HEADER_HEIGHT_DESKTOP)};
    max-height: ${pxToRem(HEADER_HEIGHT_DESKTOP)};
  }

  ${mediaQueries.max} {
    flex-grow: 0;
  }

  transition: all 0.3s cubic-bezier(0, 0.7, 0.3, 1);
  will-change: opacity, height, max-height, visibility, padding;
`;

export const MenuButton = styled('button')<{ isSimplified: boolean }>`
  display: block;
  display: ${({ isSimplified }) => (isSimplified ? 'none' : 'block')};
  background: none;
  border: none;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.xs};

  ${mediaQueries.desktop} {
    display: none;
  }
`;

export const ShowOnMobileSecondary = styled(Box)<{
  isSecondaryHeader: boolean;
}>`
  display: ${({ isSecondaryHeader }) => (isSecondaryHeader ? 'block' : 'none')};
  ${mediaQueries.desktop} {
    display: none;
  }

  img {
    display: block;
  }
`;

export const StyledHeader = styled('div')`
  height: ${pxToRem(HEADER_HEIGHT_BASELINE)};
  width: 100%;
  overflow: visible;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4;

  ${mediaQueries.desktop} {
    height: ${pxToRem(HEADER_HEIGHT_DESKTOP)};
  }
`;
