import { sendHttpRequest } from '../../utils/sendHttpRequest';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
const { stargateway } = publicRuntimeConfig;

export const CONSENT_URL = `${stargateway}/open/v2`;

interface CollectConsentArgs {
  userId: string | false;
  type: string;
  pageName: string;
}
function collectConsent({ userId, type, pageName }: CollectConsentArgs) {
  const url = window?.location?.href;
  return sendHttpRequest<true>({
    method: 'post',
    url: `${CONSENT_URL}/consent`,
    data: {
      userId,
      type,
      pageName,
      url,
      duringRegistration: true,
      glProduct: 'marketing_website'
    },
    validateResponse: () => true,
    retries: 2,
    backoffMs: 500
  });
}

export default {
  collectConsent
};
