import styled from '../../theme/styled-with-theme';
import { pxToRem } from '../../styles/utils';
import {
  useColourPropAsFill,
  useHoverColorPropAsFill
} from '../../theme/tokens/color';
import { SocialIconProps } from '.';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export const IconWrapper = styled('span')<SocialIconProps>`
  margin-right: ${({ theme }) => theme.spacing.xs};
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  height: 32px;
  width: 32px;
  display: flex;
  flex-directon: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color.black};
  border-radius: 100%;

  &:hover {
    background: ${({ theme }) => theme.color.socialIconSupplementary};
  }

  .socialIcon {
    ${useColourPropAsFill}
    transition: 0.2s fill ease;
  }

  svg {
    display: inline;
  }

  &:hover .socialIcon {
    ${useHoverColorPropAsFill}
  }
`;

export const ReactTooltip = styled(Tooltip)`
  border-radius: ${pxToRem(12)};
`;
