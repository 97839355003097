import React from 'react';
import Image from 'next/legacy/image';
import { Block, BLOCKS, Inline } from '@contentful/rich-text-types';

import { IAffiliateFooterFields } from '../../../types/contentful';

import { usePageDataContext } from '../../context/PageData';

import { Box } from '../Box';
import { Flexbox } from '../Flexbox';
import { BlockContainer, Layout, Column } from '../Layout';
import { Text } from '../Text';
import { FooterLegalNav } from '../Footer/1.0/FooterLegalNav';
import RichText from '../RichText';
import { GreenlightLogo } from '../Header/GreenlightLogo';

import { ParagraphWithLineBreaks } from '../RichText/styles';
import {
  AppBadge,
  FooterBlockContainer,
  LineBreak,
  LineBreakWrapper
} from '../Footer/1.0/styles';

import appStoreBadge from '@/images/app-store-badge.svg';
import googlePlayBadge from '@/images/google-play-badge.svg';

const DEFAULT_APP_LINK_TEXT = 'Get the App:';

export function AffiliateFooter({
  appLinkText,
  pageFootnotes,
  hideFooterAppLinks
}: IAffiliateFooterFields) {
  const { config } = usePageDataContext();

  const { appleAppLink, googleAppLink, footerDisclaimer } = config ?? {};

  return (
    <>
      {!hideFooterAppLinks && (
        <BlockContainer
          as="section"
          marginTop={{ 0: 'ml', tablet: 'xl' }}
          marginBottom={{ 0: 'ml', tablet: 'xl' }}
          data-blockid="app-links"
        >
          <Layout>
            <Column size={10}>
              <Flexbox
                flexDirection={{ 0: 'column', desktop: 'row' }}
                justifyContent="center"
                margin="auto"
              >
                <Box
                  marginBottom={{ 0: 's' }}
                  marginRight={{ 0: 0, tablet: 'ml' }}
                >
                  <Text
                    as="h2"
                    fontSize={{ 0: 's', tablet: 'm' }}
                    fontWeight="h2"
                    colour="defaultText"
                  >
                    {appLinkText ?? DEFAULT_APP_LINK_TEXT}
                  </Text>
                </Box>
                <div>
                  <AppBadge
                    href={appleAppLink ?? '/'}
                    data-testid="ios-app-link"
                  >
                    <Image
                      src={appStoreBadge}
                      alt="Download Greenlight App - App Store"
                    />
                  </AppBadge>
                  <AppBadge
                    href={googleAppLink ?? '/'}
                    data-testid="android-app-link"
                  >
                    <Image
                      src={googlePlayBadge}
                      alt="Download Greenlight App - Google Play"
                    />
                  </AppBadge>
                </div>
              </Flexbox>
            </Column>
          </Layout>
        </BlockContainer>
      )}

      <FooterBlockContainer
        as="footer"
        marginTop={
          hideFooterAppLinks
            ? {
                0: 'blockMarginY',
                tablet: 'blockMarginY_tablet',
                desktop: '3xl'
              }
            : undefined
        }
        borderRadius={{
          0: ['block', 'block', '0', '0'],
          tablet: 'unset'
        }}
        backgroundColor={{ 0: 'footerBg', tablet: 'transparent' }}
        revealOnScroll={false}
        data-blockid="footer"
        id="footer"
      >
        <Layout
          backgroundColor={{ 0: 'unset', tablet: 'footerBg' }}
          borderRadius={['block_desktop', 'block_desktop', 0, 0]}
          paddingTop={{ 0: 'l', tablet: '2xl' }}
          paddingBottom={{ 0: 'xl', tablet: '2xl' }}
        >
          <Layout>
            <Column size={{ 0: 10, desktop: 5 }} flexDirection="column">
              <GreenlightLogo isMenuOpen={true} />
              <FooterLegalNav marginTop="ml" isAffiliateFooter={true} />
            </Column>
            <Column
              size={{ 0: 10, desktop: 5 }}
              data-section-id="copyright"
              flexDirection="column"
              data-testid="footer-legal-text"
            >
              <RichText
                data={footerDisclaimer}
                overrideOptions={{
                  [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => {
                    return (
                      <Text
                        as="p"
                        fontSize={{ 0: '2xs', tablet: 'sm' }}
                        colour="footerText"
                        fontWeight="300"
                        marginTop="sm"
                      >
                        {children}
                      </Text>
                    );
                  }
                }}
              />
            </Column>
          </Layout>
          {pageFootnotes && (
            <Layout>
              <LineBreakWrapper
                size={{ 0: 10, desktop: 10 }}
                flexDirection="column"
              >
                <LineBreak />
              </LineBreakWrapper>
              <Column size={{ 0: 10, desktop: 10 }} flexDirection="column">
                <RichText
                  colour="footerText"
                  data={pageFootnotes}
                  overrideOptions={{
                    [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => (
                      <ParagraphWithLineBreaks
                        as="p"
                        fontSize={{
                          0: '2xs',
                          tablet: 'sm'
                        }}
                        fontWeight="300"
                        colour="footerText"
                      >
                        {children}
                      </ParagraphWithLineBreaks>
                    )
                  }}
                />
              </Column>
            </Layout>
          )}
        </Layout>
      </FooterBlockContainer>
    </>
  );
}
