import React from 'react';

import { Text } from '../../Text';

import { NodeProps } from './shared';

interface SubheadingNodeProps extends NodeProps {
  type: 'h4' | 'h5' | 'h6';
}

export function SubheadingNode({ children, type }: SubheadingNodeProps) {
  return (
    <Text
      as={type}
      fontSize={{
        0: `${type}_0`,
        tablet: `${type}_tablet`
      }}
      fontWeight="500"
      marginTop={{ 0: 'ml', tablet: 'l' }}
      marginBottom={{ 0: 'sm', tablet: 'm' }}
    >
      {children}
    </Text>
  );
}
