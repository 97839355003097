import { TokenProp } from './types';
import { Theme } from '../types';
import { useTokenProp } from './utils';

interface FontWeightScaleTokens {
  '100': string;
  '200': string;
  '300': string;
  '400': string;
  '500': string;
  '600': string;
  '700': string;
  '800': string;
  '900': string;
}

interface FontWeightAliasTokens {
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  p: string;
  inlineAnchor: string;
  footerAnchor: string;
  secondaryFooterAnchor: string;
  button: string;
  bold: string;
}

export interface FontWeightTokens
  extends FontWeightScaleTokens,
    FontWeightAliasTokens {}

type FontWeightToken = keyof FontWeightTokens;
export type FontWeightProp = TokenProp<FontWeightToken>;

interface StyledProps {
  theme: Theme;
  fontWeight?: FontWeightProp;
}

export const useFontWeightProp = useTokenProp<FontWeightToken, StyledProps>(
  'font-weight',
  props => props.fontWeight,
  (props, token) =>
    token ? props.theme.fontWeight[token as FontWeightToken] : ''
);
