import React from 'react';

import { IFooter20Fields } from '../../../../../types/contentful';

import { AppLinks } from './AppLinks';
import { FooterImage } from './FooterImage';
import { SocialLinks } from './SocialLinks';

export function Social({
  glLogo,
  glTagline,
  hideAppLinks,
  isAffiliateFooter
}: IFooter20Fields) {
  return (
    <>
      <FooterImage {...{ glLogo, glTagline, isAffiliateFooter }} />

      {!isAffiliateFooter && !hideAppLinks && <AppLinks />}

      {!isAffiliateFooter && <SocialLinks />}
    </>
  );
}
