import { mediaQueries } from '../../styles/media-queries';
import styled from '../../theme/styled-with-theme';

export const DisableBackground = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
`;

export const Modal = styled('div')`
  display: flex;
  position: fixed;
  inset: 0;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 0;
  height: 500px;
  margin: 0 auto;
  top: 5%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 10px -3px #999;
  font-size: 18px;

  ${mediaQueries.min} {
    max-width: 300px;
    min-width: 300px;
    font-size: 16px;
    height: 575px;
  }

  ${mediaQueries.tablet} {
    max-width: 575px;
    min-width: 575px;
    height: 500px;
    font-size: 18px;
  }
`;

export const ModalContainer = styled('div')`
  padding: ${({ theme }) => theme.spacing.s} 0;
  flex: 1;
`;

export const ModalBody = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  outline: 0;
  color: #1d252c;
  font-size: 0.812em;
  line-height: 1.5;
  overflow-y: scroll;
`;

export const ModalHeader = styled('div')`
  border-bottom: none;
  height: 68px;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${mediaQueries.mobile} {
    img {
      max-width: 200px;
      max-height: 30px;
    }
  }
`;

export const ModalSubHeader = styled('div')`
  float: left;
  font-size: 1.3em;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  font-weight: 500;
  color: #1d252c;
  padding-left: 30px;
`;

export const CloseButton = styled('button')`
  cursor: pointer;
  z-index: 1;
  padding: 0;
  background-color: transparent;
  border: none;
  background-size: 10px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMzQ4LjMzM3B4IiBoZWlnaHQ9IjM0OC4zMzNweCIgdmlld0JveD0iMCAwIDM0OC4zMzMgMzQ4LjMzNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQ4LjMzMyAzNDguMzM0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZmlsbD0iIzU2NTY1NiIgZD0iTTMzNi41NTksNjguNjExTDIzMS4wMTYsMTc0LjE2NWwxMDUuNTQzLDEwNS41NDljMTUuNjk5LDE1LjcwNSwxNS42OTksNDEuMTQ1LDAsNTYuODVjLTcuODQ0LDcuODQ0LTE4LjEyOCwxMS43NjktMjguNDA3LDExLjc2OWMtMTAuMjk2LDAtMjAuNTgxLTMuOTE5LTI4LjQxOS0xMS43NjlMMTc0LjE2NywyMzEuMDAzTDY4LjYwOSwzMzYuNTYzYy03Ljg0Myw3Ljg0NC0xOC4xMjgsMTEuNzY5LTI4LjQxNiwxMS43NjljLTEwLjI4NSwwLTIwLjU2My0zLjkxOS0yOC40MTMtMTEuNzY5Yy0xNS42OTktMTUuNjk4LTE1LjY5OS00MS4xMzksMC01Ni44NWwxMDUuNTQtMTA1LjU0OUwxMS43NzQsNjguNjExYy0xNS42OTktMTUuNjk5LTE1LjY5OS00MS4xNDUsMC01Ni44NDRjMTUuNjk2LTE1LjY4Nyw0MS4xMjctMTUuNjg3LDU2LjgyOSwwbDEwNS41NjMsMTA1LjU1NEwyNzkuNzIxLDExLjc2N2MxNS43MDUtMTUuNjg3LDQxLjEzOS0xNS42ODcsNTYuODMyLDBDMzUyLjI1OCwyNy40NjYsMzUyLjI1OCw1Mi45MTIsMzM2LjU1OSw2OC42MTF6Ii8+PC9nPjwvc3ZnPg==);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 12px;
  width: 12px;
`;

export const ModalContent = styled('p')`
  color: #1d252c;
  clear: both;
  width: 100%;
  line-height: 1.5;
  margin-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const ButtonWrapper = styled('div')`
  text-align: right;
  padding-bottom: 20px;
  padding-right: 20px;
`;

export const Link = styled('a')`
  color: #035c67;
  &:hover {
    text-decoration: none;
  }
`;
