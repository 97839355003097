import getConfig from 'next/config';
import { omitBy, isNil } from 'lodash';
import { v4 } from 'uuid';
import logger from '@greenlight/logger';

import { sendHttpRequest2 } from '../../utils/sendHttpRequest';

const { publicRuntimeConfig } = getConfig();
const { onboardingUrl } = publicRuntimeConfig;

export async function initialize(
  initParams: Record<string, any>
): Promise<boolean> {
  const stockRewardsAsBoolean = initParams.stockRewards === 'true';

  const data = omitBy(
    {
      ...initParams,
      accessToken: initParams.authToken,
      legacyAccessToken: initParams.accessToken,
      stockRewards: stockRewardsAsBoolean,
      deviceId: v4(),
      anonymousId: initParams.segmentAnonymousId || initParams.anonymousId // prefer the anon id passed from WRIA if it is there
    },
    isNil
  );

  const initializedNextGen = await sendHttpRequest2<void>({
    method: 'post',
    url: `${onboardingUrl}/initialize`,
    data,
    validateResponse: () => true,
    withCredentials: true
  })
    .then((response) => {
      logger.info('Onboarding cookie data set.', {
        initializeResponse: response
      });

      return true;
    })
    .catch((error) => {
      logger.info('Failed to set onboarding cookie data.', {
        error
      });
      return false;
    });

  return initializedNextGen;
}
