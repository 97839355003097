import { useScrollPosition as useSP } from '@n8tb1t/use-scroll-position';

export const useScrollPosition: typeof useSP = (onScroll, ...args) => {
  const onScrollWithIeFix: typeof onScroll = props => {
    if (!props.currPos.x || !props.currPos.y) {
      props.currPos.x = window.pageXOffset;
      props.currPos.y = window.pageYOffset;
    }
    return onScroll(props);
  };
  return useSP(onScrollWithIeFix, ...args);
};

useScrollPosition.defaultProps = {
  deps: [],
  element: false,
  useWindow: false,
  wait: null,
  boundingElement: false
};
