import styled from '../../theme/styled-with-theme';
import { mediaQueries } from '../../styles/media-queries';
import { pxToRem } from '../../styles/utils';
import {
  useButtonColorProp,
  useButtonHoverColorProp,
  ButtonColorTokenProp
} from '../../theme/tokens/button-color';
import {
  CFColorTokenProp,
  useCFButtonColorProp,
  useCFButtonHoverColorProp
} from '../../theme/tokens/contentful-color';

import Link from '../Link';

interface ButtonProps {
  buttonColor?: ButtonColorTokenProp;
  buttonHoverColor?: ButtonColorTokenProp;
  cfButtonColor?: CFColorTokenProp;
  cfButtonHoverColor?: CFColorTokenProp;
}

const shouldForwardProp = (prop: unknown) =>
  ![
    'buttonColor',
    'buttonHoverColor',
    'cfButtonColor',
    'cfButtonHoverColor'
  ].includes(prop as string);

export const Button = styled('button', {
  shouldForwardProp
})<ButtonProps>`
  border-radius: ${({ theme }) => theme.borderRadius.button};
  border: 0;
  outline: 0;
  padding: ${({ theme }) => `0 ${theme.spacing.sm}`};
  height: ${pxToRem(36)};
  min-width: ${pxToRem(146)};
  font-size: ${({ theme }) => theme.fontSize.button_0};
  font-weight: ${({ theme }) => theme.fontWeight.button};
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  transition: 0.2s background-color ease;
  ${useButtonColorProp};
  ${useCFButtonColorProp};

  @media (hover: hover) {
    &:hover,
    &:focus {
      ${useButtonHoverColorProp};
      ${useCFButtonHoverColorProp};
    }
  }

  ${mediaQueries.tablet} {
    font-size: ${({ theme }) => theme.fontSize.button_desktop};
  }

  ${mediaQueries.desktop} {
    border-radius: ${({ theme }) => theme.borderRadius.button_desktop};
    height: ${pxToRem(40)};
    min-width: ${pxToRem(168)};
  }
`;

export const UnstyledButton = styled('button')`
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  text-align: initial;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
`;

export const LinkButton = styled(Link, {
  shouldForwardProp
})<ButtonProps>`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  border-radius: 8px;
  border: 0;
  outline: 0;
  padding: ${({ theme }) => `0 ${theme.spacing.sm}`};
  height: ${pxToRem(36)};
  width: auto;
  min-width: ${pxToRem(146)};
  font-size: ${({ theme }) => theme.fontSize.button_0};
  font-weight: ${({ theme }) => theme.fontWeight.button};
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  transition: 0.2s background-color ease;
  ${useButtonColorProp};
  ${useCFButtonColorProp};

  @media (hover: hover) {
    &:hover,
    &:focus {
      ${useButtonHoverColorProp};
      ${useCFButtonHoverColorProp};
    }
  }

  ${mediaQueries.tablet} {
    font-size: ${({ theme }) => theme.fontSize.button_desktop};
  }

  ${mediaQueries.desktop} {
    height: ${pxToRem(40)};
    min-width: ${pxToRem(168)};
  }
`;

export const OutlineButton = styled('button')<{ selected?: boolean }>`
  width: 100%;
  height: ${pxToRem(40)};
  box-sizing: border-box;
  border-radius: ${pxToRem(17)};
  background-color: ${({ selected = false }) =>
    selected ? '#E6F9F5' : 'transparent'};
  border: 1px solid #000000;
  padding: ${pxToRem(4)} ${pxToRem(16)};
  display: flex;
  gap: ${pxToRem(11)};
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.secondaryText};
  font-weight: ${({ theme }) => theme.fontWeight[400]};
  font-size: ${({ theme }) => theme.fontSize.sm};

  ${mediaQueries.tablet} {
    height: ${pxToRem(32)};
    border: 1px solid #c6c8ca;
    background-color: transparent;
    max-width: ${pxToRem(170)};
  }
`;

export const TransparentButton = styled('button')`
  max-width: ${pxToRem(100)};
  width: 100%;
  height: ${pxToRem(28)};
  box-sizing: border-box;
  border-radius: 100px;
  background-color: transparent;
  padding: ${pxToRem(4)};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${pxToRem(28)};
  color: ${({ theme }) => theme.color.secondaryText};
  font-weight: ${({ theme }) => theme.fontWeight[400]};
  font-size: ${({ theme }) => theme.fontSize.sm};

  ${mediaQueries.min} {
    width: 100%;
  }

  ${mediaQueries.tablet} {
    width: 50%;
  }
`;
