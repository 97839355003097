import React, {
  forwardRef,
  useState,
  useEffect,
  useMemo,
  AnchorHTMLAttributes,
  ReactNode
} from 'react';
import Link from 'next/link';

import { appendParams } from '../../utils/buildWebRegUrl';

export type Ref = HTMLAnchorElement | null;
export interface BaseLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: ReactNode;
  attachParams?: boolean;
  forwardedButtonParams?: string;
}

export default forwardRef<Ref, BaseLinkProps>(function GlLink(
  {
    href,
    children,
    attachParams = false,
    forwardedButtonParams,
    ...linkProps
  }: BaseLinkProps,
  ref
) {
  // Check if links start with exactly one slash
  const internal = useMemo(() => /^\/(?!\/)/.test(href as string), [href]);
  const [url, setUrl] = useState<string>(href as string);

  useEffect(() => {
    if (!internal && attachParams) {
      const urlWithParams = appendParams({
        baseUrl: url,
        phoneNumber: '',
        bypassPhoneNumberEntry: true,
        forwardedButtonParams
      });
      setUrl(urlWithParams);
    }
  }, [attachParams]);

  return (
    <Link href={url} prefetch={false} passHref legacyBehavior>
      <a
        ref={ref}
        data-gl-link="greenlight-link"
        data-testid="greenlight-link"
        {...linkProps}
      >
        {children}
      </a>
    </Link>
  );
});
