import React, { FunctionComponent } from 'react';

import { ScrollReveal } from '../ScrollReveal';
import { BoxProps, Box } from '../Box';
import { FlexboxProps } from '../Flexbox';
import { SpacingToken } from '../../theme/tokens/spacing';
import { ResponsiveTokenSet, TokenProp } from '../../theme/tokens/types';

import { StyledLayout, StyledBlockContainer, LayoutContainer } from './styles';

type ExcludedProps = 'margin' | 'marginLeft' | 'marginRight' | 'justifyContent';

export interface BlockContainerProps extends Omit<BoxProps, ExcludedProps> {
  children?: React.ReactNode;
  revealOnScroll?: boolean;
  xPaddingOverrides?: ResponsiveTokenSet<SpacingToken>;
}

export const BlockContainer: FunctionComponent<BlockContainerProps> = ({
  revealOnScroll = true,
  ...props
}: BlockContainerProps) =>
  revealOnScroll ? (
    <ScrollReveal>
      <StyledBlockContainer {...props} />
    </ScrollReveal>
  ) : (
    <StyledBlockContainer {...props} />
  );

export interface LayoutProps extends FlexboxProps {
  children?: React.ReactNode;
  gutter?: TokenProp<SpacingToken>;
}

export const Layout: FunctionComponent<LayoutProps> = ({
  gutter = { 0: 'm', tablet: 'ml', desktop: 'l' },
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  backgroundColor,
  cfBackgroundColor,
  hexOverrideBgColor,
  borderRadius,
  ...props
}: LayoutProps) => (
  <LayoutContainer
    margin={margin}
    marginTop={marginTop}
    marginRight={marginRight}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
  >
    <Box
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      cfBackgroundColor={cfBackgroundColor}
      hexOverrideBgColor={hexOverrideBgColor}
    >
      <StyledLayout gutter={gutter} {...props} />
    </Box>
  </LayoutContainer>
);

export { Column, BoxColumn } from './styles';
